import ProductLinks from 'Component/ProductLinks';
import { RELATED } from 'Store/LinkedProducts/LinkedProducts.reducer';

import '../component/BlogRelatedProducts/BlogRelatedProducts.style.scss';

export default {
    'Osavi/Blog/Component/BlogRelatedProducts/Component/BlogRelatedProductsComponent': {
        'member-function': {
            render() {
                return (
                    <div block="BlogRelatedProducts" mods={ { isOverridden: true } }>
                        <div block="BlogRelatedProducts" elem="Wrapper">
                            <ProductLinks
                              linkType={ RELATED }
                              forBlog
                              title={ __('Related Products') }
                              numberOfProductsToDisplay={ 5 }
                            />
                        </div>
                    </div>
                );
            }
        }
    }
};
