import {
    ExpandableContent as SourceExpandableContent
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';

/** @namespace Scandipwa/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContent {
    // TODO implement logic
}

export default ExpandableContentComponent;
