import Icons from 'Component/Icons';
import Link from 'Component/Link';
import { Breadcrumb as SourceBreadcrumb } from 'SourceComponent/Breadcrumb/Breadcrumb.component';

import './Breadcrumb.style';

/** @namespace Scandipwa/Component/Breadcrumb/Component */
export class Breadcrumb extends SourceBreadcrumb {
    renderLink() {
        const {
            index,
            isDisabled
        } = this.props;

        const url = this.getLinkUrl() || {};

        return (
            <>
                <Link
                  block="Breadcrumb"
                  elem="Link"
                  to={ url }
                  tabIndex={ isDisabled ? '-1' : '0' }
                >
                    <meta itemProp="item" content={ window.location.origin + url.pathname } />
                    <span itemProp="name">
                    { this.renderName() }
                    </span>
                    <meta itemProp="position" content={ index } />
                </Link>
                <span block="Breadcrumb" elem="SeparateIcon">
                    <Icons name="breadcrumbs_separator" height="18" width="5" />
                </span>
            </>
        );
    }
}

export default Breadcrumb;
