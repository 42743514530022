import MapWithShops from 'Component/MapWithShops';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import ResellerContactForm from 'Component/ResellerContactForm';
import { MAP_WITH_SHOPS, RESELLER_CONTACT_FROM } from 'Component/WidgetFactory/WidgetFactory.config';
import { WidgetFactory as SourceWidgetFactory } from 'SourceComponent/WidgetFactory/WidgetFactory.component';

import './WidgetFactory.style.scss';

export * from 'SourceComponent/WidgetFactory/WidgetFactory.component';

/** @namespace Scandipwa/Component/WidgetFactory/Component */
export class WidgetFactory extends SourceWidgetFactory {
    renderMap = {
        ...this.renderMap,
        [MAP_WITH_SHOPS]: {
            component: MapWithShops
        },
        [RESELLER_CONTACT_FROM]: {
            component: ResellerContactForm
        }
    };

    renderContent() {
        const { type } = this.props;
        const {
            component: Widget,
            fallback
        } = this.renderMap[type] || {};

        if (Widget !== undefined) {
            return (
                <RenderWhenVisible fallback={ fallback }>
                    <Widget { ...this.props } />
                </RenderWhenVisible>
            );
        }

        return null;
    }

    renderSliderFallback() {
        return (
            <div block="SliderWidget" elem="Placeholder" />
        );
    }
}

export default WidgetFactory;
