import React, { PureComponent } from 'react';

/** @namespace OsaviAdvox/Blog/Component/BlogTableOfContents/Component */
export class BlogTableOfContentsComponent extends PureComponent {
    state = {
        list: []
    };

    getPostContentElement() {
        return document.getElementById('post-content');
    }

    componentDidMount() {
        this.getTableOfContents();
    }

    componentDidUpdate(prevProps) {
        const { content } = this.props;

        if (prevProps.content !== content) {
            this.getTableOfContents();
        }
    }

    goToElement({ tag, text }) {
        const postContentElement = this.getPostContentElement();

        if (postContentElement) {
            const element = Array.from(postContentElement.querySelectorAll(tag) ?? [])
                .find((item) => item.innerText === text);

            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }

    getTableOfContents() {
        const element = this.getPostContentElement();

        this.setState({
            list: element ? Array.from(element.querySelectorAll('h2') ?? [], (element, key) => ({
                tag: element.tagName,
                text: element.innerText,
                id: key
            })) : []
        });
    }

    renderItem({ text, id, ...props }) {
        return (
            <li
              block="PostsDetails"
              elem="TableOfContentsItem"
              key={ id }
              onClick={ this.goToElement.bind(this, { text, id, ...props }) }
            >
                <span>
                    { text }
                </span>
            </li>
        );
    }

    render() {
        const { content } = this.props;
        const { list } = this.state;

        if (!content || !list.length) {
            return null;
        }

        return (
            <div block="PostsDetails" elem="TableOfContents">
                <p block="PostsDetails" elem="TableOfContentsHeading">
                    { __('Table of contents') }
                </p>
                <ol block="PostsDetails" elem="TableOfContentsList">
                    { list.map(this.renderItem.bind(this)) }
                </ol>
            </div>
        );
    }
}

export default BlogTableOfContentsComponent;
