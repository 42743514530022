import BlogSearchOverlay from '@osavi/blog/src/component/BlogSearchOverlay/BlogSearchOverlay.container';
import React from 'react';

import ClickOutside from 'Component/ClickOutside';
import SearchIcon from 'Component/SearchIcon';

import '../component/BlogSearchBar/BlogSearchBar.style.scss';

export default {
    'Osavi/Blog/Component/BlogSearchBar/Component/BlogSearchBarComponent': {
        'member-function': {

            render(args, callback, instance) {
                const {
                    mix,
                    searchCriteria,
                    isSearchVisible,
                    onSearchBarClick,
                    onSearchBarChange,
                    onSearchOutsideClick
                } = instance.props;

                return (
                    <div mix={ mix } block="BlogSearchBar" mods={ { isOverridden: true } }>
                        <ClickOutside onClick={ onSearchOutsideClick }>
                            <div
                              block="BlogSearchBar"
                              elem="SearchWrapper"
                              aria-label={ __('Search') }
                            >
                                <div block="BlogSearchBar" elem="Search">
                                    <input
                                      id="posts-search-field"
                                      placeholder={ __('Search') }
                                      block="BlogSearchBar"
                                      elem="SearchField"
                                      autoComplete="off"
                                      onClick={ onSearchBarClick }
                                      onChange={ onSearchBarChange }
                                      value={ searchCriteria }
                                      mods={ { type: 'searchField' } }
                                    />
                                    <SearchIcon />
                                </div>
                                <BlogSearchOverlay
                                  searchCriteria={ searchCriteria }
                                  onSearchOutsideClick={ onSearchOutsideClick }
                                  isSearchVisible={ isSearchVisible }
                                />
                            </div>
                        </ClickOutside>
                    </div>
                );
            }
        }
    }
};
