import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductLinksContainer as SourceProductLinksContainer
} from 'SourceComponent/ProductLinks/ProductLinks.container';
import LinkedProductsReducer from 'Store/LinkedProducts/LinkedProducts.reducer';
import { withReducers } from 'Util/DynamicReducer';

/** @namespace Scandipwa/Component/ProductLinks/Container */
export class ProductLinksContainer extends SourceProductLinksContainer {
    containerProps() {
        const { showAttributes, forBlog, withViewButton } = this.props;

        return {
            ...super.containerProps(),
            showAttributes,
            forBlog,
            withViewButton: withViewButton ?? true
        };
    }
}

export default withReducers({
    LinkedProductsReducer
})(connect(mapStateToProps, mapDispatchToProps)(ProductLinksContainer));
