/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'SourceUtil/Query';

/** @namespace Osavi/Blog/Query/BlogPosts/Query/BlogPostsQuery */
export class BlogPostsQuery {
    getQuery(options) {
        const items = this.itemField(options);
        const page = this.pageField();

        const {
            filter,
            // eslint-disable-next-line no-unused-vars
            sort
        } = options || {};

        const defaultOptions = {
            pageSize: 6,
            currentPage: 1,
            sort: ['DESC']
        };

        const modifiedOptions = { ...defaultOptions, ...options };

        const field = new Field('blogPosts')
            .addArgument('pageSize', 'Int', modifiedOptions.pageSize)
            .addArgument('currentPage', 'Int', modifiedOptions.currentPage)
            .addField(items)
            .addFieldList(page)
            .setAlias('posts');

        if (filter) {
            field.addArgument('filter', 'BlogPostsFilterInput', filter);
        }

        if (modifiedOptions.sort) {
            field.addArgument('sort', '[String]', modifiedOptions.sort);
        }

        return field;
    }

    itemField(options) {
        const {
            getBasicDetails = true,
            getDescription,
            getMedia
        } = options || {};

        const fields = [];
        const author = this.authorField();

        if (getBasicDetails) {
            fields.push('post_id', 'identifier', 'title', 'publish_time', ...this.metaFields());
        }

        if (getDescription) {
            fields.push('short_content', author, 'content');
        }

        if (getMedia) {
            fields.push('og_image', 'first_image', 'media_gallery');
        }

        return new Field('items').addFieldList(fields);
    }

    pageField() {
        return ['total_count', 'total_pages'];
    }

    authorField() {
        return new Field('author').addField('name');
    }

    metaFields() {
        return ['meta_title', 'meta_description', 'meta_keywords'];
    }
}

export default new BlogPostsQuery();
