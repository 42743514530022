import { PureComponent } from 'react';

import {
    INSTAGRAM_PHOTOS_FIELDS,
    INSTAGRAM_PHOTOS_LIMIT,
    INSTAGRAM_TOKENS
} from 'Component/Instagram/Instagram.config';
import { getActiveStoreCode } from 'Util/Helpers/getActiveStoreCode';

import Instagram from './Instagram.component';

/** @namespace Scandipwa/Component/Instagram/Container */
export class InstagramContainer extends PureComponent {
    state = {
        isError: false,
        isLoading: true,
        instaPhotos: [],
        instaUsername: ''
    };

    componentDidMount() {
        const activeStoreCode = getActiveStoreCode();
        this._getInstagramPhotos(activeStoreCode);
        this._getInstagramUsername(activeStoreCode);
    }

    containerProps = () => {
        const {
            instaPhotos, isError, isLoading, instaUsername
        } = this.state;

        return {
            instaPhotos,
            instaUsername,
            isError,
            isLoading
        };
    };

    _getInstagramPhotos(activeStoreCode) {
        fetch(`https://graph.instagram.com/me/media?fields=${INSTAGRAM_PHOTOS_FIELDS}&limit=${INSTAGRAM_PHOTOS_LIMIT}&access_token=${INSTAGRAM_TOKENS[activeStoreCode]}`)
            .then(
                /** @namespace Scandipwa/Component/Instagram/Container/InstagramContainer/_getInstagramPhotos/then/then/fetch/then */
                (res) => res.json()
            )
            .then(
                /** @namespace Scandipwa/Component/Instagram/Container/InstagramContainer/_getInstagramPhotos/then/then */
                ({ data }) => {
                    this.setState({
                        isLoading: false,
                        instaPhotos: data
                    });
                },
                /** @namespace Scandipwa/Component/Instagram/Container/InstagramContainer/_getInstagramPhotos/then/then/catch */
                (error) => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                    this.setState({
                        isLoading: false,
                        isError: !!error
                    });
                }
            );
    }

    _getInstagramUsername(activeStoreCode) {
        fetch(`https://graph.instagram.com/me?fields=username&access_token=${INSTAGRAM_TOKENS[activeStoreCode]}`)
            .then(
                /** @namespace Scandipwa/Component/Instagram/Container/InstagramContainer/_getInstagramUsername/then/then/fetch/then */
                (res) => res.json()
            )
            .then(
                /** @namespace Scandipwa/Component/Instagram/Container/InstagramContainer/_getInstagramUsername/then/then */
                ({ username }) => {
                    this.setState({
                        instaUsername: username
                    });
                },
                /** @namespace Scandipwa/Component/Instagram/Container/InstagramContainer/_getInstagramUsername/then/then/catch */
                (error) => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            );
    }

    render() {
        return (
            <Instagram
              { ...this.containerProps() }
            />
        );
    }
}

export default InstagramContainer;
