import React, { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { FIXED_CENTER } from 'Component/Map/Map.config';
import SellerPointQuery from 'Query/SellerPoint.query';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    findLocations,
    initialSearchWithKeys,
    SEARCH_ENGINE_TYPE,
    toGeoJson,
    toStringLocations
} from 'Util/Map/Map';
import { debounce, fetchQuery, getErrorMessage } from 'Util/Request';

import MapWithShops from './MapWithShops.component';

/** @namespace Scandipwa/Component/MapWithShops/Container/mapStateToProps */
export const mapStateToProps = () => ({
});

/** @namespace Scandipwa/Component/MapWithShops/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, msg) => dispatch(showNotification(type, msg))
});

/** @namespace Scandipwa/Component/MapWithShops/Container */
export class MapWithShopsContainer extends PureComponent {
    key = 'id';

    fixedCenter = FIXED_CENTER;

    googleMapsEngine = createRef();

    fuseEngine = createRef();

    minSearchInputValue = 3;

    searchedKeys = [
        ['properties', 'name'],
        ['properties', 'city'],
        ['properties', 'street'],
        ['properties', 'postal_code']
    ];

    state = {
        search: '',
        isLoaded: false,
        isLoading: false,
        locations: '',
        foundLocations: '',
        selectedMarkerKey: undefined
    };

    containerFunctions = {
        handleMapsRef: this.handleMapsRef.bind(this),
        handleInputSearchChange: this.handleInputSearchChange.bind(this),
        handleMarkerClick: this.handleMarkerClick.bind(this)
    };

    handleSearchLocationsUpdate = debounce(async () => {
        const { search, locations } = this.state;

        this.setState({
            isLoading: false,
            foundLocations: await findLocations(SEARCH_ENGINE_TYPE.FUSE, this.fuseEngine.current, {
                locations,
                search,
                minSearchValue: this.minSearchInputValue
            })
        });
    }, 1000);

    componentDidMount() {
        this.handlePrepareLocations();
    }

    handleMapsRef(maps) {
        this.setState({ isLoaded: true }, () => {
            this.googleMapsEngine.current = maps;
        });
    }

    async handlePrepareLocations() {
        const { showNotification } = this.props;

        this.setState({
            isLoading: true
        });

        try {
            const locations = toStringLocations(
                (await this.handleLocationsFetch())
                    .map((location) => toGeoJson(location, this.key))
            );

            this.fuseEngine.current = initialSearchWithKeys(locations, this.searchedKeys);

            this.setState(
                {
                    locations
                }
            );
        } catch (e) {
            showNotification('error', getErrorMessage(e));
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }

    async handleLocationsFetch(_search = '') {
        try {
            const { sellerPoint } = await fetchQuery(SellerPointQuery.getSellerPoint());

            return sellerPoint;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    handleInputSearchChange({ target: { value: search } }) {
        this.setState(
            {
                isLoading: search >= this.minSearchInputValue,
                search
            },
            () => this.handleSearchLocationsUpdate()
        );
    }

    handleMarkerClick(selectedMarkerKey) {
        this.setState({
            selectedMarkerKey
        });
    }

    containerProps() {
        const {
            isLoaded, isLoading, selectedMarkerKey, search, locations, foundLocations
        } = this.state;

        return {
            search,
            isLoaded,
            isLoading,
            locations,
            foundLocations,
            selectedMarkerKey,
            fixedCenter: this.fixedCenter
        };
    }

    render() {
        return <MapWithShops { ...this.containerFunctions } { ...this.containerProps() } />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapWithShopsContainer);
