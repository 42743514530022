import {
    motion,
    useScroll
} from 'framer-motion/dist/framer-motion';
import {
    Children,
    cloneElement,
    isValidElement,
    useRef
} from 'react';

import './ProgressBar.style.scss';

/** @namespace Scandipwa/Component/ProgressBar/Component/ProgressBarValue */
export function ProgressBarValue({ ref }) {
    const { scrollYProgress: scaleX } = useScroll({
        target: ref,
        offset: ['start start', 'end end']
    });

    return <motion.div className="ProgressBar-Value" style={ { scaleX } } />;
}

/** @namespace Scandipwa/Component/ProgressBar/Component/ProgressBar */
export function ProgressBar({ children, visible }) {
    const ref = useRef(null);

    return (
        <>
            { visible ? (
                <motion.div className="ProgressBar">
                   <ProgressBarValue ref={ ref } />
                </motion.div>
            ) : null }
            { Children.map(children, (child) => {
                if (isValidElement(child)) {
                    return cloneElement(child, { ref, ...child.props });
                }

                return child;
            }) }
        </>
    );
}

export default ProgressBar;
