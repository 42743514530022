import BlogPostCard from '@osavi/blog/src/component/BlogPostCard/BlogPostCard.component';
import BlogSearchBar from '@osavi/blog/src/component/BlogSearchBar/BlogSearchBar.container';
import { POSTS_LISTING } from '@osavi/blog/src/route/PostsListing/PostsListing.config';
import React from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import { getQueryParam } from 'Util/Url';

import '../route/PostListing/PostListing.style.scss';

export default {
    'Osavi/Blog/Route/PostsListing/Container': {
        'member-function': {
            updateBreadcrumbs(args, callback, instance) {
                const { updateBreadcrumbs } = instance.props;
                const breadcrumbs = [
                    ...instance.getCategoryBreadcrumb(),
                    {
                        url: '/blog',
                        name: __('Blog')
                    }
                ];

                updateBreadcrumbs(breadcrumbs);
            },

            getCategoryTitleFromUrl(args, callback, instance) {
                const { history, categories } = instance.props;

                if (Object.keys(categories).length) {
                    const location = history?.location?.search;
                    const separaterIndex = location.indexOf('=') + 1;
                    if (separaterIndex) {
                        const result = categories.items.find((e) => e.identifier === location.slice(separaterIndex));
                        return result?.title;
                    }
                }

                return __('Blog');
            },

            updateMeta(args, callback, instance) {
                const { updateMeta } = instance.props;

                updateMeta({
                    title: __('Blog'),
                    canonical_url: instance.getCanonicalUrl()
                });
            },
            getCategoryFromUrl(args, callback, instance) {
                const [location] = args;
                const { location: locationFromProps } = instance.props;
                return getQueryParam('category', location || locationFromProps) || '';
            },
            componentDidUpdate(args, callback, instance) {
                const [prevProps] = args;
                const { isCategoryOverlayOpen } = instance.state;
                const {
                    setNavigationState,
                    setHeaderState,
                    history,
                    location,
                    posts_per_page
                } = instance.props;
                const { posts_per_page: prev_posts_per_page, location: prevLocation } = prevProps;
                const title = instance.getCategoryTitleFromUrl();

                if (
                    instance.getCategoryFromUrl(prevLocation) !== instance.getCategoryFromUrl(location)
                    || (posts_per_page !== prev_posts_per_page && posts_per_page)
                ) {
                    instance.updateCategory();
                }

                setHeaderState({
                    name: POSTS_LISTING,
                    title: `${isCategoryOverlayOpen ? __('Categories') : title}`,
                    onBackClick: () => (isCategoryOverlayOpen ? instance.closeOverlay() : history.goBack())
                });

                setNavigationState('blog');
            }
        }
    },
    'Osavi/Blog/Route/PostsListing/Component/PostsListingComponent': {
        'member-function': {
            componentDidMount() {
                window.scrollTo(0, 0);
            },

            getHeroPosts(args, callback, instance) {
                const {
                    posts: { items }
                } = instance.props;

                return (items ?? []).filter((_, i) => i === 0);
            },

            getPosts(args, callback, instance) {
                const {
                    posts: { items }
                } = instance.props;

                return (items ?? []).filter((_, i) => i > 0);
            },

            renderGrid(args, callback, instance) {
                const {
                    isLoaded,
                    pageSize
                } = instance.props;

                const items = instance.getPosts();

                if (isLoaded && items.length) {
                    return items.map((post) => (
                        <BlogPostCard
                          key={ post.post_id }
                          block="BlogPostCard"
                          post={ post }
                        />
                    ));
                }

                if (isLoaded) {
                    return (
                        <span>
                { __('No posts in this category') }
                        </span>
                    );
                }

                return Array.from({ length: pageSize }, (_, i) => (
                    <BlogPostCard
                      key={ i }
                      block="BlogPostCard"
                      isPlaceholder
                    />
                ));
            },

            renderHero(args, callback, instance) {
                const {
                    isLoaded
                } = instance.props;

                const items = instance.getHeroPosts();

                if (isLoaded && items.length) {
                    return items.map((post) => (
                        <BlogPostCard
                          key={ post.post_id }
                          block="BlogPostCard"
                          post={ post }
                          isHero
                        />
                    ));
                }

                return null;
            },

            renderTitle(args, callback, instance) {
                const { updateBreadcrumbs, getCategoryTitle } = instance.props;

                updateBreadcrumbs();

                return (
                    <h3 block="PostsListing" elem="Title">
                        { getCategoryTitle() || __('Your portion of knowledge') }
                    </h3>
                );
            },
            renderMainContent(args, callback, instance) {
                return (
                    <>
                        <div block="PostsListing" elem="Grid">
                            { instance.renderGrid() }
                        </div>
                        { instance.renderLoadMore() }
                    </>
                );
            },

            render(args, callback, instance) {
                const { device } = instance.props;

                return (
                    <main block="PostsListing" mods={ { isOverridden: true } }>
                        <div block="PostsListing" elem="Hero">
                            <div block="PostsListing" elem="HeroWrapper">
                                { instance.renderTitle() }
                                { device.isMobile ? instance.renderMobileBlogCategories() : instance.renderHero() }
                            </div>
                        </div>
                        <ContentWrapper label="PostsListing">
                            <div block="PostsListing" elem="ColumnWrapper">
                                <div block="PostsListing" elem="Sidebar">
                                    <BlogSearchBar mix={ { block: 'PostsListing', elem: 'Search' } } />
                                    { instance.renderBlogCategoriesIfNotMobile() }
                                </div>
                                <div block="PostsListing" elem="GridWrapper">
                                    { instance.renderPostListingDetails() }
                                </div>
                            </div>
                        </ContentWrapper>
                    </main>
                );
            }
        }
    }
};
