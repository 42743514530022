import { stringify } from 'rebem-classname';

import { Link as SourceLink } from 'SourceComponent/Link/Link.component';

/** @namespace Scandipwa/Component/Link/Component */
export class LinkComponent extends SourceLink {
    renderRelativePathLink() {
        const {
            isOpenInNewTab,
            children,
            to,
            className,
            bemProps,
            ...props
        } = this.props;

        const classNameConverted = `${ className } ${ stringify(bemProps)}`;

        if (isOpenInNewTab) {
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <a
                  { ...props }
                  onClick={ this.scrollToElement }
                  href={ to }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                    { children }
                </a>
            );
        }

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <a
              { ...props }
              className={ classNameConverted }
              onClick={ this.scrollToElement }
              href={ to }
            >
                { children }
            </a>
        );
    }
}

export default LinkComponent;
