import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/ResellerContactForm/Query */
export class ResellerContactForm {
    getQuery({ email, name }) {
        return new Field('distributorForm')
            .addArgument('email', 'String!', email)
            .addArgument('name', 'String!', name).addFieldList(['response', 'error']);
    }
}

export default new ResellerContactForm();
