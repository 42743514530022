import {
    filterData,
    getInitialState as sourceGetInitialState,
    MetaReducer,
    updateEveryTime
} from 'SourceStore/Meta/Meta.reducer';

export {
    updateEveryTime,
    filterData,
    MetaReducer
};

/** @namespace Scandipwa/Store/Meta/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    alternatives: []
});

export default MetaReducer;
