export default {
    'Osavi/Blog/Route/PostsDetails/Container': {
        'member-function': {
            updateBreadcrumbs(args, callback, instance) {
                const { updateBreadcrumbs, post, device: { isMobile } = {} } = instance.props;
                const {
                    title, categories = []
                } = post || {};
                const { title: breadcrumb_title, identifier: breadcrumb_identifier } = categories[0] || {};

                if (!title) {
                    return;
                }

                updateBreadcrumbs([
                    ...(isMobile ? [] : [{
                        name: title
                    }]),
                    {
                        name: breadcrumb_title,
                        url: `/blog?category=${breadcrumb_identifier}`
                    },
                    {
                        url: '/blog',
                        name: __('Blog')
                    }
                ]);
            },
            updateMeta(args, callback, instance) {
                const {
                    post: {
                        title
                    }
                } = instance.props;

                if (!title) {
                    return;
                }

                callback(...args);
            },
            requestPosts(args, callback, instance) {
                const { requestPosts } = instance.props;
                const { postsCount } = instance.state;

                if (!postsCount) {
                    requestPosts({ filter: {} });
                } else {
                    requestPosts({ pageSize: postsCount, filter: {} });
                }
            },

            requestPost(args, callback, instance) {
                const { requestPost } = instance.props;
                const { postOptions } = instance.options;

                instance.setState({ isLoaded: true });

                if (requestPost && postOptions) {
                    requestPost({
                        ...postOptions,
                        id: instance.getUrlParam()
                    });

                    instance.setState({ isPostMatch: true });
                }

                return null;
            }
        }
    }
};
