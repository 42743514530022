import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    NoMatchContainer as SourceNoMatchContainer
} from 'SourceRoute/NoMatch/NoMatch.container';

export {
    mapDispatchToProps,
    mapStateToProps
};

/** @namespace Scandipwa/Route/NoMatch/Container */
export class NoMatchContainer extends SourceNoMatchContainer {
    componentWillUnmount() {
        const { updateNoMatch } = this.props;

        updateNoMatch(false);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoMatchContainer);
