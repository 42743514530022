import React, { PureComponent } from 'react';

import Icons from 'Component/Icons';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import Map from 'Component/Map';
import VirtualList from 'Component/VirtualList';
import { eventPersist, onEnterPressDefault } from 'Util/Element';
import { toArrayLocations } from 'Util/Map/Map';

import './MapWithShops.style';

/** @namespace Scandipwa/Component/MapWithShops/Component */
export class MapWithShops extends PureComponent {
    renderSearch() {
        const { handleInputSearchChange, search } = this.props;

        return (
            <div block="MapWithShops" elem="Search">
                <input
                  placeholder={ __('Find a shop') }
                  value={ search }
                  onKeyUp={ onEnterPressDefault }
                  onKeyDown={ onEnterPressDefault }
                  onChange={ eventPersist(handleInputSearchChange) }
                />
            </div>
        );
    }

    renderHint({
        point_key, city, name, street, postal_code, google_business_card

    }, { handleChildReset }) {
        const { selectedMarkerKey } = this.props;

        if (selectedMarkerKey === undefined || selectedMarkerKey !== point_key) {
            return null;
        }

        return (

            <div
              block="MapWithShops"
              elem="Hint"
            >
                <button block="MapWithShops" elem="Hint-Close" type="button" onClick={ handleChildReset }>
                    <Icons name="close" height="18" width="18" fill="#000000" />
                </button>
                <Icons name="marker" height="21" width="21" />
                <div block="MapWithShops" elem="Hint-Place">
                    <h4 block="MapWithShops" elem="Hint-Name">
                        { name }
                    </h4>
                    <p block="MapWithShops" elem="Hint-Address">
                        { street }
                        { ', ' }
                        { postal_code }
                        { ' ' }
                        { city }
                    </p>
                    { google_business_card
                        ? (
                            <Link
                              block="MapWithShops"
                              elem="Hint-Link"
                              to={ google_business_card }
                              isOpenInNewTab
                            >
                                { __('View on Google Maps') }
                            </Link>
                        )
                        : null }
                </div>
            </div>
        );
    }

    renderMarker({
        properties: {
            cluster, point_count, ...properties
        }
    }, functions) {
        return (
            <div block="MapWithShops" elem="Marker-Wrapper">
                { !cluster ? this.renderHint(properties, functions) : null }
                <button
                  block="MapWithShops"
                  elem="Marker"
                  mods={ { isCluster: cluster } }
                  type="button"
                >
                    { cluster ? point_count : <Icons name="marker" height="24" width="24" /> }
                </button>
            </div>
        );
    }

    renderMap() {
        const {
            fixedCenter, selectedMarkerKey, locations, foundLocations, handleMarkerClick, handleMapsRef
        } = this.props;

        if (!locations.length) {
            return null;
        }

        return (
            <div block="MapWithShops" elem="Map" id="MapWithShops-Map">
                <Map
                  fixedCenter={ fixedCenter }
                  pointSelected={ selectedMarkerKey }
                  points={ foundLocations || locations }
                  onChildClick={ handleMarkerClick }
                  onMapsLoaded={ handleMapsRef }
                >
                    { this.renderMarker.bind(this) }
                </Map>
            </div>
        );
    }

    renderItem({
        properties: {
            point_key, city, name, street,
            postal_code, google_business_card
        } = {}
    }, ref, index) {
        const {
            selectedMarkerKey, handleMarkerClick, locations, foundLocations
        } = this.props;
        const data = foundLocations ? toArrayLocations(foundLocations) : toArrayLocations(locations);

        return (
            <li
              block="MapWithShops"
              elem="Item-Wrapper"
              mods={ { lastChild: index === data.length - 1, selected: selectedMarkerKey === point_key } }
              onClick={ () => handleMarkerClick(point_key) }
              ref={ ref }
            >
                <div block="MapWithShops" elem="Item">
                    <Icons name="marker" height="21" width="21" />
                    <div block="MapWithShops" elem="Item-Details">
                        <div block="MapWithShops" elem="Item-NameAndAddress">
                            <h4 block="MapWithShops" elem="Item-Name">
                                { name }
                            </h4>
                            <p block="MapWithShops" elem="Item-Address">
                                { street }
                                { ', ' }
                                { postal_code }
                                { ' ' }
                                { city }
                            </p>
                            { google_business_card
                                ? (
                                    <Link
                                      block="MapWithShops"
                                      elem="Item-Link"
                                      to={ google_business_card }
                                      isOpenInNewTab
                                    >
                                        { __('View on Google Maps') }
                                    </Link>
                                )
                                : null }
                        </div>
                    </div>
                </div>
                <span block="MapWithShops" elem="ItemSeparator" />
            </li>
        );
    }

    renderList() {
        const { locations, foundLocations } = this.props;
        const data = foundLocations ? toArrayLocations(foundLocations) : toArrayLocations(locations);

        if (data.length === 0) {
            return (
                <div block="MapWithShops" elem="List">
                    <div block="MapWithShops" elem="Item-Wrapper">
                        { __('Shop not found') }
                    </div>
                </div>
            );
        }

        return (
            <div block="MapWithShops" elem="List">
                <VirtualList list={ data }>
                    { this.renderItem.bind(this) }
                </VirtualList>
            </div>
        );
    }

    renderContent() {
        const { isLoading } = this.props;

        return (
            <>
                <Loader isLoading={ isLoading } />
                <div block="MapWithShops" elem="Header">
                    { this.renderSearch() }
                </div>
                <div block="MapWithShops" elem="Grid">
                    <div block="MapWithShops" elem="Content" mods={ { type: 'list' } }>
                        { this.renderList() }
                    </div>
                    <div block="MapWithShops" elem="Content" mods={ { type: 'map' } }>
                        { this.renderMap() }
                    </div>
                </div>
            </>
        );
    }

    render() {
        const { isLoaded } = this.props;

        return (
            <div block="MapWithShops">
                { this.renderContent() }
                <Loader isLoading={ !isLoaded } />
            </div>
        );
    }
}

export default MapWithShops;
