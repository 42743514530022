import PropTypes from 'prop-types';

import Link from 'Component/Link';
import ProductAttributeValue
from 'Component/ProductAttributeValue';
import { LOT_RESULT_URL } from 'Route/LotResult/LotResult.config';
import {
    ProductAttributes as SourceProductAttributes
} from 'SourceComponent/ProductAttributes/ProductAttributes.component';
import { AttributeType } from 'Type/ProductList';

import './ProductAttributes.style';

/** @namespace Scandipwa/Component/ProductAttributes/Component */
export class ProductAttributesComponent extends SourceProductAttributes {
    static propTypes = {
        attributesWithValues: AttributeType.isRequired,
        attributesGroupCode: PropTypes.string,
        isProductCard: PropTypes.bool
    };

    static defaultProps = {
        attributesGroupCode: '',
        isProductCard: false
    };

    renderAttributeAsLabel(attribute) {
        const { isProductCard } = this.props;
        return isProductCard ? (
            <div block="ProductCard" elem="Label" key={ attribute.attribute_code }>
                <ProductAttributeValue
                  attribute={ attribute }
                  isFormattedAsText
                  mix={ { block: 'ProductCard', elem: 'LabelValue' } }
                />
            </div>
        ) : null;
    }

    renderSingleAttribute(attribute) {
        const { isProductCard } = this.props;
        const { attribute_code, attribute_label } = attribute;
        const isProductCollection = attribute_code === 'product_collection';

        if (isProductCollection) {
            return this.renderAttributeAsLabel(attribute);
        }

        return (
            <div block="ProductAttributes" elem="Attribute" mods={ { isProductCard } } key={ attribute_code }>
                <div block="ProductAttributes" elem="AttributeLabel" mods={ { isProductCard } }>
                    { attribute_label }
                    :
                </div>
                <ProductAttributeValue
                  attribute={ attribute }
                  isFormattedAsText
                  mix={ { block: 'ProductAttributes', elem: 'AttributeValue' } }
                />
            </div>
        );
    }

    renderCategoryName() {
        const { product, isProductCard } = this.props;
        const categories = product?.categories;
        const categoryName = categories && categories.length ? categories.reduce((acc, category, index) => {
            if (index > 0) {
                acc.push(category.name);
            }

            return acc;
        }, []).join(', ') : null;

        return categoryName ? (
                <div block="ProductAttributes" elem="Attribute" mods={ { isProductCard } }>
                    <div block="ProductAttributes" elem="AttributeLabel" mods={ { isProductCard } }>
                        { __('Category') }
                        :
                    </div>
                    <div block="ProductAttributes" elem="AttributeValue">
                        { categoryName }
                    </div>
                </div>
        ) : null;
    }

    renderCertificateLink(item, index) {
        return (
            <>
                { index > 0 ? ' ' : null }
                <Link block="ProductAttributes" elem="AttributeLink" to={ `/${LOT_RESULT_URL}/${item}` } key={ item }>
                    { item }
                </Link>
            </>

        );
    }

    renderCertificates() {
        const { certificates, isProductCard } = this.props;

        if (!certificates?.length) {
            return null;
        }

        return (
            <div block="ProductAttributes" elem="Attribute" mods={ { isProductCard } }>
                <div block="ProductAttributes" elem="AttributeLabel" mods={ { isProductCard } }>
                    { __('Certificates:') }
                </div>
                <div block="ProductAttributes" elem="AttributeValue" mods={ { isCertificates: true } }>
                    { certificates.map((item, index) => this.renderCertificateLink(item, index)) }
                </div>
            </div>
        );
    }

    renderAttributes() {
        const { attributesGroupCode, attributesWithValues } = this.props;

        const attributesArray = Object.values(attributesWithValues);
        const attributes = attributesGroupCode ? attributesArray.filter(
            (attr) => attr.attribute_group_code === attributesGroupCode
        ) : attributesArray;

        if (!attributes.length) {
            return null;
        }

        return (
            <div block="ProductAttributes">
                { this.renderCategoryName() }
                { attributes.map((attr) => this.renderSingleAttribute(attr)) }
                { this.renderCertificates() }
            </div>
        );
    }

    render() {
        return this.renderAttributes();
    }
}
export default ProductAttributesComponent;
