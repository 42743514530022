import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Consents/Query */
export class ConsentsQuery {
    getConsents({ page }) {
        return new Field('getConsents')
            .addArgument('page', 'PageType!', page)
            .addFieldList(this._getConsentsFields());
    }

    _getConsentsFields() {
        return ['consent_id', 'description', 'is_checkbox', 'is_required'];
    }
}

export default new ConsentsQuery();
