import React from 'react';

/** @namespace Scandipwa/Component/ArrowNext/Component/ArrowNext */
export const ArrowNext = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31" viewBox="0 0 32 31" fill="none">
        <rect y="0.0227051" width="32" height="30.0679" rx="15.034" fill="#F3F8FB" />
        <path
          d="M14.0488 19.8236L18.7317 15.4234L14.0488 11.0232"
          stroke="#9EC5DF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
    </svg>
);

export default ArrowNext;
