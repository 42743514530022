import { prepareQuery } from '@scandipwa/scandipwa/src/util/Query';
import { connect } from 'react-redux';

import {
    ContactFormContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps
} from 'Component/ContactForm/ContactForm.container';
import ResellerContactFormQuery from 'Query/ResellerContactForm.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { executePost, getErrorMessage } from 'Util/Request';

import ResellerContactForm from './ResellerContactForm.component';

export {
    mapStateToProps
};

/** @namespace Scandipwa/Component/ResellerContactForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Scandipwa/Component/ResellerContactForm/Container */
export class ResellerContactFormContainer extends ContactFormContainer {
    state = {
        ...this.state,
        isLoading: false
    };

    async onFormSuccess(fields) {
        const { showNotification } = this.props;
        const consentsNewsletter = this.consentsNewsletterRef.current.state;
        const { areAllRequiredConsentsAccepted } = consentsNewsletter;

        if (!areAllRequiredConsentsAccepted) {
            this.onFormError();
        } else {
            this.setState({
                isLoading: true
            });

            try {
                const { distributorForm } = await executePost(prepareQuery(ResellerContactFormQuery.getQuery(fields)));
                const { error, response } = distributorForm || {};

                showNotification(error ? 'error' : 'success', error || response);
            } catch (error) {
                showNotification('error', getErrorMessage(error));
            } finally {
                this.setState({
                    isLoading: false
                });
            }
        }
    }

    containerProps() {
        const { isLoading } = this.state;

        return {
            ...super.containerProps(),
            isLoading
        };
    }

    render() {
        return (
            <ResellerContactForm
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerContactFormContainer);
