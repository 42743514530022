import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace Scandipwa/Component/Button/Component */
export class ButtonComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        priority: PropTypes.oneOf(['primary', 'secondary', 'other'])
    };

    static defaultProps = {
        priority: 'other'
    };

    render() {
        const { children, priority, ...rest } = this.props;

        return (
            /* eslint-disable-next-line */
            <button block="Button" mods={ { priority } } { ...rest }>
                { children }
            </button>
        );
    }
}

export default ButtonComponent;
