import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static defaultProps = {
        ...super.defaultProps,
        withViewButton: false,
        showAttributes: true
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
