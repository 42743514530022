import React from 'react';

/** @namespace Scandipwa/Component/ArrowPrev/Component/ArrowPrev */
export const ArrowPrev = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31" viewBox="0 0 32 31" fill="none">
        <rect
          x="32"
          y="30.0906"
          width="32"
          height="30.0679"
          rx="15.034"
          transform="rotate(180 32 30.0906)"
          fill="#F3F8FB"
        />
        <path
          d="M17.9512 10.2897L13.2683 14.6899L17.9512 19.0901"
          stroke="#9EC5DF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
    </svg>
);

export default ArrowPrev;
