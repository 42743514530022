import { MixType } from '@scandipwa/scandipwa/src/type/Common';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Slick from 'react-slick';

import { DEFAULT_SLIDER_SETTINGS } from './AdvoxSlider.config';

import './AdvoxSlider.style';
import './slick.scss';

/** @namespace Scandipwa/Component/AdvoxSlider/Component */
export class AdvoxSliderComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        variant: PropTypes.string,
        subVariant: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        settings: PropTypes.shape({
            dots: PropTypes.bool,
            arrows: PropTypes.bool,
            speed: PropTypes.number,
            slidesToShow: PropTypes.number,
            slidesToScroll: PropTypes.number,
            infinite: PropTypes.bool,
            draggable: PropTypes.bool
        }),
        mix: MixType
    };

    static defaultProps = {
        children: [],
        variant: 'default',
        subVariant: '',
        settings: DEFAULT_SLIDER_SETTINGS,
        mix: {}
    };

    render() {
        const {
            children, settings, variant, subVariant, mix
        } = this.props;

        const sliderSettings = { ...DEFAULT_SLIDER_SETTINGS, ...settings };

        // prevent infinite issue when we don't have enough slides
        // (slick would copy and collapse them to next row)
        if (Object.values(children).length < sliderSettings.slidesToShow) {
            sliderSettings.infinite = false;
        }

        return (
            <div block="AdvoxSlider" mods={ { variant, subVariant } } mix={ mix }>
                <Slick { ...sliderSettings }>{ children }</Slick>
            </div>
        );
    }
}

export default AdvoxSliderComponent;
