export const DEFAULT_SLIDER_SETTINGS = {
    dots: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    draggable: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 728,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
