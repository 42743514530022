import { connect } from 'react-redux';

import BreadcrumbsReducer from 'Store/Breadcrumbs/Breadcrumbs.reducer';
import { withReducers } from 'Util/DynamicReducer';

import Breadcrumbs from './Breadcrumbs.component';

/** @namespace Scandipwa/Component/Breadcrumbs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    breadcrumbs: state.BreadcrumbsReducer.breadcrumbs,
    areBreadcrumbsVisible: state.BreadcrumbsReducer.areBreadcrumbsVisible
});

/** @namespace Scandipwa/Component/Breadcrumbs/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

export default withReducers({
    BreadcrumbsReducer
})(connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs));
