import * as Sentry from '@sentry/react';

import {
    App as SourceApp
} from 'SourceComponent/App/App.component';

/** @namespace Scandipwa/Component/App/Component */
export class AppComponent extends SourceApp {}

export default Sentry.withProfiler(AppComponent);
