/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */

import { BLOG_SEARCH } from '@osavi/blog/src/component/BlogSearchBar/BlogSearchBar.config';
import { getPostUrl } from '@osavi/blog/src/util/Posts';
import React from 'react';

import Image from 'Component/Image';
import Overlay from 'Component/Overlay';
import TextPlaceholder from 'Component/TextPlaceholder';
import { getActiveStoreCode } from 'Util/Helpers/getActiveStoreCode';

import '../component/BlogSearchOverlay/BlogSearchOverlay.style.scss';

export default {
    'Osavi/Blog/Component/BlogSearchOverlay/Component/BlogSearchOverlayComponent': {
        'member-function': {
            renderSearchItem([post, i]) {
                const { title, first_image } = post;
                const isNoImage = !first_image || first_image === 'false';
                const postUrl = `/${getActiveStoreCode()}${getPostUrl(post)}`;

                return (
                    <li block="BlogSearchOverlay" elem="Item" key={ `searchItem${i}` } mods={ { isNoImage } }>
                        <a
                          block="BlogSearchOverlay"
                          elem="Link"
                          href={ postUrl }
                        >
                            <figure block="BlogSearchOverlay" elem="Wrapper">
                                { !isNoImage ? (
                                    <Image
                                      src={ first_image }
                                      ratio="custom"
                                      alt={ __('Product %s thumbnail.', title) }
                                      isPlaceholder={ !first_image }
                                    />
                                ) : null }
                                <figcaption block="BlogSearchOverlay" elem="Content">
                                    <h4 block="BlogSearchOverlay" elem="Title" mods={ { isLoaded: !!name } }>
                                        <TextPlaceholder content={ title } length="medium" />
                                    </h4>
                                </figcaption>
                            </figure>
                        </a>
                    </li>
                );
            },

            render(args, callback, instance) {
                return (
                    <Overlay id={ BLOG_SEARCH } mix={ { block: 'BlogSearchOverlay' } } isRenderInPortal={ false }>
                        <article block="BlogSearchOverlay" elem="Results">
                            <div block="BlogSearchOverlay" elem="ResultsWrapper">
                                { instance.renderSearchResults() }
                            </div>
                        </article>
                    </Overlay>
                );
            }
        }
    }
};
