import { Field } from 'SourceUtil/Query';

export default {
    'Osavi/Blog/Query/BlogPost/Query': {
        'member-function': {
            postField([options], callback, instance) {
                const {
                    getBasicDetails = true,
                    getDescription,
                    getMedia,
                    getRelated,
                    getBreadcrumbs
                } = options;

                const fields = [];
                const author = instance.authorField();
                const relatedPosts = instance.relatedPostsField();
                const categories = instance.categoriesField();
                const verifiedUser = instance.verifiedUser();

                if (getBasicDetails) {
                    fields.push(
                        'post_id',
                        'identifier',
                        'title',
                        'publish_time',
                        'reading_time',
                        categories,
                        verifiedUser,
                        ...instance.metaFields()
                    );
                }

                if (getDescription) {
                    fields.push('short_content', author, 'content', 'filtered_content');
                }

                if (getMedia) {
                    fields.push('og_image', 'first_image', 'media_gallery');
                }

                if (getRelated) {
                    fields.push(relatedPosts, 'related_products');
                }

                return fields;
            },

            relatedPostsField(args, callback, instance) {
                return new Field('related_posts')
                    .addFieldList([
                        'post_id',
                        'identifier',
                        'title',
                        'publish_time',
                        'first_image',
                        instance.categoriesField()
                    ]);
            },

            categoriesField() {
                return new Field('categories').addFieldList(['category_id', 'identifier', 'title']);
            },
            verifiedUser() {
                return new Field('verified_user').addFieldList(['firstname',
                    'lastname',
                    'user_id',
                    'user_image']);
            }
        }
    }
};
