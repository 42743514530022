import { getPostUrl } from '@osavi/blog/src/util/Posts';
import React from 'react';

import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';

import '../component/BlogRecentPosts/BlogRecentPosts.style.scss';

export default {
    'Osavi/Blog/Component/BlogRecentPosts/Component/BlogRecentPostsComponent': {
        'member-function': {
            renderCard([post, key]) {
                const { title, first_image, publish_time } = post || {};
                const postUrl = getPostUrl(post);

                const dateFormatted = new Date(publish_time).toLocaleDateString('pl-PL');
                const isNoImage = !first_image || first_image === 'false';

                return (
                    <div
                      block="BlogRecentPosts"
                      elem="PostCard"
                      key={ key }
                    >
                        { !isNoImage ? (
                            <Link
                              to={ postUrl }
                              title={ __('View %s', title) }
                              mix={ { block: 'BlogRecentPosts', elem: 'ImageWrapper' } }
                            >
                                <img src={ first_image } alt={ title } />
                            </Link>
                        ) : null }

                        <div
                          block="BlogRecentPosts"
                          elem="Wrapper"
                          mods={ { isNoImage } }
                        >
                            <Link to={ postUrl } title={ __('View %s', title) }>
                                <h3 block="BlogRecentPosts" elem="PostTitle">
                                    <TextPlaceholder
                                      mix={ { block: 'BlogRecentPosts', elem: 'PostTitlePlaceholder' } }
                                      content={ title }
                                      length="short"
                                    />
                                </h3>
                            </Link>
                            <div block="BlogRecentPosts" elem="Date">
                                <TextPlaceholder
                                  mix={ { block: 'BlogRecentPosts', elem: 'DatePlaceholder' } }
                                  content={ dateFormatted }
                                  length="custom"
                                />
                            </div>
                        </div>
                    </div>
                );
            },

            render(args, callback, instance) {
                return (
                    <div block="BlogRecentPosts" mods={ { isOverridden: true } }>
                        <h3 block="BlogRecentPosts" elem="Title">
                            { __('Recent Posts') }
                        </h3>
                        { instance.renderContents() }
                    </div>
                );
            }
        }
    }
};
