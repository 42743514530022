import BlogRelatedPosts from '@osavi/blog/src/component/BlogRelatedPosts';
import BlogRelatedProducts from '@osavi/blog/src/component/BlogRelatedProducts';
import React from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import Icons from 'Component/Icons';
import Image from 'Component/Image';
import Link from 'Component/Link';
import ProgressBar from 'Component/ProgressBar';
import ScrollToTop from 'Component/ScrollToTop';
import TextPlaceholder from 'Component/TextPlaceholder';
import NoMatch from 'Route/NoMatch';
import { formatDate } from 'Util/Browser/Browser';
import { getActiveStoreCode } from 'Util/Helpers/getActiveStoreCode';

import BlogTableOfContents from '../component/BlogTableOfContents';

import '../route/PostDetails/PostDetails.style.scss';

export default {
    'Osavi/Blog/Route/PostsDetails/Component/PostsDetailsComponent': {
        'member-function': {
            componentDidMount() {
                window.scrollTo(0, 0);
            },

            renderBackLink() {
                return (
                    <Link
                      block="PostsDetails"
                      elem="BackLink"
                      mix={ { block: 'Button', mods: { isHollow: true } } }
                      to={ `/${getActiveStoreCode()}/blog` }
                    >
                        { __('Back to list of articles') }
                    </Link>
                );
            },

            renderCategoryList([categories]) {
                if (!categories?.length) {
                    return null;
                }

                return categories.map(({ category_id, identifier, title }, key) => {
                    const url = `/${getActiveStoreCode()}/blog?category=${identifier}`;

                    return (
                        <span
                          block="PostsDetails"
                          elem="Category"
                          key={ `postDetailsCategory${category_id}` }
                        >
                            { key > 0 ? (
                                <span
                                  block="PostsDetails"
                                  elem="CategorySeparator"
                                >
                                    |
                                </span>
                            ) : null }
                            <Link to={ url }>
                                { title }
                            </Link>
                        </span>
                    );
                });
            },

            renderCategories(args, callback, instance) {
                const { post: { categories } } = instance.props;

                if (!categories?.length) {
                    return null;
                }

                return (
                    <div block="PostsDetails" elem="Categories">
                        { instance.renderCategoryList(categories) }
                    </div>
                );
            },

            renderContent(args, callback, instance) {
                const { post: { filtered_content } } = instance.props;

                if (!filtered_content) {
                    return (
                        <TextPlaceholder
                          mix={ { block: 'PostsDetails', elem: 'ContentPlaceholder' } }
                          length="custom"
                        />
                    );
                }

                return (
                    <div id="post-content" mix={ { block: 'PostsDetails', elem: 'Content' } }>
                        <Html content={ filtered_content } />
                    </div>

                );
            },

            renderImage(args, callback, instance) {
                const { post: { title, first_image } } = instance.props;

                return (
                    <Image
                      block="PostsDetails"
                      elem="Image"
                      src={ first_image }
                      ratio="custom"
                      isPlaceholder={ (!first_image || first_image === 'false') }
                      alt={ title }
                    />
                );
            },

            renderInfo(args, callback, instance) {
                const { post: { author, publish_time, reading_time } } = instance.props;

                return (
                    <div
                      block="PostsDetails"
                      elem="Info"
                    >
                        { [formatDate(publish_time), author?.name, reading_time ? (
                            <>
                                <Icons name="clock" height={ 16 } width={ 16 } />
                                { __('%s min', reading_time) }
                            </>
                        ) : undefined].filter(Boolean).map((value, key) => (
                            <span
                              block="PostsDetails"
                              elem="InfoItem"
                              key={ key }
                            >
                            { key > 0 ? (
                                <span
                                  block="PostsDetails"
                                  elem="InfoItemSeparator"
                                >
                                    |
                                </span>
                            ) : null }
                                <span>
                                { value }
                                </span>
                            </span>

                        )) }
                    </div>
                );
            },

            renderVerifiedUser(args, callback, instance) {
                const {
                    post: {
                        verified_user
                    }
                } = instance.props;

                if (!verified_user?.user_id) {
                    return null;
                }

                const {
                    user_image, firstname,
                    lastname
                } = verified_user;

                return (
                    <div block="PostsDetails" elem="VerifiedUser">
                        { user_image ? (
                            <div block="PostsDetails" elem="VerifiedUserImage">
                                <Image src={ user_image } isPlaceholder={ !user_image } />
                            </div>
                        ) : null }
                        <p block="PostsDetails" elem="VerifiedUserLabel">
                            { __('Verified by') }
                        </p>
                        <p block="PostsDetails" elem="VerifiedUserName">
                            { [firstname, lastname].filter(Boolean).join(' ') }
                        </p>
                    </div>
                );
            },

            renderTableOfContents(args, callback, instance) {
                const { post: { filtered_content } } = instance.props;

                return (
                    <BlogTableOfContents content={ filtered_content } />
                );
            },

            renderMainContent(args, callback, instance) {
                const { device: { isMobile } } = instance.props;

                const sidebar = (
                    <div block="PostsDetails" elem="Sidebar">
                        { instance.renderVerifiedUser() }
                        { instance.renderTableOfContents() }
                        <BlogRelatedProducts />
                    </div>
                );

                return (
                    <>
                        <div block="PostsDetails" elem="Header">
                            { instance.renderInfo() }
                            { instance.renderTitle() }
                            { instance.renderCategories() }
                        </div>
                        <div block="PostsDetails" elem="Body">
                            { isMobile ? sidebar : null }
                            <div block="PostsDetails" elem="ContentWrapper">
                                { instance.renderContent() }
                                { instance.renderBackLink() }
                            </div>
                            { !isMobile ? sidebar : null }

                        </div>
                    </>
                );
            },

            render(args, callback, instance) {
                const {
                    isPostMatch,
                    isLoaded
                } = instance.props;

                if (!isPostMatch && isLoaded) {
                    return (
                        <NoMatch />
                    );
                }

                return (
                    <ProgressBar visible={ isLoaded }>
                        <main block="PostsDetails" mods={ { isOverridden: true } }>
                            <div block="PostsDetails" elem="Hero">
                                <div block="PostsDetails" elem="HeroWrapper">
                                    { instance.renderImage() }
                                </div>
                            </div>
                            <ContentWrapper
                              wrapperMix={ { block: 'PostsDetails', elem: 'Wrapper' } }
                              label="Post"
                            >
                                { instance.renderMainContent() }
                            </ContentWrapper>
                            <BlogRelatedPosts />
                            <ScrollToTop />
                        </main>
                    </ProgressBar>
                );
            }
        }
    }
};
