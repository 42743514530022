import {
    IMAGE_LOADED, IMAGE_NOT_FOUND
} from 'Component/Image/Image.config';
import { Image as SourceImage } from 'SourceComponent/Image/Image.component';
import { isCrawler } from 'Util/Browser';

/** @namespace Scandipwa/Component/Image/Component */
export class Image extends SourceImage {
    onError() {
        if (isCrawler()) {
            this.setState({ imageStatus: IMAGE_LOADED });
        } else {
            this.setState({ imageStatus: IMAGE_NOT_FOUND });
        }
    }
}

export default Image;
