import Fuse from 'fuse.js';
import { convertDistance, findNearest, getDistance } from 'geolib';
import Supercluster from 'supercluster';

import { DEFAULT_COORS, DEFAULT_ZOOM, ZOOM } from 'Component/Map/Map.config';

export const MAX_DISTANCE_TO_PLACE = 10;

export const SEARCH_ENGINE_TYPE = {
    GOOGLE: 'google',
    FUSE: 'fuse'
};

/** @namespace Scandipwa/Util/Map/toStringLocations */
export const toStringLocations = (locations) => JSON.stringify(locations || []);

/** @namespace Scandipwa/Util/Map/toArrayLocations */
export const toArrayLocations = (locations) => JSON.parse(locations || '[]');

/** @namespace Scandipwa/Util/Map/toGeoJson */
export const toGeoJson = ({ longitude, latitude, ...location }, key) => ({
    type: 'Feature',
    properties: {
        cluster: false,
        ...location,
        point_key: location[key]
    },
    geometry: {
        type: 'Point',
        coordinates: [Number(longitude), Number(latitude)]
    }
});

/** @namespace Scandipwa/Util/Map/initialCluster */
export const initialCluster = () => new Supercluster({
    radius: 100,
    maxZoom: 13,
    minZoom: 6
});

/** @namespace Scandipwa/Util/Map/initialSearchWithKeys */
export const initialSearchWithKeys = (location, keys = []) => new Fuse(toArrayLocations(location), {
    keys,
    useExtendedSearch: true,
    isCaseSensitive: false
});

/** @namespace Scandipwa/Util/Map/getSearchEngineType */
export const getSearchEngineType = (search) => {
    if (new RegExp('^[A-Z0-9]+$', 'gm').test(search)) {
        return SEARCH_ENGINE_TYPE.FUSE;
    }

    return SEARCH_ENGINE_TYPE.GOOGLE;
};

/** @namespace Scandipwa/Util/Map/findLocations */
export const findLocations = (engine, instance, options) => {
    const { search, locations, minSearchValue } = options || {};

    return new Promise((resolve, reject) => {
        if (search.length >= minSearchValue) {
            switch (engine) {
            case SEARCH_ENGINE_TYPE.GOOGLE: {
                const { Geocoder, GeocoderStatus } = instance || {};

                if (Geocoder) {
                    new Geocoder().geocode({ address: search }, (results, status) => {
                        if (status === GeocoderStatus.OK) {
                            const location = results[0].geometry.location || {};

                            resolve(
                                toStringLocations(
                                    toArrayLocations(locations)
                                        .filter(
                                            ({ geometry: { coordinates: [lng, lat] = [] } = {} }) => convertDistance(
                                                getDistance(
                                                    { lat, lng },
                                                    { lat: location.lat(), lng: location.lng() }
                                                ),
                                                'km'
                                            ) < MAX_DISTANCE_TO_PLACE
                                        )
                                        .sort((a, b) => (getDistance(
                                            { lat: b.geometry.coordinates[1], lng: b.geometry.coordinates[0] },
                                            { lat: location.lat(), lng: location.lng() }
                                        )
                                                < getDistance(
                                                    { lat: a.geometry.coordinates[1], lng: a.geometry.coordinates[0] },
                                                    { lat: location.lat(), lng: location.lng() }
                                                )
                                            ? 1
                                            : -1))
                                )
                            );
                        } else {
                            resolve(locations);
                        }
                    });
                } else {
                    resolve(locations);
                }

                break;
            }
            case SEARCH_ENGINE_TYPE.FUSE: {
                const result = instance.search(`'${search}`).map((result) => result.item);
                resolve(toStringLocations(result));
                break;
            }
            default: {
                reject(new Error('Search engine is unknown.'));
                break;
            }
            }
        } else {
            resolve(locations);
        }
    });
};

/** @namespace Scandipwa/Util/Map/getNearestLocation */
export const getNearestLocation = (locations, coords) => findNearest(
    coords,
    toArrayLocations(locations).map(
        ({ properties, geometry: { coordinates: [longitude, latitude] = [] } = {} }) => ({
            location: { ...properties, latitude, longitude },
            lat: Number(latitude),
            lng: Number(longitude)
        })
    )
) || {};

/** @namespace Scandipwa/Util/Map/fitBounds */
export const fitBounds = (points, maps, map) => {
    if (maps && map) {
        const { LatLngBounds, LatLng } = maps;
        const bounds = new LatLngBounds();

        if (points.length > 0) {
            points
                .map(({ geometry: { coordinates: [longitude, latitude] = [] } = {} }) => [
                    Number(latitude),
                    Number(longitude)
                ])
                .forEach((coordinates) => {
                    bounds.extend(new LatLng(...coordinates));
                });

            map.fitBounds(bounds);

            if (points.length === 1) {
                map.setZoom(ZOOM);
            }
        } else {
            map.setZoom(DEFAULT_ZOOM);
            map.panTo(DEFAULT_COORS);
        }
    }
};
