import {
    ProductListQuery as SourceProductListQuery
} from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false
        } = this.options;

        // Basic fields returned always
        const fields = [
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            this._getStockItemField()
        ];

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant)) {
            fields.push(
                this._getPriceRangeField(),
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                this._getTierPricesField(),
                this._getCertificatesField()
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getUrlRewritesFields(),
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                this._getCustomizableProductFragment()
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment()
                );
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                'meta_title',
                'meta_keyword',
                'canonical_url',
                'meta_description',
                'allegro_link',
                'amazon_link',
                'ceneo_link',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                this._getProductLinksField(),
                this._getCustomizableProductFragment(),
                this._getAlternativeProductUrls(),
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment()
                );
            }
        }

        return fields;
    }

    _getItemsField() {
        const { isSingleProduct } = this.options;
        const items = super._getItemsField();

        if (!isSingleProduct) {
            items.addField(this._getCategoriesField());
        }

        return items;
    }

    _getCertificatesField() {
        return new Field('lot_numbers');
    }

    _getAlternativeProductUrls() {
        return new Field('alternative_product_urls').addFieldList(['product_id', 'store_code', 'url']);
    }
}

export default new ProductListQuery();
