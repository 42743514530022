import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Button from 'Component/Button';

/** @namespace Scandipwa/Component/QuizButton/Component */
export class QuizButtonComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired
    };

    render() {
        const { children } = this.props;

        return (
            <Button block="QuizPage" elem="Button" priority="primary" onClick={ window?.handleStartQuiz }>
                { children }
            </Button>
        );
    }
}

export default QuizButtonComponent;
