import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ReactPlayer from 'react-player/youtube';

import Icons from 'Component/Icons';

import './VideoPlayer.style';

/** @namespace Scandipwa/Component/VideoPlayer/Component */
export class VideoPlayerComponent extends PureComponent {
    static propTypes = {
        url: PropTypes.string.isRequired
    };

    render() {
        const { url } = this.props;

        return (
            <div block="VideoPlayer">
                <div block="VideoPlayer" elem="Wrapper">
                    <ReactPlayer
                      className="VideoPlayer-Player"
                      url={ url }
                      width="100%"
                      height="100%"
                      controls
                      light
                      playIcon={ <Icons name="play" /> }
                    />
                </div>
            </div>
        );
    }
}

export default VideoPlayerComponent;
