import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/SellerPoint/Query */
export class SellerPointQuery {
    getSellerPoint() {
        return new Field('sellerPoint')
            .addFieldList(this._getSellerPointFields());
    }

    _getSellerPointFields() {
        return [
            'city',
            'id',
            'latitude',
            'longitude',
            'name',
            'store_id',
            'street',
            'postal_code',
            'google_business_card'
        ];
    }
}

export default new SellerPointQuery();
