import PropTypes from 'prop-types';

import Consents from 'Component/Consents';
import { CONSENTS_CONTACT } from 'Component/Consents/Consents.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import {
    ContactForm as SourceContactForm
} from 'SourceComponent/ContactForm/ContactForm.component';

import './ContactForm.override.style.scss';

/** @namespace Scandipwa/Component/ContactForm/Component */
export class ContactFormComponent extends SourceContactForm {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    static propTypes = {
        isSubmitted: PropTypes.bool
    };

    get fieldMap() {
        const { contactTypes, forPage } = this.props;

        return {
            contact_type: {
                type: 'select',
                selectOptions: contactTypes.map((
                    { topicID, topicLabel }
                ) => ({ id: topicID, label: __(topicLabel), value: topicID })),
                validateSeparately: false,
                placeholder: __('Choose a topic of conversation'),
                validation: ['notEmpty'],
                label: forPage ? __('Choose a topic of conversation') : __('Contact Type')
            },
            name: {
                validation: ['notEmpty'],
                placeholder: __('Your name here'),
                label: __('Name')
            },
            email: {
                validation: ['notEmpty', 'email'],
                placeholder: __('Your email here'),
                label: __('Email')
            },
            telephone: {
                label: __('Phone number'),
                placeholder: __('Your phone number here')
            },
            message: {
                type: 'textarea',
                validation: ['notEmpty'],
                placeholder: __('Your message here'),
                label: forPage ? __('How can we help you?') : __('What\'s on your mind?')
            }
        };
    }

    renderConsents() {
        const { isSubmitted, consentsNewsletterRef } = this.props;

        return (
            <Consents
              ref={ consentsNewsletterRef }
              isSubmitted={ isSubmitted }
              page={ CONSENTS_CONTACT }
              color="black"
            />
        );
    }

    renderFields() {
        const { forPage } = this.props;

        if (forPage) {
            const left = Object.entries(this.fieldMap).filter(([key]) => key !== 'message').map(this.renderField);
            const right = Object.entries(this.fieldMap).filter(([key]) => key === 'message').map(this.renderField);

            return (
                <div
                  block="FieldForm"
                  elem="Fields"
                >
                    <div>
                        { left }
                    </div>
                    <div>
                        { right }
                        { this.renderConsents() }
                        <button type="submit" block="Button">
                            { __('Send Your message') }
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div
              block="FieldForm"
              elem="Fields"
            >
                { Object.entries(this.fieldMap).map(this.renderField) }
                { this.renderConsents() }
            </div>
        );
    }

    renderActions() {
        const { isLoading, forPage } = this.props;

        if (forPage) {
            return <Loader isLoading={ isLoading } />;
        }

        return (
            <>
                <Loader isLoading={ isLoading } />
                <button type="submit" block="Button">
                    { __('Send Your message') }
                </button>
            </>
        );
    }

    render() {
        const { onFormSuccess, onFormError } = this.props;

        return (
            <div block="ContactForm">
                <Form
                  onSubmitError={ onFormError }
                  onSubmitSuccess={ onFormSuccess }
                  mix={ { block: 'FieldForm' } }
                >
                    { this.renderFields() }
                    { this.renderActions() }
                </Form>
            </div>
        );
    }
}

export default ContactFormComponent;
