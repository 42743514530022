import { Field } from 'SourceUtil/Query';

export default {
    'Osavi/Blog/Query/BlogPosts/Query/BlogPostsQuery': {
        'member-function': {
            itemField([options], callback, instance) {
                const {
                    getBasicDetails = true,
                    getDescription,
                    getMedia
                } = options || {};

                const fields = [];
                const author = instance.authorField();
                const categories = instance.categoriesField();

                if (getBasicDetails) {
                    fields.push('post_id', 'identifier', 'title', 'publish_time',
                        'reading_time', categories, ...instance.metaFields());
                }

                if (getDescription) {
                    fields.push('short_content', author, 'content');
                }

                if (getMedia) {
                    fields.push('og_image', 'first_image', 'media_gallery');
                }

                return new Field('items').addFieldList(fields);
            },

            categoriesField() {
                return new Field('categories').addFieldList(['category_id', 'identifier', 'title']);
            }
        }
    }
};
