import {
    formatURI, getFetch,
    getGraphqlEndpoint,
    getWindowId,
    handleConnectionError, HTTP_201_CREATED, HTTP_410_GONE, postFetch, putPersistedQuery
} from 'SourceUtil/Request/Request';

export * from 'SourceUtil/Request/Request';

/** @namespace Scandipwa/Util/Request/getCategoryError */
export const getCategoryError = (error) => {
    const {
        extensions
    } = error?.length ? error[0] : error || {};

    return extensions?.category || undefined;
};

/** @namespace Scandipwa/Util/Request/checkForErrors */
export const checkForErrors = (res) => new Promise((resolve, reject) => {
    const { errors, data } = res;

    if (errors) {
        if (Array.isArray(errors)) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return reject([...errors, data]);
        }

        return reject(errors);
    }

    return resolve(data);
});

/**
 * Parse response and check wether it contains errors
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace Scandipwa/Util/Request/parseResponse */
export const parseResponse = (promise) => new Promise((resolve, reject) => {
    promise.then(
        /** @namespace Scandipwa/Util/Request/parseResponse/Promise/promise/then */
        (res) => res.json().then(
            /** @namespace Scandipwa/Util/Request/parseResponse/Promise/promise/then/json/then/resolve */
            (res) => resolve(checkForErrors(res)),
            /** @namespace Scandipwa/Util/Request/parseResponse/Promise/promise/then/json/then/catch */
            () => handleConnectionError('Can not transform JSON!') && reject()
        ),
        /** @namespace Scandipwa/Util/Request/parseResponse/Promise/promise/then/catch */
        (err) => handleConnectionError('Can not establish connection!') && reject(err)
    );
});

/**
 * Make GET request to endpoint (via ServiceWorker)
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @param  {String} name Name of model for ServiceWorker to send BroadCasts updates to
 * @param  {Number} cacheTTL Cache TTL (in seconds) for ServiceWorker to cache responses
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace Scandipwa/Util/Request/executeGet */
export const executeGet = (queryObject, name, cacheTTL) => {
    const { query, variables } = queryObject;
    const uri = formatURI(query, variables, getGraphqlEndpoint());

    return parseResponse(new Promise((resolve) => {
        getFetch(uri, name).then(
            /** @namespace Scandipwa/Util/Request/executeGet/parseResponse/getFetch/then */
            (res) => {
                if (res.status === HTTP_410_GONE) {
                    putPersistedQuery(getGraphqlEndpoint(), query, cacheTTL).then(
                        /** @namespace Scandipwa/Util/Request/executeGet/parseResponse/getFetch/then/putPersistedQuery/then */
                        (putResponse) => {
                            if (putResponse.status === HTTP_201_CREATED) {
                                getFetch(uri, name).then(
                                    /** @namespace Scandipwa/Util/Request/executeGet/parseResponse/getFetch/then/putPersistedQuery/then/getFetch/then/resolve */
                                    (res) => resolve(res)
                                );
                            }
                        }
                    );
                } else {
                    resolve(res);
                }
            }
        );
    }));
};

/**
 * Make POST request to endpoint
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace Scandipwa/Util/Request/executePost */
export const executePost = (queryObject) => {
    const { query, variables } = queryObject;

    return parseResponse(postFetch(getGraphqlEndpoint(), query, variables));
};

/**
 * Listen to the BroadCast connection
 * @param  {String} name Name of model for ServiceWorker to send BroadCasts updates to
 * @return {Promise<any>} Broadcast message promise
 * @namespace Scandipwa/Util/Request/listenForBroadCast */
export const listenForBroadCast = (name) => new Promise((resolve) => {
    const { BroadcastChannel } = window;
    const windowId = getWindowId();

    if (BroadcastChannel) {
        const bc = new BroadcastChannel(`${ name }_${ windowId }`);
        bc.onmessage = (update) => {
            const { data: { payload: body } } = update;
            resolve(checkForErrors(body));
        };
    }
});
