import React, { PureComponent } from 'react';

import Button from 'Component/Button';

import './ShowMore.style.scss';

/** @namespace Scandipwa/Component/ShowMore/Component */
export class ShowMoreComponent extends PureComponent {
    static defaultProps ={
        height: '500px'
    };

    state = {
        isOpen: false
    };

    onToggle() {
        this.setState((prev) => ({
            isOpen: !prev.isOpen
        }));
    }

    getStyle() {
        const { height } = this.props;
        const { isOpen } = this.state;

        return { height: isOpen ? 'auto' : height, overflow: 'hidden' };
    }

    render() {
        const { children } = this.props;
        const { isOpen } = this.state;

        return (
            <div block="ShowMore">
                <div block="ShowMore" elem="Content" mods={ { isOpen } } style={ this.getStyle() }>
                    { children }
                </div>
                <Button
                  priority="primary"
                  mix={ { block: 'ShowMore', elem: 'Button' } }
                  onClick={ this.onToggle.bind(this) }
                >
                    { (isOpen
                        ? __('show-more: Hide')
                        : __('show-more: Read more')).toString().replace('show-more:', '') }
                </Button>
            </div>
        );
    }
}

export default ShowMoreComponent;
