import { ChildrenType } from '@scandipwa/scandipwa/src/type/Common';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentTabs from './ContentTabs.component';

/** @namespace Scandipwa/Component/ContentTabs/Container */
export class ContentTabsContainer extends PureComponent {
    static propTypes = {
        'data-tabs': PropTypes.string.isRequired,
        children: ChildrenType.isRequired
    };

    containerFunctions = {
        handleTabClick: this.handleTabClick.bind(this)
    };

    state = {
        activeTab: 1
    };

    componentDidMount() {
        this.changeActiveTab();
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeTab } = this.state;

        if (prevState.activeTab !== activeTab) {
            this.changeActiveTab();
        }
    }

    changeActiveTab = () => {
        const contentTabs = document.querySelectorAll('.ContentTabs-Content');
        const { activeTab } = this.state;

        contentTabs.forEach((contentTab, index) => {
            if (index + 1 === activeTab && !contentTab.classList.contains('ContentTabs-Content_isActive')) {
                contentTab.classList.add('ContentTabs-Content_isActive');
            } else {
                contentTab.classList.remove('ContentTabs-Content_isActive');
            }
        });
    };

    handleTabClick(id) {
        this.setState({ activeTab: id });
    }

    getArrayTabs() {
        const { 'data-tabs': dataTabs } = this.props;
        const tabNames = JSON.parse(dataTabs.replace(/'/g, '"'));
        const tabs = [];

        tabNames.forEach((tabName, index) => {
            tabs.push({ name: tabName, id: index + 1 });
        });

        return tabs;
    }

    containerProps = () => {
        const { children } = this.props;
        const { activeTab } = this.state;
        return {
            tabs: this.getArrayTabs(),
            children,
            activeTab
        };
    };

    render() {
        return (
            <ContentTabs
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ContentTabsContainer;
