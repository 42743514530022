/* eslint-disable */

/** @namespace Scandipwa/Component/Icons/Config/INSTAGRAM */
export const INSTAGRAM = ({ fill, ...rest }) => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" { ...rest } xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1773 14.7273H4.55006C3.3437 14.7261 2.18712 14.2463 1.3341 13.3932C0.481079
              12.5402 0.00128632 11.3836 0 10.1773V4.55006C0.00128632 3.3437 0.481079
              2.18712 1.3341 1.3341C2.18712 0.481079 3.3437 0.00128632 4.55006 0H10.1773C11.3836
              0.00128632 12.5402 0.481079 13.3932 1.3341C14.2463 2.18712 14.7261
              3.3437 14.7273 4.55006V10.1773C14.7261 11.3836 14.2463 12.5402 13.3932 13.3932C12.5402
              14.2463 11.3836 14.7261 10.1773 14.7273ZM4.55006 1.64087C3.77677
              1.64087 3.03516 1.94806 2.48837 2.49485C1.94158 3.04164 1.63439 3.78325 1.63439
              4.55654V10.1838C1.63439 10.9571 1.94158 11.6987 2.48837 12.2455C3.03516
              12.7922 3.77677 13.0994 4.55006 13.0994H10.1773C10.9506 13.0994 11.6922 12.7922
              12.239 12.2455C12.7858 11.6987 13.093 10.9571 13.093 10.1838V4.55006C13.093
              3.77677 12.7858 3.03516 12.239 2.48837C11.6922 1.94158 10.9506
              1.63439 10.1773 1.63439L4.55006 1.64087Z"
        fill={fill}
      />
      <path
        d="M7.36357 11.1275C6.61903 11.1275 5.89122 10.9067 5.27216
              10.4931C4.65309 10.0795 4.1706 9.49154
              3.88567 8.80367C3.60075 8.11581 3.5262 7.35891 3.67146 6.62867C3.81671
              5.89844 4.17524 5.22768 4.7017 4.70121C5.22817
              4.17475 5.89893 3.81622 6.62916 3.67097C7.35939 3.52572 8.1163 3.60026
              8.80416 3.88519C9.49202 4.17011 10.08 4.65261 10.4936
              5.27167C10.9072 5.89073 11.128 6.61854 11.128 7.36308C11.1267 8.36108
              10.7297 9.31784 10.024 10.0235C9.31832 10.7292 8.36157
              11.1262 7.36357 11.1275ZM7.36357 5.0759C6.91121 5.0759 6.46901 5.21004
              6.09288 5.46136C5.71676 5.71268 5.4236 6.06989 5.25049
              6.48782C5.07738 6.90574 5.03209 7.36562 5.12034 7.80929C5.20859 8.25296
              5.42642 8.66049 5.74629 8.98036C6.06616 9.30023 6.4737
              9.51806 6.91736 9.60631C7.36103 9.69456 7.82091 9.64927 8.23883
              9.47616C8.65676 9.30305 9.01397 9.00989 9.26529 8.63377C9.51661
              8.25765 9.65075 7.81544 9.65075 7.36308C9.64989 6.75675 9.40864
              6.17549 8.9799 5.74675C8.55116 5.31801 7.9699 5.07676 7.36357 5.0759Z"
        fill={fill}
      />
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/FACEBOOK */
export const FACEBOOK = ({ fill, ...rest }) => (
  <svg width="9" height="18" viewBox="0 0 9 18" fill="none" { ...rest } xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.96405 2.84763V0.0275365C8.96405 0.0275365 6.07916
        0 5.8864 0C4.59055 0 2.70833 1.48213
        2.70833 3.16512V6.18283H0V9.3852H2.67107V17.3223H5.83133V9.34795H8.61741L8.97215
        6.22009H5.86049V3.92319C5.85683 3.78329 5.88079 3.64404 5.93102 3.51342C5.98124
        3.38279 6.05674 3.26336 6.15318 3.16195C6.24963 3.06054 6.36513 2.97914 6.49307
        2.92243C6.62101 2.86572 6.75888 2.8348 6.89879 2.83143C7.31346 2.83143 8.96405
        2.84763 8.96405 2.84763Z"
      fill={fill}
    />
  </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/TWITTER */
export const TWITTER = ({ fill, ...rest }) => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" { ...rest } xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4406 1.0528C14.1229 0.708403 13.7349 0.436301
        13.3028 0.254927C12.8708 0.0735528 12.4048 -0.012858 11.9364
        0.00154554C10.0509 0.0630985 8.29831 1.21641 8.50078 4.17581C8.50078
        4.17581 5.65963 5.04079 1.23916 0.835749C1.23916 0.835749 -0.184659
        3.15532 2.33091 5.34045C2.33091 5.34045 1.62467 5.53483 0.681941
        5.01649C0.681941 5.01649 0.17656 6.97484 3.41295 8.45859C3.41295
        8.45859 2.32119 8.76798 1.82553 8.45859C1.82553 8.45859 2.11386
        10.5401 5.02142 11.0357C5.02142 11.0357 3.52633 12.5195 0 12.479C0
        12.479 7.35071 16.2402 12.4483 11.4067C16.088 7.95321 15.5826
        3.34646 15.5826 3.34646C16.3143 3.07131 16.9218 2.54088 17.2931
        1.85299C17.2931 1.85299 16.0767 2.23365 15.3769 2.17696C15.3769
        2.17696 16.7165 1.34113 16.8201 0.351425C16.812 0.341706
        15.6668 1.15161 14.4406 1.0528Z"
      fill={fill}
    />
  </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/YOUTUBE */
export const YOUTUBE = ({ fill, ...rest }) => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" { ...rest } xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0427 0H2.89623C2.12876 0.00213722 1.39333 0.307961
        0.850646 0.850646C0.307961 1.39333 0.00213722 2.12876 0
        2.89623V8.20922C0.00128029 8.9774 0.306674 9.71382 0.849406
        10.2575C1.39214 10.8011 2.12805 11.1077 2.89623 11.1103H13.0427C13.8101
        11.1077 14.5453 10.8018 15.0879 10.2592C15.6304 9.71659 15.9364 8.98142
        15.939 8.21408V2.89623C15.9368 2.12876 15.631 1.39333 15.0883 0.850646C14.5456
        0.307961 13.8102 0.00213722 13.0427 0ZM8.41493 7.12718L5.9431
        8.65629V2.53339L8.41817 4.06411L10.8916 5.59322L8.41493 7.12718Z"
      fill={fill}
    />
  </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/ARROW_UP */
export const ARROW_UP = ({ fill, ...rest }) => (
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" { ...rest } xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 6L6.00006 1L1 6"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeL
      inejoin="round"
    />
  </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/ARROW_DOWN */
export const ARROW_DOWN = ({ fill, ...rest }) => (
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" { ...rest } xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1L5.99994 6L11 1"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/ARROW_LEFT */
export const ARROW_LEFT = ({ fill, ...rest }) => (
  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" { ...rest } xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 1L1 5.99994L6 11"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/ARROW_RIGHT */
export const ARROW_RIGHT = ({ fill, ...rest }) => (
  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" { ...rest } xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 11L6 6.00006L1 1"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/BRAIN */
export const BRAIN = ({ fill, ...rest }) => (
  <svg width="60" height="68" viewBox="0 0 60 68" fill="none" { ...rest } xmlns="http://www.w3.org/2000/svg">
    <path
      d="M47.98 16.4421C47.6385 16.4408 47.3074 16.3246 47.04 16.1121C46.8858 15.9903 46.7573 15.839
3 46.6617 15.6677C46.5662 15.496 46.5055 15.3072 46.4832 15.112C46.4609 14.9168 46.4774 14.7192 46.5
318 14.5304C46.5861 14.3416 46.6773 14.1655 46.8 14.0121C47.5468 13.0283 47.903 11.8029 47.8 10.5721
C47.667 10.0949 47.4293 9.65336 47.104 9.27969C46.7788 8.90602 46.3743 8.60962 45.92 8.41209C45.4151
 8.0975 44.85 7.89188 44.261 7.80842C43.672 7.72496 43.072 7.7655 42.4996 7.92744C41.9272 8.08938 41
.3949 8.36914 40.9369 8.74879C40.4789 9.12845 40.1053 9.59962 39.84 10.1321C39.6431 10.4652 39.3265
10.7106 38.9549 10.8184C38.5833 10.9261 38.1846 10.8881 37.84 10.7121C37.503 10.5188 37.2548 10.2016
 37.1484 9.82798C37.0419 9.45438 37.0855 9.05397 37.27 8.71209C38.52 6.46209 42.27 2.96209 47.42 5.8
3209C48.2592 6.22887 48.9981 6.80991 49.5816 7.53191C50.1651 8.25392 50.5782 9.09828 50.79 10.0021C5
0.9212 11.0404 50.8437 12.0945 50.5621 13.1025C50.2805 14.1105 49.8004 15.0521 49.15 15.8721C49.0113
 16.05 48.8338 16.1938 48.631 16.2926C48.4282 16.3914 48.2056 16.4425 47.98 16.4421Z"
      fill={fill}
    />
    <path
      d="M48.7502 41.5023C48.3523 41.5355 47.9576 41.4092 47.6529 41.1514C47.3481 40.8935 47.1583 40
.5251 47.1252 40.1273C47.092 39.7295 47.2182 39.3348 47.4761 39.03C47.734 38.7253 48.1023 38.5355 48
.5002 38.5023L49.2902 38.4023C52.5702 38.0223 55.7102 37.1623 56.5502 33.4023C57.5502 28.8523 54.650
2 27.3223 54.6202 27.3123C54.294 27.1482 54.0389 26.8708 53.9027 26.5321C53.7665 26.1933 53.7584 25.
8165 53.8802 25.4723C54.1202 24.8023 56.0302 18.8623 50.7402 16.9223C50.204 16.7073 49.6285 16.6078
49.0513 16.6302C48.4741 16.6526 47.9081 16.7964 47.3902 17.0523C44.3902 18.5023 42.1602 23.3323 41.5
802 25.0523C41.4542 25.4302 41.1832 25.7427 40.8269 25.9208C40.4706 26.099 40.0581 26.1283 39.6802 2
6.0023C39.3022 25.8763 38.9898 25.6054 38.8117 25.2491C38.6335 24.8927 38.6042 24.4802 38.7302 24.10
23C38.8302 23.7923 41.2902 16.6323 46.0802 14.3323C46.9598 13.8995 47.9196 13.6539 48.899 13.6108C49
.8783 13.5678 50.856 13.7283 51.7702 14.0823C57.6302 16.2323 57.8802 21.8623 57.0402 25.3423C58.6802
 26.7323 60.4602 29.6123 59.4702 34.0323C58.0602 40.3723 51.9402 41.0323 49.6302 41.3423L49.0002 41.
4223L48.7502 41.5023Z"
      fill={fill}
    />
    <path
      d="M37.3802 67.1722C34.9502 67.1722 32.7102 65.7122 30.7202 62.8222C28.7302 59.9322 28.1602 57
.2622 28.8802 54.6922C30.0602 50.4422 34.5102 48.4022 34.6902 48.3122C34.8704 48.1912 35.0748 48.110
8 35.2892 48.0765C35.5035 48.0422 35.7228 48.0548 35.9318 48.1136C36.1408 48.1723 36.3346 48.2756 36
.4998 48.4166C36.6649 48.5575 36.7975 48.7326 36.8884 48.9297C36.9793 49.1269 37.0263 49.3414 37.026
2 49.5585C37.0261 49.7756 36.9788 49.9901 36.8877 50.1872C36.7966 50.3842 36.6638 50.5592 36.4985 50
.6999C36.3331 50.8406 36.1392 50.9438 35.9302 51.0022C35.9302 51.0022 32.5602 52.5822 31.7602 55.472
2C31.3002 57.1422 31.7602 59.0322 33.2002 61.0822C34.6402 63.1322 36.1002 64.2022 37.5702 64.0822C40
.3402 63.9122 43.0402 59.8422 43.7702 58.4322C43.8936 58.1775 44.0894 57.9648 44.3331 57.8208C44.576
8 57.6767 44.8575 57.6076 45.1402 57.6222C46.4102 57.6222 49.9502 57.3022 51.0602 55.4622C51.9417 53
.7253 52.2162 51.7433 51.8402 49.8322C51.7641 49.4855 51.8126 49.123 51.9772 48.8084C52.1417 48.4939
 52.4119 48.2474 52.7402 48.1122C53.7402 47.7022 56.4202 46.1622 56.6402 44.0522C56.7178 42.1559 56.
1463 40.2899 55.0202 38.7622C54.8748 38.4501 54.8412 38.0975 54.9251 37.7636C55.0089 37.4296 55.2051
 37.1347 55.4807 36.9283C55.7563 36.7219 56.0945 36.6166 56.4386 36.6301C56.7826 36.6435 57.1116 36.
7749 57.3702 37.0022C58.9825 39.1427 59.7801 41.7872 59.6202 44.4622C59.2902 47.6122 56.5202 49.5922
 54.9402 50.4622C55.2082 52.7613 54.7501 55.0866 53.6302 57.1122C51.8702 60.0322 47.8502 60.6022 45.
9902 60.7022C44.8702 62.5822 41.8602 66.9222 37.7602 67.1822L37.3802 67.1722Z"
      fill={fill}
    />
    <path
      d="M30.39 56.0922C29.9939 56.0922 29.6139 55.9356 29.3329 55.6564C29.0519 55.3773 28.8927 54.9
983 28.89 54.6022L28.77 4.54222C28.7056 3.75632 28.858 2.96781 29.2106 2.26252C29.5633 1.55724 30.10
27 0.962208 30.77 0.542221C33.3 -0.887779 37.36 0.492221 43.53 4.90222C43.6902 5.01713 43.8263 5.162
47 43.9303 5.32994C44.0343 5.49741 44.1044 5.68373 44.1364 5.87827C44.1685 6.07281 44.1619 6.27175 4
4.117 6.46374C44.0722 6.65573 43.9899 6.83701 43.875 6.99722C43.7601 7.15743 43.6148 7.29344 43.4473
 7.39749C43.2798 7.50153 43.0935 7.57157 42.899 7.60361C42.7044 7.63565 42.5055 7.62905 42.3135 7.58
42C42.1215 7.53935 41.9402 7.45713 41.78 7.34222C34.69 2.27222 32.6 3.00222 32.24 3.15222C31.72 3.44
222 31.76 4.37222 31.76 4.38222V4.48222L31.88 54.5922C31.88 54.9883 31.7234 55.3683 31.4442 55.6494C
31.1651 55.9304 30.7861 56.0896 30.39 56.0922Z"
      fill={fill}
    />
    <path
      d="M30.4701 26.4423C30.3673 26.4563 30.263 26.4563 30.1601 26.4423C29.9673 26.403 29.7842 26.3
26 29.6214 26.2155C29.4585 26.1051 29.3192 25.9636 29.2113 25.799C29.1035 25.6344 29.0293 25.4502 28
.993 25.2568C28.9568 25.0634 28.9592 24.8647 29.0001 24.6723C29.7001 21.3123 33.7601 18.3123 34.2201
 17.9823C34.3786 17.853 34.562 17.7574 34.7588 17.7015C34.9556 17.6457 35.1617 17.6307 35.3646 17.65
74C35.5674 17.6842 35.7626 17.7521 35.9382 17.8571C36.1138 17.9621 36.266 18.1019 36.3856 18.2679C36
.5052 18.434 36.5895 18.6227 36.6334 18.8225C36.6773 19.0223 36.6799 19.229 36.641 19.4298C36.6021 1
9.6307 36.5225 19.8215 36.4071 19.9904C36.2918 20.1594 36.143 20.3029 35.9701 20.4123C34.6001 21.412
3 32.2901 23.6023 31.9701 25.2823C31.8902 25.6179 31.6978 25.916 31.4249 26.1271C31.152 26.3382 30.8
151 26.4494 30.4701 26.4423Z"
      fill={fill}
    />
    <path
      d="M38.47 32.872C38.2137 32.8922 37.9563 32.8922 37.7 32.872C34.25 32.422 32.7 29.112 32.64 28
.962C32.4906 28.6024 32.4868 28.1988 32.6295 27.8365C32.7723 27.4743 33.0503 27.1817 33.4048 27.0206
C33.7593 26.8596 34.1626 26.8427 34.5293 26.9736C34.8961 27.1045 35.1975 27.3729 35.37 27.722C35.635
1 28.2668 36.0188 28.7453 36.4929 29.1225C36.967 29.4998 37.5196 29.7661 38.11 29.902C39.45 30.072 4
1.8 29.362 45.33 25.052C45.4526 24.8931 45.6058 24.7605 45.7805 24.6619C45.9553 24.5633 46.148 24.50
07 46.3474 24.478C46.5467 24.4552 46.7486 24.4726 46.9411 24.5293C47.1336 24.5859 47.3128 24.6806 47
.468 24.8078C47.6232 24.9349 47.7514 25.0919 47.8449 25.2694C47.9384 25.447 47.9953 25.6415 48.0122
25.8414C48.0292 26.0413 48.0059 26.2426 47.9437 26.4334C47.8814 26.6242 47.7816 26.8005 47.65 26.952
C44.4 30.872 41.31 32.872 38.47 32.872Z"
      fill={fill}
    />
    <path
      d="M46.0701 38.4524C45.6701 38.4524 42.0701 38.3124 40.0701 35.9324C39.5642 35.2826 39.2114 34
.527 39.0383 33.7218C38.8651 32.9167 38.876 32.0828 39.0701 31.2824C39.1548 30.9051 39.3819 30.5751
39.704 30.3612C40.0261 30.1473 40.4184 30.0661 40.799 30.1345C41.1796 30.2029 41.519 30.4156 41.7466
 30.7282C41.9741 31.0408 42.0721 31.4293 42.0201 31.8124C41.9113 32.1804 41.8862 32.5682 41.9468 32.
9471C42.0073 33.3261 42.152 33.6867 42.3701 34.0024C43.2901 35.1324 45.3701 35.4224 46.1001 35.4324C
46.498 35.4324 46.8795 35.5905 47.1608 35.8718C47.4421 36.1531 47.6001 36.5346 47.6001 36.9324C47.60
01 37.3303 47.4421 37.7118 47.1608 37.9931C46.8795 38.2744 46.498 38.4324 46.1001 38.4324L46.0701 38
.4524Z"
      fill={fill}
    />
    <path
      d="M43.15 49.8222C42.855 49.8212 42.5666 49.7343 42.32 49.5722C41.99 49.3506 41.7615 49.007 41
.6846 48.617C41.6077 48.227 41.6888 47.8225 41.91 47.4922C41.91 47.4922 42.57 46.2322 41.19 43.8122C
39.9 41.5722 36.85 42.5222 36.72 42.5622C36.3421 42.6868 35.9301 42.6563 35.5747 42.4772C35.2194 42.
2981 34.9497 41.9851 34.825 41.6072C34.7004 41.2293 34.731 40.8173 34.9101 40.4619C35.0892 40.1065 3
5.4021 39.8368 35.78 39.7122C37.67 39.0822 41.78 38.8222 43.78 42.3222C46.1 46.3822 44.57 48.8822 44
.39 49.1622C44.2535 49.3657 44.069 49.5324 43.8527 49.6475C43.6364 49.7626 43.395 49.8226 43.15 49.8
222Z"
      fill={fill}
    />
    <path
      d="M39.3101 57.7822C38.989 57.7846 38.6758 57.6824 38.4179 57.4912C38.1599 57.3 37.9712 57.030
1 37.8801 56.7222C37.8801 56.5522 36.6901 52.6122 39.0601 49.6322C40.8401 47.3822 43.9901 46.3822 48
.4201 46.6322C48.8179 46.6561 49.1899 46.837 49.4544 47.1352C49.7188 47.4334 49.8539 47.8244 49.8301
 48.2222C49.8062 48.6201 49.6253 48.9921 49.3271 49.2565C49.0289 49.521 48.6379 49.6561 48.2401 49.6
322C44.9001 49.4322 42.5501 50.0622 41.4201 51.4622C40.0201 53.2122 40.7501 55.8222 40.7601 55.8522C
40.8692 56.2301 40.8256 56.6358 40.6385 56.9818C40.4515 57.3278 40.136 57.5866 39.7601 57.7022C39.61
47 57.7504 39.4631 57.7774 39.3101 57.7822Z"
      fill={fill}
    />
    <path
      d="M12.0201 16.4421C11.7943 16.444 11.5711 16.3935 11.3681 16.2946C11.1651 16.1957 10.9878 16.
0511 10.8501 15.8721C10.2065 15.049 9.73269 14.1062 9.45631 13.0986C9.17993 12.0909 9.10645 11.0383
9.24015 10.0021C9.44858 9.09183 9.85992 8.24045 10.4435 7.51147C11.027 6.78248 11.7677 6.19471 12.61
01 5.79205C17.7701 2.92205 21.5101 6.42205 22.7701 8.67205C22.9651 9.01948 23.014 9.43012 22.9062 9.
81364C22.7983 10.1971 22.5426 10.5221 22.1951 10.7171C21.8477 10.912 21.4371 10.9609 21.0536 10.8531
C20.67 10.7453 20.3451 10.4895 20.1501 10.1421C19.8881 9.60654 19.5164 9.13214 19.0591 8.74967C18.60
18 8.36721 18.0691 8.08524 17.4957 7.92208C16.9223 7.75892 16.321 7.71823 15.7308 7.80264C15.1406 7.
88706 14.5748 8.09469 14.0701 8.41205C13.6144 8.61 13.2088 8.90775 12.8834 9.28332C12.5581 9.65889 1
2.3211 10.1027 12.1901 10.5821C12.0986 11.8134 12.4734 13.0342 13.2401 14.0021C13.3629 14.1555 13.45
4 14.3316 13.5084 14.5204C13.5628 14.7091 13.5793 14.9068 13.557 15.102C13.5347 15.2971 13.474 15.48
6 13.3784 15.6576C13.2829 15.8292 13.1543 15.9803 13.0001 16.1021C12.7256 16.3307 12.3773 16.4516 12
.0201 16.4421Z"
      fill={fill}
    />
    <path
      d="M11.2401 41.5024H10.9801L10.3501 41.4224C8.05009 41.1524 1.92009 40.4224 0.510093 34.1124C-
0.489907 29.6924 1.31009 26.8124 2.95009 25.4224C2.12009 21.9224 2.37009 16.2924 8.24009 14.1424C9.1
5308 13.7829 10.1314 13.6194 11.1117 13.6625C12.0919 13.7056 13.0522 13.9542 13.9301 14.3924C18.7301
 16.6924 21.1801 23.8524 21.2801 24.1624C21.3564 24.3521 21.3931 24.5554 21.3879 24.7599C21.3828 24.
9643 21.3359 25.1655 21.2501 25.3512C21.1643 25.5368 21.0415 25.703 20.8892 25.8394C20.7368 25.9759
20.5582 26.0797 20.3643 26.1446C20.1703 26.2095 19.9652 26.234 19.7614 26.2167C19.5576 26.1994 19.35
96 26.1406 19.1793 26.0439C18.9991 25.9472 18.8406 25.8147 18.7135 25.6545C18.5863 25.4943 18.4933 2
5.3098 18.4401 25.1124C17.8501 23.3724 15.6601 18.5424 12.6301 17.1124C12.1062 16.8513 11.5321 16.70
64 10.9471 16.6874C10.3621 16.6684 9.77983 16.7759 9.24009 17.0024C3.82009 19.0024 6.00009 25.2824 6
.09009 25.5524C6.2148 25.8953 6.20936 26.272 6.07481 26.6111C5.94026 26.9503 5.68595 27.2282 5.36009
 27.3924C5.07009 27.5424 2.45009 29.1024 3.43009 33.4824C4.26009 37.2224 7.43009 38.0824 10.6901 38.
4824L11.4701 38.5824C11.8679 38.6142 12.2368 38.8028 12.4956 39.1066C12.7544 39.4104 12.8819 39.8045
 12.8501 40.2024C12.8183 40.6002 12.6297 40.9691 12.3259 41.2279C12.0221 41.4867 11.6279 41.6142 11.
2301 41.5824L11.2401 41.5024Z"
      fill={fill}
    />
    <path
      d="M22.6202 67.1722H22.2402C18.1402 66.9122 15.1202 62.5722 14.0002 60.6922C12.1402 60.5922 8.
13015 60.0222 6.37015 57.1022C5.25245 55.0756 4.79123 52.752 5.05015 50.4522C3.48015 49.5822 0.70015
5 47.6022 0.370155 44.4522C0.212626 41.7806 1.01012 39.14 2.62015 37.0022C2.86813 36.6905 3.22975 36
.4902 3.62545 36.4452C4.02115 36.4002 4.41853 36.5142 4.73015 36.7622C5.04178 37.0101 5.24214 37.371
8 5.28715 37.7675C5.33216 38.1632 5.21813 38.5605 4.97015 38.8722C3.84608 40.3966 3.2779 42.2599 3.3
6015 44.1522C3.58015 46.2422 6.24015 47.7822 7.24015 48.1922C7.56658 48.3291 7.83453 48.5763 7.9972
48.8907C8.15988 49.2051 8.20693 49.5666 8.13015 49.9122C7.76562 51.8246 8.04336 53.8039 8.92015 55.5
422C10.0302 57.3822 13.5502 57.7122 14.8402 57.7022C15.1198 57.697 15.3954 57.77 15.6357 57.9132C15.
876 58.0563 16.0715 58.2638 16.2002 58.5122C16.9902 60.0222 19.6402 63.9922 22.4102 64.1622C23.8502
64.2622 25.3302 63.2322 26.7802 61.1622C28.2302 59.0922 28.6702 57.2222 28.2102 55.5522C27.4202 52.6
622 24.0802 51.1022 24.0402 51.0822C23.691 50.9097 23.4227 50.6082 23.2918 50.2415C23.1609 49.8748 2
3.1778 49.4715 23.3388 49.117C23.4998 48.7625 23.7924 48.4844 24.1547 48.3417C24.517 48.199 24.9206
48.2027 25.2802 48.3522C25.4702 48.4422 29.9102 50.4822 31.1002 54.7322C31.8402 57.2622 31.2402 60.0
022 29.2402 62.8222C27.2402 65.6422 25.0502 67.1722 22.6202 67.1722Z"
      fill={fill}
    />
    <path
      d="M29.6001 56.0924C29.2023 56.0924 28.8207 55.9344 28.5394 55.6531C28.2581 55.3718 28.1001 54
.9903 28.1001 54.5924L28.2401 4.48244C28.2401 4.48244 28.2401 4.40244 28.2401 4.36244C28.2401 4.3624
4 28.2401 3.44244 27.7601 3.15244C27.4001 3.00244 25.3101 2.26244 18.2401 7.34244C18.0799 7.45866 17
.8983 7.54218 17.7059 7.58825C17.5134 7.63431 17.3137 7.642 17.1182 7.6109C16.9227 7.57979 16.7353 7
.51049 16.5666 7.40694C16.3979 7.3034 16.2513 7.16765 16.1351 7.00744C16.0189 6.84723 15.9353 6.6656
9 15.8893 6.4732C15.8432 6.2807 15.8355 6.08102 15.8666 5.88556C15.8977 5.69009 15.967 5.50266 16.07
06 5.33398C16.1741 5.1653 16.3099 5.01866 16.4701 4.90244C22.6401 0.492438 26.6901 -0.887562 29.2401
 0.552438C29.9083 0.971606 30.4483 1.56648 30.801 2.27198C31.1538 2.97747 31.3057 3.7664 31.2401 4.5
5244L31.1001 54.6024C31.0975 54.9985 30.9383 55.3775 30.6572 55.6566C30.3762 55.9358 29.9962 56.0924
 29.6001 56.0924Z"
      fill={fill}
    />
    <path
      d="M29.5202 26.4424C29.1756 26.4421 28.8418 26.3223 28.5756 26.1035C28.3094 25.8847 28.1272 25
.5804 28.0602 25.2424C27.7102 23.5624 25.4001 21.3624 24.0601 20.3724C23.9006 20.2569 23.7654 20.111
 23.6622 19.9432C23.559 19.7754 23.4899 19.5889 23.4588 19.3943C23.4277 19.1998 23.4352 19.001 23.48
09 18.8094C23.5266 18.6177 23.6096 18.437 23.7252 18.2774C23.8407 18.1179 23.9866 17.9826 24.1544 17
.8795C24.3222 17.7763 24.5087 17.7072 24.7033 17.676C24.8978 17.6449 25.0966 17.6525 25.2882 17.6982
C25.4798 17.7439 25.6606 17.8269 25.8202 17.9424C26.2802 18.2724 30.3302 21.2724 31.0302 24.6324C31.
0711 24.8249 31.0735 25.0235 31.0372 25.2169C31.001 25.4103 30.9268 25.5946 30.819 25.7591C30.7111 2
5.9237 30.5718 26.0653 30.4089 26.1757C30.246 26.2861 30.0629 26.3632 29.8701 26.4024C29.7564 26.434
5 29.6382 26.448 29.5202 26.4424Z"
      fill={fill}
    />
    <path
      d="M21.5201 32.872C18.6801 32.872 15.6001 30.872 12.3401 26.872C12.1441 26.5609 12.0691 26.188
6 12.1295 25.8259C12.1899 25.4631 12.3815 25.1352 12.6678 24.9044C12.9541 24.6736 13.3152 24.5561 13
.6825 24.5741C14.0498 24.5921 14.3977 24.7444 14.6601 25.002C18.2001 29.312 20.5501 30.002 21.8901 2
9.852C22.4804 29.7188 23.0328 29.4534 23.5057 29.0758C23.9786 28.6982 24.3596 28.2182 24.6201 27.672
C24.7926 27.3229 25.0941 27.0545 25.4608 26.9237C25.8275 26.7928 26.2308 26.8096 26.5853 26.9707C26.
9398 27.1317 27.2178 27.4243 27.3606 27.7866C27.5033 28.1489 27.4996 28.5524 27.3501 28.912C26.8789
29.9176 26.1751 30.7967 25.297 31.4765C24.4189 32.1564 23.3917 32.6176 22.3001 32.822C22.0418 32.858
6 21.781 32.8753 21.5201 32.872Z"
      fill={fill}
    />
    <path
      d="M13.9302 38.4522C13.5324 38.4522 13.1508 38.2941 12.8695 38.0128C12.5882 37.7315 12.4302 37
.35 12.4302 36.9522C12.4302 36.5543 12.5882 36.1728 12.8695 35.8915C13.1508 35.6102 13.5324 35.4522
13.9302 35.4522C14.6602 35.4522 16.7502 35.1422 17.6602 34.0222C17.8918 33.7091 18.0484 33.347 18.11
77 32.9638C18.1871 32.5806 18.1674 32.1865 18.0602 31.8122C17.9899 31.4143 18.0805 31.0049 18.3121 3
0.6739C18.5437 30.3429 18.8974 30.1174 19.2952 30.0472C19.693 29.9769 20.1025 30.0675 20.4335 30.299
1C20.7645 30.5307 20.9899 30.8843 21.0602 31.2822C21.2499 32.0828 21.2586 32.9157 21.0856 33.7202C20
.9126 34.5246 20.5623 35.2803 20.0602 35.9322C18.0602 38.3122 14.4702 38.4522 14.0602 38.4522H13.930
2Z"
      fill={fill}
    />
    <path
      d="M16.87 49.8422C16.6174 49.8417 16.3687 49.7795 16.1456 49.661C15.9225 49.5425 15.7318 49.37
13 15.59 49.1622C15.41 48.8822 13.88 46.3822 16.21 42.3222C18.21 38.8222 22.32 39.0822 24.21 39.7122
C24.3971 39.7759 24.5699 39.8758 24.7184 40.0063C24.8669 40.1367 24.9883 40.2951 25.0756 40.4725C25.
1629 40.6499 25.2144 40.8427 25.2271 41.0399C25.2399 41.2372 25.2137 41.4351 25.15 41.6222C25.0863 4
1.8093 24.9864 41.9821 24.8559 42.1306C24.7255 42.2791 24.5671 42.4005 24.3897 42.4878C24.2123 42.57
5 24.0195 42.6265 23.8223 42.6393C23.625 42.6521 23.4271 42.6259 23.24 42.5622C23.09 42.5122 20.06 4
1.5622 18.78 43.8122C17.39 46.2322 18.06 47.4822 18.09 47.5422C18.1978 47.7046 18.2723 47.8868 18.30
91 48.0782C18.3458 48.2696 18.3442 48.4664 18.3041 48.6572C18.2641 48.848 18.1866 49.0289 18.076 49.
1894C17.9654 49.3499 17.824 49.4868 17.66 49.5922C17.4269 49.7511 17.1522 49.8381 16.87 49.8422Z"
      fi
      ll="#0E0F11"
    />
    <path
      d="M20.6802 57.7924C20.5339 57.7936 20.3885 57.7699 20.2502 57.7224C19.8727 57.609 19.5555 57.
3506 19.3681 57.0039C19.1807 56.6572 19.1383 56.2503 19.2502 55.8724C19.2502 55.8124 19.9802 53.2224
 18.5802 51.4824C17.4602 50.0824 15.1102 49.4824 11.7702 49.6524C11.5732 49.6642 11.3758 49.6371 11.
1893 49.5726C11.0028 49.5082 10.8308 49.4076 10.6832 49.2767C10.5355 49.1458 10.4151 48.987 10.3288
48.8096C10.2425 48.6321 10.192 48.4394 10.1802 48.2424C10.1684 48.0454 10.1955 47.848 10.2599 47.661
5C10.3244 47.475 10.425 47.303 10.5559 47.1554C10.6868 47.0077 10.8455 46.8873 11.023 46.801C11.2005
 46.7147 11.3932 46.6642 11.5902 46.6524C16.0102 46.3724 19.1602 47.3724 20.9402 49.6524C23.3102 52.
6524 22.1802 56.5724 22.1302 56.7424C22.0321 57.0492 21.8384 57.3165 21.5776 57.5054C21.3167 57.6943
 21.0023 57.7949 20.6802 57.7924Z"
      fill={fill}
    />
  </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/BATTERY */
export const BATTERY = ({ fill, ...rest }) => (
  <svg width="66" height="38" viewBox="0 0 66 38" fill="none" { ...rest } xmlns="http://www.w3.org/2000/svg">
    <path
      d="M51.31 37.08H7.17C5.26921 37.0774 3.44703 36.3211 2.10297 34.977C0.758904 33.633 0.0026
455 31.8108 0 29.91V7.17
C0.0026455 5.26921 0.758904 3.44703 2.10297 2.10297C3.44703 0.758904 5.26921 0.0026455 7.17 0H51.31C
53.2108 0.0026455 55
.033 0.758904 56.377 2.10297C57.7211 3.44703 58.4774 5.26921 58.48 7.17V29.91C58.4774 31.8108 57.721
1 33.633 56.377 34.9
77C55.033 36.3211 53.2108 37.0774 51.31 37.08ZM7.17 3C6.06405 3 5.00339 3.43934 4.22136 4.22136C3.43
934 5.00339 3 6.0640
5 3 7.17V29.91C3 31.016 3.43934 32.0766 4.22136 32.8586C5.00339 33.6407 6.06405 34.08 7.17 34.08H51.
31C51.8576 34.08 52.
3999 33.9721 52.9058 33.7626C53.4117 33.553 53.8714 33.2459 54.2586 32.8586C54.6459 32.4714 54.953 3
2.0117 55.1626 31.50
58C55.3721 30.9999 55.48 30.4576 55.48 29.91V7.17C55.48 6.06405 55.0407 5.00339 54.2586 4.22136C53.4
766 3.43934 52.416 3
 51.31 3H7.17Z"
      fill={fill}
    />
    <path
      d="M18.7201 30.7699H11.3701C8.75012 30.7699 6.62012 28.9199 6.62012 26.6399V10.2999C6.6201
2 8.01992 8.75012 6.1699
2 11.3701 6.16992H15.0501C15.3842 6.17023 15.7087 6.28132 15.9729 6.4858C16.2371 6.69028 16.426 6.97
66 16.5101 7.29992L2
1.8401 28.5299C21.925 28.871 21.8874 29.2311 21.7338 29.5473C21.5803 29.8634 21.3206 30.1157 21.0001
 30.2599C20.2864 30.
5943 19.5082 30.7683 18.7201 30.7699ZM11.3701 9.16992C10.3701 9.16992 9.62012 9.76992 9.62012 10.299
9V26.6399C9.62012 27
.1699 10.3401 27.7699 11.3701 27.7699H18.5601L13.8801 9.16992H11.3701Z"
      fill={fill}
    />
    <path
      d="M63.58 27.12H57.2988C56.8453 27.12 56.4104 26.9619 56.0897 26.6806C55.769 26.3993 55.58
89 26.0178 55.5889 25.62
V11.46C55.5889 11.0621 55.769 10.6806 56.0897 10.3993C56.4104 10.118 56.8453 9.95996 57.2988 9.95996
H63.58C64.0335 9.959
96 64.4685 10.118 64.7891 10.3993C65.1098 10.6806 65.29 11.0621 65.29 11.46V25.62C65.29 26.0178 65.1
098 26.3993 64.7891
26.6806C64.4685 26.9619 64.0335 27.12 63.58 27.12ZM59.0202 24.12H61.8815V13H59.0088L59.0202 24.12Z"
      fill={fill}
    />
    <path
      d="M37.4601 30.7001H26.8401C26.5015 30.6999 26.173 30.5851 25.9079 30.3745C25.6428 30.1639
 25.4568 29.8698 25.3801
 29.5401L20.2801 7.9401C20.2277 7.72101 20.2257 7.49291 20.2742 7.27294C20.3227 7.05296 20.4204 6.84
685 20.5601 6.6701C2
0.7013 6.49255 20.8807 6.3491 21.085 6.25042C21.2893 6.15174 21.5132 6.10036 21.7401 6.1001H34.0001C
34.3368 6.10154 34.6
634 6.2155 34.928 6.42387C35.1925 6.63224 35.3798 6.92306 35.4601 7.2501L40.4601 28.4801C40.5367 28.
8096 40.5005 29.1552
 40.3573 29.4617C40.214 29.7681 39.972 30.0176 39.6701 30.1701C38.9839 30.5141 38.2277 30.6954 37.46
01 30.7001ZM28.0001
27.7001H37.1801L32.8301 9.1001H23.6401L28.0001 27.7001Z"
      fill={fill}
    />
  </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/SHIELD */
export const SHIELD = ({ fill, ...rest }) => (
  <svg width="53" height="67" viewBox="0 0 53 67" fill="none" { ...rest } xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.4111 66.12C26.0413 66.1169 25.6854 65.9781 25.4111 65.73C24.8811 65.25 12.5811 54 6
.48113 40.6C3.71113 34.6
 -1.33887 12.75 0.33113 10.4C0.491604 10.1672 0.711969 9.98206 0.96895 9.86413C1.22593 9.7462 1.5099
9 9.69986 1.79113 9.
73C17.3911 11.18 25.1211 0.73 25.1911 0.62C25.3313 0.427888 25.5149 0.271597 25.7269 0.163857C25.938
9 0.0561174 26.1733
-2.58099e-05 26.4111 8.90102e-09C26.6488 0.000768367 26.883 0.0572587 27.0949 0.164935C27.3067 0.272
61 27.4904 0.428471
27.6311 0.62C27.9411 1.05 35.5011 11.17 51.0311 9.73C51.3123 9.69986 51.5963 9.7462 51.8533 9.86413C
52.1103 9.98206 52.3
307 10.1672 52.4911 10.4C54.1611 12.75 49.1111 34.57 46.3411 40.6C40.2311 54 27.9411 65.25 27.4211 6
5.73C27.1442 65.9804
 26.7844 66.1193 26.4111 66.12ZM3.00113 12.82C3.28113 17.3 6.25113 32.82 9.25113 39.36C14.0611 50 23
.3411 59.56 26.4111
62.55C29.4711 59.55 38.7511 49.99 43.6211 39.36C46.6211 32.79 49.6211 17.3 49.8711 12.82C45.5385 13.
1486 41.1869 12.5118
 37.1301 10.9555C33.0732 9.39919 29.4123 6.96212 26.4111 3.82C23.4108 6.94912 19.7573 9.37803 15.711
 10.9337C11.6646 12.
4893 7.3249 13.1334 3.00113 12.82Z"
      fill={fill}
    />
    <path
      d="M30.2212 44.4801H22.6012C22.2068 44.4802 21.8283 44.3249 21.5476 44.0479C21.2668 43.770
9 21.1065 43.3945 21.101
2 43.0001V36.8701H15.0012C14.6034 36.8701 14.2219 36.7121 13.9406 36.4308C13.6593 36.1495 13.5012 35
.768 13.5012 35.3701
V27.7501C13.5012 27.3523 13.6593 26.9708 13.9406 26.6895C14.2219 26.4082 14.6034 26.2501 15.0012 26.
2501H21.1012V20.1401
C21.1012 19.7423 21.2593 19.3608 21.5406 19.0795C21.8219 18.7982 22.2034 18.6401 22.6012 18.6401H30.
2212C30.619 18.6401
31.0006 18.7982 31.2819 19.0795C31.5632 19.3608 31.7212 19.7423 31.7212 20.1401V26.2501H37.8312C38.2
29 26.2501 38.6106 2
6.4082 38.8919 26.6895C39.1732 26.9708 39.3312 27.3523 39.3312 27.7501V35.3701C39.3312 35.768 39.173
2 36.1495 38.8919 36
.4308C38.6106 36.7121 38.229 36.8701 37.8312 36.8701H31.7212V43.0001C31.716 43.3945 31.5556 43.7709
31.2749 44.0479C30.9
941 44.3249 30.6156 44.4802 30.2212 44.4801ZM24.1012 41.4801H28.7212V35.3701C28.7212 34.9723 28.8793
 34.5908 29.1606 34.
3095C29.4419 34.0282 29.8234 33.8701 30.2212 33.8701H36.3312V29.2501H30.2212C29.8234 29.2501 29.4419
 29.0921 29.1606 28.
8108C28.8793 28.5295 28.7212 28.148 28.7212 27.7501V21.6401H24.1012V27.7501C24.1012 28.148 23.9432 2
8.5295 23.6619 28.81
08C23.3806 29.0921 22.999 29.2501 22.6012 29.2501H16.4912V33.8701H22.6012C22.999 33.8701 23.3806 34.
0282 23.6619 34.3095
C23.9432 34.5908 24.1012 34.9723 24.1012 35.3701V41.4801Z"
      fill={fill}
    />
  </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/HEART */
export const HEART = ({ fill, ...rest }) => (
  <svg width="65" height="59" viewBox="0 0 65 59" fill="none" { ...rest } xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.5 58.0037C32.2123 58.0047 31.9307 57.9213 31.69 57.7637C24.247 52.8123 17.3957 47.0246
11.27 40.5137C11.1113 40.3741 10.9839 40.2026 10.8962 40.0103C10.8084 39.818 10.7623 39.6094 10.7608
 39.398C10.7593 39.1867 10.8025 38.9774 10.8876 38.784C10.9726 38.5905 11.0976 38.4171 11.2543 38.27
53C11.411 38.1335 11.5959 38.0264 11.7969 37.9611C11.9979 37.8957 12.2104 37.8735 12.4205 37.896C12.
6307 37.9186 12.8337 37.9852 13.0163 38.0917C13.1989 38.1981 13.3569 38.3419 13.48 38.5137C19.2131 4
4.5834 25.5832 50.0181 32.48 54.7237C37.33 51.4137 62 33.8437 62 18.0037C61.9493 14.7063 60.8297 11.
5143 58.8094 8.90774C56.7891 6.30116 53.9773 4.42076 50.7967 3.54923C47.6161 2.67769 44.2385 2.86209
 41.1717 4.07472C38.1049 5.28735 35.5145 7.46269 33.79 10.2737C33.6572 10.4976 33.4684 10.683 33.242
2 10.8118C33.0161 10.9406 32.7603 11.0083 32.5 11.0083C32.2397 11.0083 31.9839 10.9406 31.7578 10.81
18C31.5316 10.683 31.3428 10.4976 31.21 10.2737C29.8459 8.00363 27.9051 6.13516 25.5849 4.85823C23.2
646 3.58131 20.6477 2.94146 18 3.00373C14.0218 3.00373 10.2064 4.58408 7.3934 7.39713C4.58035 10.210
2 3 14.0255 3 18.0037C3 22.3837 4.92 27.3037 8.71 32.6337C8.94074 32.9586 9.03296 33.3619 8.96639 33
.7547C8.89981 34.1476 8.67989 34.498 8.355 34.7287C8.03011 34.9595 7.62687 35.0517 7.23398 34.9851C6
.84109 34.9185 6.49074 34.6986 6.26 34.3737C2.11 28.5237 0 23.0037 0 18.0037C0 13.2298 1.89642 8.651
46 5.27208 5.27581C8.64773 1.90015 13.2261 0.0037286 18 0.0037286C20.7819 -0.0529 23.5391 0.536189 2
6.055 1.72473C28.5709 2.91327 30.777 4.6689 32.5 6.85373C34.8489 3.9171 38.0477 1.77778 41.6588 0.72
8369C45.2699 -0.321046 49.117 -0.229321 52.674 0.991C56.231 2.21132 59.3242 4.50065 61.5305 7.54589C
63.7368 10.5911 64.9486 14.2436 65 18.0037C65 37.1037 34.61 56.9337 33.31 57.7637C33.0693 57.9213 32
.7877 58.0047 32.5 58.0037Z"
      fill={fill}
    />
    <path
      d="M24.5 40.0039C24.2214 40.0047 23.9481 39.9273 23.7113 39.7806C23.4744 39.6339 23.2834 39.42
37 23.16 39.1739L19.38 31.6139L17.79 34.2739C17.6559 34.4962 17.4667 34.6802 17.2407 34.8081C17.0148
 34.936 16.7596 35.0034 16.5 35.0039H1.5C1.10218 35.0039 0.720644 34.8459 0.43934 34.5646C0.158035 3
4.2833 0 33.9017 0 33.5039C0 33.1061 0.158035 32.7246 0.43934 32.4432C0.720644 32.1619 1.10218 32.00
39 1.5 32.0039H15.65L18.21 27.7339C18.3481 27.5029 18.5457 27.3132 18.7823 27.1848C19.0189 27.0565 1
9.2857 26.9942 19.5546 27.0044C19.8236 27.0146 20.0849 27.097 20.311 27.243C20.5372 27.3889 20.7199
27.593 20.84 27.8339L24.31 34.7739L31.1 17.0039C31.2026 16.7137 31.3919 16.4621 31.6424 16.2832C31.8
928 16.1043 32.1922 16.0068 32.5 16.0039C32.8085 16.0039 33.1092 16.1003 33.3602 16.2796C33.6112 16.
4589 33.8 16.7121 33.9 17.0039L39.54 32.0039H46.54C46.9378 32.0039 47.3194 32.1619 47.6007 32.4432C4
7.882 32.7246 48.04 33.1061 48.04 33.5039C48.04 33.9017 47.882 34.2833 47.6007 34.5646C47.3194 34.84
59 46.9378 35.0039 46.54 35.0039H38.54C38.2322 35.001 37.9328 34.9035 37.6824 34.7246C37.4319 34.545
7 37.2426 34.2941 37.14 34.0039L32.49 21.7439L25.9 39.0039C25.8036 39.2825 25.6267 39.5262 25.3917 3
9.7042C25.1568 39.8822 24.8743 39.9865 24.58 40.0039H24.5Z"
      fill={fill}
    />
  </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/BODY */
export const BODY = ({ fill, ...rest }) => (
  <svg width="66" height="69" viewBox="0 0 66 69" fill="none" { ...rest } xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.5566 68.4401C34.1588 68.4401 33.7773 68.2821 33.496 68.0008C33.2147 67.7195 33.0566 67.
3379 33.0566 66.9401V51.1201C33.0566 50.7223 33.2147 50.3408 33.496 50.0595C33.7773 49.7782 34.1588
49.6201 34.5566 49.6201C34.9545 49.6201 35.336 49.7782 35.6173 50.0595C35.8986 50.3408 36.0566 50.72
23 36.0566 51.1201V66.9401C36.0566 67.3379 35.8986 67.7195 35.6173 68.0008C35.336 68.2821 34.9545 68
.4401 34.5566 68.4401Z"
      fill={fill}
    />
    <path
      d="M34.5565 52.6202C34.3054 52.6207 34.0582 52.5577 33.8379 52.4371C33.6176 52.3164 33.4314 52
.1421 33.2965 51.9302C31.8982 49.9313 30.2135 48.1488 28.2965 46.6402C28.1429 46.518 28.0148 46.3668
 27.9196 46.1952C27.8244 46.0236 27.7639 45.8349 27.7416 45.6399C27.7193 45.4449 27.7357 45.2474 27.
7897 45.0587C27.8437 44.87 27.9344 44.6938 28.0565 44.5402C28.1787 44.3865 28.3299 44.2584 28.5015 4
4.1632C28.6731 44.068 28.8618 44.0075 29.0568 43.9853C29.2518 43.963 29.4493 43.9793 29.638 44.0333C
29.8267 44.0874 30.0029 44.178 30.1565 44.3002C31.7628 45.5768 33.2278 47.0217 34.5265 48.6102C35.82
53 47.0217 37.2903 45.5768 38.8965 44.3002C39.0502 44.178 39.2264 44.0874 39.4151 44.0333C39.6037 43
.9793 39.8012 43.963 39.9962 43.9853C40.1912 44.0075 40.3799 44.068 40.5516 44.1632C40.7232 44.2584
40.8744 44.3865 40.9965 44.5402C41.1187 44.6938 41.2093 44.87 41.2634 45.0587C41.3174 45.2474 41.333
7 45.4449 41.3115 45.6399C41.2892 45.8349 41.2287 46.0236 41.1335 46.1952C41.0383 46.3668 40.9102 46
.518 40.7565 46.6402C38.8395 48.1488 37.1548 49.9313 35.7565 51.9302C35.6273 52.1337 35.4505 52.3028
 35.2415 52.423C35.0325 52.5432 34.7974 52.6108 34.5565 52.6202Z"
      fill={fill}
    />
    <path
      d="M55.7566 68.4402C55.6437 68.4549 55.5294 68.4549 55.4166 68.4402C55.0295 68.3498 54.6941 68
.1095 54.4841 67.772C54.2741 67.4345 54.2067 67.0274 54.2966 66.6402C55.6232 60.4362 56.3201 54.1142
 56.3766 47.7702C56.3724 41.3429 54.9306 34.998 52.1566 29.2002C44.5066 14.0502 49.8366 1.43021 50.0
666 0.900208C50.1454 0.719641 50.2589 0.556361 50.4008 0.419691C50.5427 0.283021 50.7102 0.175638 50
.8936 0.103672C51.077 0.0317071 51.2727 -0.00343121 51.4697 0.00026392C51.6667 0.00395905 51.861 0.0
464152 52.0416 0.125208C52.2221 0.204001 52.3854 0.317589 52.5221 0.459484C52.6588 0.60138 52.7661 0
.768805 52.8381 0.952201C52.9101 1.1356 52.9452 1.33137 52.9415 1.52835C52.9378 1.72532 52.8954 1.91
964 52.8166 2.10021C52.8166 2.21021 47.8166 14.0102 54.8166 27.8402C57.7973 34.048 59.3519 40.8439 5
9.3666 47.7302C59.3249 54.3041 58.6011 60.8558 57.2066 67.2802C57.1302 67.6083 56.9457 67.9011 56.68
26 68.1115C56.4196 68.322 56.0934 68.4377 55.7566 68.4402Z"
      fill={fill}
    />
    <path
      d="M13.3566 68.4402C13.018 68.44 12.6895 68.3253 12.4244 68.1146C12.1593 67.904 11.9733 67.61
11.8966 67.2802C10.5021 60.8558 9.7782 54.3041 9.73657 47.7302C9.81607 41.2559 11.268 34.872 13.9966
 29.0002C20.9966 14.0002 16.3366 2.19021 16.2866 2.08021C16.1341 1.71156 16.1343 1.29743 16.2871 0.9
2892C16.4399 0.560411 16.7329 0.267712 17.1016 0.115212C17.4702 -0.0372872 17.8844 -0.0370947 18.252
9 0.115747C18.6214 0.26859 18.9141 0.561561 19.0666 0.930212C19.2866 1.46021 24.2966 14.0602 16.6866
 30.2902C14.1328 35.7545 12.7693 41.6992 12.6866 47.7302C12.7595 54.0759 13.4731 60.398 14.8166 66.6
002C14.9065 66.9874 14.839 67.3945 14.629 67.732C14.419 68.0695 14.0837 68.3098 13.6966 68.4002C13.5
854 68.4277 13.4711 68.4412 13.3566 68.4402Z"
      fill={fill}
    />
    <path
      d="M38.6666 30.6602C38.3303 30.6617 38.0033 30.5501 37.7381 30.3434C37.4729 30.1367 37.2848 29
.8468 37.204 29.5204C37.1233 29.194 37.1546 28.8499 37.2929 28.5434C37.4311 28.2369 37.6684 27.9857
37.9666 27.8302C60.0866 16.2102 62.1866 12.4902 62.3466 11.6802C61.4266 10.9502 56.6566 11.0102 52.1
266 11.8202C51.7287 11.8905 51.3193 11.7999 50.9883 11.5683C50.6573 11.3367 50.4319 10.9831 50.3616
10.5852C50.2913 10.1874 50.3819 9.77796 50.6135 9.44696C50.8451 9.11596 51.1987 8.89052 51.5966 8.82
023C56.1666 8.05023 63.9966 7.20023 65.2066 10.6902C66.1466 13.4302 63.6666 17.6902 39.3566 30.4902C
39.1444 30.6037 38.9072 30.6621 38.6666 30.6602Z"
      fill={fill}
    />
    <path
      d="M4.26649 41.8601C1.88649 41.8601 0.876495 41.1501 0.346495 40.2301C-1.52351 37.0001 4.6065
31.3901 8.4265 28.3301L16.4265 22.1101C16.5821 21.9893 16.76 21.9003 16.95 21.8483C17.14 21.7962 17.
3384 21.7821 17.5339 21.8067C17.7293 21.8313 17.9181 21.8941 18.0892 21.9917C18.2604 22.0892 18.4107
 22.2195 18.5315 22.3751C18.6523 22.5307 18.7413 22.7086 18.7934 22.8986C18.8454 23.0886 18.8596 23.
287 18.835 23.4825C18.8103 23.678 18.7475 23.8667 18.6499 24.0379C18.5524 24.209 18.4221 24.3593 18.
2665 24.4801L10.2665 30.6901C6.4665 33.6901 3.06649 37.4701 2.96649 38.6901C3.71649 39.0901 7.9665 3
9.8001 30.4265 30.9701C30.6108 30.8919 30.8089 30.8515 31.0091 30.851C31.2093 30.8506 31.4076 30.890
3 31.5923 30.9677C31.7769 31.0452 31.9442 31.1588 32.0842 31.3018C32.2243 31.4449 32.3342 31.6146 32
.4077 31.8009C32.4811 31.9871 32.5165 32.1862 32.5118 32.3864C32.5071 32.5865 32.4624 32.7837 32.380
2 32.9663C32.2981 33.1489 32.1803 33.3132 32.0336 33.4496C31.887 33.5859 31.7146 33.6915 31.5265 33.
7601C15.9365 39.9101 8.21649 41.8601 4.26649 41.8601Z"
      fill={fill}
    />
    <path
      d="M24.8865 41.8702C24.5537 41.8732 24.2295 41.764 23.9665 41.5602C23.8097 41.4397 23.6784 41.
2894 23.5799 41.118C23.4814 40.9466 23.4178 40.7574 23.3927 40.5613C23.3677 40.3652 23.3816 40.1661
23.4337 39.9754C23.4858 39.7847 23.5751 39.6062 23.6965 39.4502L29.8665 31.5402C30.1105 31.2259 30.4
693 31.0214 30.8641 30.9717C31.2588 30.922 31.6572 31.0312 31.9715 31.2752C32.2857 31.5192 32.4902 3
1.878 32.5399 32.2728C32.5896 32.6676 32.4805 33.0659 32.2365 33.3802L26.0665 41.3002C25.9243 41.476
6 25.7446 41.6193 25.5406 41.7179C25.3365 41.8165 25.1131 41.8685 24.8865 41.8702Z"
      fill={fill}
    />
    <path
      d="M30.9965 33.88C30.877 33.8948 30.7561 33.8948 30.6365 33.88L21.2865 31.58C21.0928 31.5334 2
0.9102 31.4491 20.7491 31.3319C20.588 31.2147 20.4515 31.0669 20.3475 30.897C20.1375 30.5538 20.0724
 30.1412 20.1665 29.75C20.2607 29.3588 20.5064 29.0211 20.8496 28.811C21.1927 28.601 21.6053 28.5359
 21.9965 28.63L31.3565 30.93C31.7543 30.9778 32.1169 31.1816 32.3645 31.4966C32.612 31.8117 32.7243
32.2122 32.6765 32.61C32.6288 33.0078 32.425 33.3704 32.1099 33.618C31.7948 33.8655 31.3943 33.9778
30.9965 33.93V33.88Z"
      fill={fill}
    />
    <path
      d="M34.0965 23.3403C36.6665 23.3403 36.6665 19.3403 34.0965 19.3403C31.5265 19.3403 31.5165 23
.3403 34.0965 23.3403Z"
      fill={fill}
    />
  </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/LUNGS */
export const LUNGS = ({ fill, ...rest }) => (
  <svg width="65" height="54" viewBox="0 0 65 54" fill="none" { ...rest } xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45.4378 53.548C37.6378 53.548 35.8178 39.348 35.6378 37.698C35.6071 37.4608 35.6381 37.219
7 35.7278 36.998C36.0178 36.238 36.5578 33.998 35.6178 32.798C34.6778 31.598 32.4778 31.648 30.7678
31.858C30.3919 31.87 30.0234 31.9661 29.6895 32.1392C29.3555 32.3122 29.0645 32.5578 28.8378 32.858C
28.0478 34.108 28.4878 36.298 28.7278 37.038C28.7927 37.2412 28.8132 37.4561 28.7878 37.668C28.6078
39.318 26.7878 53.518 18.9878 53.518H18.5578C15.4978 53.318 12.3978 49.818 10.3378 46.918C4.46782 38
.678 -2.37218 21.888 0.80782 10.398C2.15782 5.49796 4.57782 2.27796 7.99782 0.837958C13.3878 -1.4420
4 19.1278 1.63796 19.3678 1.77796C19.5908 1.89822 19.7795 2.07336 19.916 2.28683C20.0525 2.50031 20.
1323 2.74506 20.1478 2.99796C20.1978 3.50796 21.3678 15.688 32.2178 16.348C43.0578 15.658 44.2378 3.
47796 44.2778 2.99796C44.2973 2.75126 44.3778 2.51329 44.5123 2.30554C44.6467 2.09778 44.8307 1.9267
7 45.0478 1.80796C45.2978 1.66796 51.0478 -1.41204 56.4178 0.867958C59.8478 2.30796 62.2678 5.52796
63.6278 10.428C66.7978 21.888 59.9978 38.678 54.0878 46.938C52.0278 49.838 48.9278 53.338 45.8678 53
.538L45.4378 53.548ZM38.6578 37.698C39.1478 41.468 41.2778 50.828 45.6578 50.538C46.7578 50.468 48.9
078 49.028 51.6578 45.198C58.0578 36.198 63.3478 20.638 60.7378 11.198C59.6478 7.19796 57.7978 4.667
96 55.2578 3.59796C52.1178 2.26796 48.6178 3.46796 47.1778 4.08796C46.4278 9.56796 42.5778 18.758 32
.3078 19.318H32.1378C21.8578 18.758 17.9978 9.56796 17.2578 4.08796C15.8078 3.46796 12.2578 2.26796
9.15782 3.60796C6.62782 4.67796 4.78782 7.23796 3.69782 11.198C1.08782 20.638 6.37782 36.198 12.7778
 45.198C15.5078 49.028 17.6478 50.468 18.7778 50.538C23.1478 50.828 25.3278 41.398 25.7778 37.658C25
.4978 36.658 24.8578 33.578 26.3078 31.288C26.7602 30.5992 27.3656 30.0242 28.0768 29.6079C28.788 29
.1916 29.5857 28.9452 30.4078 28.888C34.0078 28.428 36.5578 29.128 37.9878 30.958C39.8278 33.308 38.
9978 36.558 38.6578 37.698Z"
      fill={fill}
    />
    <path
      d="M23.0778 27.0478C20.1378 27.0478 18.8778 24.1778 18.5978 22.4778C18.5328 22.0799 18.6285 21
.6726 18.8639 21.3454C19.0992 21.0181 19.4549 20.7978 19.8528 20.7328C20.2506 20.6678 20.6579 20.763
5 20.9852 20.9989C21.3124 21.2342 21.5328 21.5899 21.5978 21.9878C21.5978 21.9878 22.0178 24.0478 23
.1178 24.0478C24.2178 24.0478 24.9178 21.8878 24.9278 21.8678C25.0246 21.4819 25.2707 21.1503 25.612
 20.9458C25.9533 20.7414 26.3619 20.681 26.7478 20.7778C27.1337 20.8746 27.4653 21.1207 27.6697 21.4
62C27.8741 21.8033 27.9346 22.2119 27.8378 22.5978C27.3878 24.2478 25.9378 27.0478 23.0778 27.0478Z"
      fill={fill}
    />
    <path
      d="M16.8178 39.068C13.8778 39.068 12.6278 36.198 12.3478 34.508C12.2815 34.1102 12.376 33.7023
 12.6104 33.3741C12.8448 33.0459 13.2 32.8243 13.5978 32.758C13.9957 32.6917 14.4035 32.7861 14.7317
 33.0206C15.0599 33.255 15.2815 33.6102 15.3478 34.008C15.3478 34.058 15.7678 36.068 16.8578 36.068C
17.9478 36.068 18.6678 33.918 18.6678 33.898C18.767 33.5135 19.0136 33.1836 19.3542 32.9796C19.6948
32.7755 20.1021 32.7139 20.4878 32.808C20.6785 32.8548 20.8581 32.9388 21.0162 33.0552C21.1743 33.17
17 21.3078 33.3182 21.4091 33.4864C21.5104 33.6546 21.5775 33.8412 21.6064 34.0354C21.6354 34.2296 2
1.6257 34.4276 21.5778 34.618C21.1278 36.278 19.6778 39.068 16.8178 39.068Z"
      fill={fill}
    />
    <path
      d="M10.8378 17.6981C7.97784 17.6981 6.51784 14.8981 6.11784 13.2481C6.03606 13.049 5.99809 12.
8346 6.00652 12.6195C6.01495 12.4044 6.06958 12.1936 6.1667 12.0015C6.26382 11.8094 6.40115 11.6404
6.56936 11.5061C6.73757 11.3718 6.93273 11.2752 7.14157 11.223C7.35041 11.1708 7.56804 11.1642 7.779
67 11.2035C7.99131 11.2429 8.19199 11.3273 8.36809 11.4511C8.54419 11.575 8.69158 11.7352 8.80024 11
.921C8.9089 12.1069 8.9763 12.3139 8.99784 12.5281C8.99784 12.5281 9.60784 14.6981 10.8078 14.6981C1
2.0078 14.6981 12.3178 12.6981 12.3178 12.6381C12.3828 12.2403 12.6032 11.8846 12.9304 11.6492C13.25
77 11.4138 13.665 11.3181 14.0628 11.3831C14.4607 11.4481 14.8164 11.6684 15.0517 11.9957C15.2871 12
.3229 15.3828 12.7303 15.3178 13.1281C14.9978 14.8281 13.7678 17.6981 10.8378 17.6981Z"
      fill="#0E0F1
1"
    />
    <path
      d="M41.0878 27.048C38.2278 27.048 36.7678 24.248 36.3678 22.598C36.3118 22.4041 36.2954 22.200
8 36.3198 22.0004C36.3442 21.8 36.4088 21.6066 36.5097 21.4318C36.6106 21.257 36.7458 21.1043 36.907
1 20.9829C37.0684 20.8616 37.2526 20.774 37.4486 20.7255C37.6445 20.6771 37.8483 20.6686 38.0476 20.
7008C38.2469 20.7329 38.4376 20.8049 38.6084 20.9125C38.7792 21.0201 38.9266 21.1611 39.0416 21.327C
39.1566 21.4929 39.2369 21.6803 39.2778 21.878C39.2778 21.878 39.8878 24.048 41.0878 24.048C42.2878
24.048 42.5978 21.998 42.5978 21.998C42.6628 21.6002 42.8831 21.2445 43.2104 21.0091C43.5376 20.7738
 43.945 20.6781 44.3428 20.743C44.7406 20.808 45.0963 21.0284 45.3317 21.3556C45.5671 21.6829 45.662
8 22.0902 45.5978 22.488C45.2778 24.178 43.9978 27.048 41.0878 27.048Z"
      fill={fill}
    />
    <path
      d="M47.3378 39.068C44.4778 39.068 43.0278 36.278 42.6178 34.618C42.5394 34.2382 42.611 33.8429
 42.8177 33.5148C43.0244 33.1867 43.3501 32.9514 43.7265 32.8583C44.1029 32.7652 44.5008 32.8214 44.
8366 33.0153C45.1725 33.2091 45.4202 33.5255 45.5278 33.898C45.5278 33.898 46.1478 36.068 47.3378 36
.068C48.5278 36.068 48.8478 34.068 48.8478 34.068C48.9165 33.676 49.1361 33.3265 49.4596 33.0946C49.
783 32.8628 50.1846 32.767 50.5778 32.828C50.7723 32.8607 50.9583 32.9314 51.1254 33.0361C51.2924 33
.1408 51.4372 33.2773 51.5515 33.438C51.6657 33.5987 51.7471 33.7803 51.7911 33.9725C51.8351 34.1647
 51.8408 34.3636 51.8078 34.558C51.5378 36.198 50.2778 39.068 47.3378 39.068Z"
      fill={fill}
    />
    <path
      d="M53.3278 17.6982C50.3878 17.6982 49.1278 14.8282 48.8478 13.1282C48.7828 12.7303 48.8785 12
.323 49.1139 11.9957C49.3492 11.6685 49.7049 11.4481 50.1028 11.3832C50.5006 11.3182 50.9079 11.4139
 51.2352 11.6493C51.5624 11.8846 51.7828 12.2403 51.8478 12.6382C51.8478 12.6382 52.2678 14.6982 53.
3678 14.6982C54.4678 14.6982 55.1678 12.5482 55.1778 12.5282C55.2854 12.1556 55.5331 11.8393 55.869
11.6454C56.2048 11.4516 56.6027 11.3953 56.9791 11.4885C57.3555 11.5816 57.6812 11.8169 57.8879 12.1
45C58.0946 12.473 58.1663 12.8684 58.0878 13.2482C57.6378 14.8982 56.1878 17.6982 53.3278 17.6982Z"
      fill={fill}
    />
  </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/TWITTER */
export const PLUS = ({ fill, ...rest }) => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill={fill} { ...rest } xmlns="http://www.w3.org/2000/svg">
        <rect x="6" y="15" width="15" height="3" rx="1.5" transform="rotate(-90 6 15)" />
        <rect y="6.05859" width="15" height="3" rx="1.5" />
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/DOWNLOAD */
export const DOWNLOAD = ({ fill, ...rest }) => (
    <svg width="34" height="34" viewBox="0 0 34 34" { ...rest } xmlns="http://www.w3.org/2000/svg">
        <line
            fill="none"
            strokeWidth="4px" y2="31.85318" x2="31.20034" y1="31.85318" x1="4.13034"
            stroke={fill}
        />
        <line
            strokeLinecap="round"
            strokeMiterlimit="10"
            fill="none"
            strokeWidth="3.74px"
            y2="23.94318" x2="17.67034" y1="2.07318" x1="17.67034"
            stroke={fill}
        />
        <g id="svg_3">
            <line
                id="svg_4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                fill="none"
                strokeWidth="4px"
                y2="23.79318" x2="17.59034" y1="13.73318" x1="7.53034"
                stroke={fill}
            />
            <line
                id="svg_5"
                strokeLinecap="round"
                strokeMiterlimit="10"
                fill="none"
                strokeWidth="4px"
                y2="23.79318" x2="17.74034" y1="13.73318" x1="27.80034"
                stroke={fill}
            />
        </g>
    </svg>
)

/** @namespace Scandipwa/Component/Icons/Config/PLAY */
export const PLAY = ({ fill, ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" { ...rest }>
        <g fill="none"
           fillRule="evenodd"
           transform="translate(-347 -178)">
        <path fill={fill} id="prefix__b" d="M387 180c19.882 0 36 16.118 36 36s-16.118 36-36 36-36-16.118-36-36 16.118-36 36-36zm-9 19v33l28-16.5-28-16.5z"/>
        </g>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/BACK_ARROW */
export const BACK_ARROW = ({ fill, ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none" {...rest}>
        <g clip-path="url(#clip0_354306_1663)">
            <path
                d="M0.29375 7.29375C-0.0968746 7.68438 -0.0968747 8.31875 0.29375 8.70938L5.29375 13.7094C5.68437 14.1 6.31875 14.1 6.70937 13.7094C7.1 13.3188 7.1 12.6844 6.70938 12.2938L3.4125 9.00001L13 9.00001C13.5531 9.00001 14 8.55313 14 8.00001C14 7.44688 13.5531 7.00001 13 7.00001L3.41563 7.00001L6.70625 3.70626C7.09688 3.31563 7.09688 2.68125 6.70625 2.29063C6.31563 1.9 5.68125 1.9 5.29063 2.29063L0.290625 7.29063L0.29375 7.29375Z"
                fill={fill}
            />
        </g>
        <defs>
            <clipPath id="clip0_354306_1663">
                <rect width="14" height="16" fill="white" transform="translate(14 16) rotate(-180)" />
            </clipPath>
        </defs>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/CLOSE */
export const CLOSE = ({ fill, ...rest }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="24"
        viewBox="0 0 18 24"
        fill="none"
        {...rest}
    >
        <path
            d="M16.0594 7.05938C16.6453 6.47344 16.6453 5.52188 16.0594 4.93594C15.4734 4.35001 14.5219 4.35001 13.9359 4.93594L9.00001 9.87657L4.05938 4.94063C3.47344 4.35469 2.52188 4.35469 1.93594 4.94063C1.35001 5.52657 1.35001 6.47813 1.93594 7.06407L6.87657 12L1.94063 16.9406C1.35469 17.5266 1.35469 18.4781 1.94063 19.0641C2.52657 19.65 3.47813 19.65 4.06407 19.0641L9.00001 14.1234L13.9406 19.0594C14.5266 19.6453 15.4781 19.6453 16.0641 19.0594C16.65 18.4734 16.65 17.5219 16.0641 16.9359L11.1234 12L16.0594 7.05938Z"
            fill={fill}
        />
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/MARKER */
export const MARKER = ({fill, ...rest}) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <g clipPath="url(#clip0_7_45)">
            <path d="M11.293 0.983362C12.0048 0.983362 12.7162 1.0551 13.4074 1.19651C14.0811 1.33436 14.7436 1.54007 15.3768 1.80784C15.9985 2.07083 16.5981 2.39629 17.159 2.77514C17.7145 3.15044 18.2369 3.58152 18.7117 4.05624C19.1865 4.53097 19.6175 5.05346 19.9928 5.60901C20.3718 6.16993 20.6972 6.76953 20.9601 7.3912C21.2279 8.02436 21.4336 8.68693 21.5715 9.36059C21.7129 10.0518 21.7846 10.7632 21.7846 11.475C21.7846 12.1868 21.7129 12.8983 21.5715 13.5894C21.4336 14.2631 21.2279 14.9257 20.9601 15.5588C20.6972 16.1805 20.3717 16.7801 19.9928 17.341C19.6175 17.8966 19.1865 18.419 18.7117 18.8938C18.2369 19.3686 17.7145 19.7996 17.159 20.1749C16.5981 20.5538 15.9985 20.8793 15.3768 21.1422C14.7436 21.41 14.0811 21.6157 13.4074 21.7535C12.7162 21.8949 12.0048 21.9667 11.293 21.9667C10.5811 21.9667 9.86971 21.8949 9.17854 21.7535C8.50489 21.6157 7.84231 21.41 7.20915 21.1422C6.58749 20.8792 5.98788 20.5538 5.42696 20.1749C4.87141 19.7996 4.349 19.3685 3.8742 18.8938C3.39939 18.419 2.96839 17.8966 2.59309 17.341C2.21416 16.7802 1.8887 16.1805 1.6258 15.5588C1.35802 14.9257 1.15232 14.2631 1.01446 13.5894C0.873053 12.8983 0.801316 12.1868 0.801316 11.475C0.801316 10.7632 0.873053 10.0518 1.01446 9.36059C1.15232 8.68693 1.35802 8.02436 1.6258 7.3912C1.88878 6.76953 2.21424 6.16993 2.59309 5.60901C2.96839 5.05346 3.39948 4.53105 3.8742 4.05624C4.349 3.58144 4.87141 3.15044 5.42696 2.77514C5.98788 2.39621 6.58749 2.07074 7.20915 1.80784C7.84231 1.54007 8.50489 1.33436 9.17854 1.19651C9.86971 1.0551 10.5811 0.983362 11.293 0.983362ZM11.293 0.975098C5.49399 0.975098 0.792969 5.67612 0.792969 11.4751C0.792969 17.2741 5.49399 21.9751 11.293 21.9751C17.0919 21.9751 21.793 17.2741 21.793 11.4751C21.793 5.67612 17.0919 0.975098 11.293 0.975098Z" fill="#0288D1"/>
            <path d="M11.293 21.6724C9.91633 21.6724 8.58092 21.4028 7.3237 20.871C6.71947 20.6155 6.13673 20.2991 5.59151 19.9308C5.05158 19.566 4.5438 19.1471 4.08239 18.6857C3.62097 18.2243 3.20203 17.7165 2.83723 17.1766C2.46896 16.6314 2.15259 16.0486 1.89704 15.4444C1.3653 14.1872 1.0957 12.8517 1.0957 11.4751C1.0957 10.0985 1.3653 8.76305 1.89704 7.50583C2.15259 6.9016 2.46896 6.31886 2.83723 5.77364C3.20203 5.23371 3.62097 4.72593 4.08239 4.26451C4.5438 3.8031 5.05158 3.38416 5.59151 3.01936C6.13665 2.65109 6.71947 2.33472 7.3237 2.07917C8.58084 1.54743 9.91633 1.27783 11.293 1.27783C12.6696 1.27783 14.005 1.54743 15.2622 2.07917C15.8665 2.33472 16.4492 2.65109 16.9944 3.01936C17.5344 3.38416 18.0421 3.8031 18.5036 4.26451C18.965 4.72593 19.3839 5.23371 19.7487 5.77364C20.117 6.31878 20.4334 6.9016 20.6889 7.50583C21.2206 8.76297 21.4902 10.0985 21.4902 11.4751C21.4902 12.8517 21.2206 14.1871 20.6889 15.4444C20.4334 16.0486 20.117 16.6313 19.7487 17.1766C19.3839 17.7165 18.965 18.2243 18.5036 18.6857C18.0421 19.1471 17.5344 19.566 16.9944 19.9308C16.4493 20.2991 15.8665 20.6155 15.2622 20.871C14.0051 21.4028 12.6696 21.6724 11.293 21.6724Z" fill="#0288D1"/>
            <path d="M11.2932 1.28195C16.9227 1.28195 21.4863 5.84561 21.4863 11.4751C21.4863 17.1045 16.9227 21.6682 11.2932 21.6682C5.66372 21.6682 1.10006 17.1045 1.10006 11.4751C1.10006 5.84561 5.66364 1.28195 11.2932 1.28195ZM11.2932 1.27368C10.601 1.27368 9.90936 1.34344 9.23728 1.48096C8.5823 1.61501 7.93799 1.81502 7.32236 2.07535C6.71788 2.33106 6.13489 2.64752 5.58951 3.01595C5.04933 3.38092 4.54138 3.80002 4.07972 4.2616C3.61805 4.72327 3.19895 5.23121 2.83407 5.77139C2.46563 6.31678 2.14918 6.89976 1.89347 7.50424C1.63305 8.11988 1.43304 8.76419 1.29907 9.41916C1.16155 10.0912 1.0918 10.7829 1.0918 11.4751C1.0918 12.1672 1.16155 12.8589 1.29907 13.531C1.43313 14.186 1.63313 14.8303 1.89347 15.4459C2.14918 16.0504 2.46563 16.6334 2.83407 17.1788C3.19904 17.7189 3.61814 18.2269 4.07972 18.6886C4.5413 19.1502 5.04933 19.5693 5.58951 19.9342C6.13489 20.3026 6.71788 20.6191 7.32236 20.8748C7.93799 21.1352 8.5823 21.3352 9.23728 21.4692C9.90936 21.6067 10.601 21.6765 11.2932 21.6765C11.9854 21.6765 12.677 21.6067 13.3491 21.4692C14.0041 21.3351 14.6484 21.1351 15.264 20.8748C15.8685 20.6191 16.4515 20.3026 16.9969 19.9342C17.5371 19.5692 18.045 19.1501 18.5067 18.6886C18.9683 18.2269 19.3874 17.7189 19.7523 17.1788C20.1208 16.6334 20.4372 16.0504 20.6929 15.4459C20.9533 14.8303 21.1533 14.186 21.2873 13.531C21.4248 12.8589 21.4946 12.1672 21.4946 11.4751C21.4946 10.7829 21.4248 10.0912 21.2873 9.41916C21.1533 8.76419 20.9533 8.11988 20.6929 7.50424C20.4372 6.89976 20.1208 6.31678 19.7523 5.77139C19.3874 5.23121 18.9683 4.72327 18.5067 4.2616C18.045 3.79994 17.5371 3.38084 16.9969 3.01595C16.4515 2.64752 15.8685 2.33106 15.264 2.07535C14.6484 1.81493 14.0041 1.61493 13.3491 1.48096C12.677 1.34344 11.9854 1.27368 11.2932 1.27368Z" fill="white"/>
            <path d="M17.4908 7.97534C17.1369 6.50599 16.024 5.57552 14.4931 5.39902C14.36 5.38369 14.2306 5.37622 14.1044 5.37622C12.7736 5.37622 11.8266 6.205 11.2853 7.3377C10.7198 6.15554 9.75435 5.38878 8.34678 5.38878C8.25364 5.38878 8.15842 5.39217 8.06143 5.39895C6.66141 5.49733 5.45884 6.39012 5.07989 8.13035C4.39833 11.2609 7.62447 14.4609 11.2854 17.5738C15.1788 14.4818 18.2163 10.9869 17.4909 7.97488V7.97534H17.4908Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_7_45">
                <rect width="21" height="21" fill="white" transform="translate(0.792969 0.975098)"/>
            </clipPath>
        </defs>
    </svg>

)

/** @namespace Scandipwa/Component/Icons/Config/PLAY */
export const PAUSE = ({fill, ...rest}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="800" height="800" viewBox="0 0 800 800" fill="none"  {...rest}>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M400.005 1.08883e-08C620.912 1.08883e-08 800 179.074 800 400.005C800 620.904 620.912 799.99 400.006 799.992C179.088 799.992 0 620.905 0 400.005C0 179.07 179.088 -0.00161236 400.005 1.08883e-08ZM359.695 195.201H262.942C254.037 195.201 246.818 202.423 246.818 211.325V588.662C246.818 597.568 254.037 604.786 262.942 604.786H359.695C368.6 604.786 375.819 597.568 375.819 588.662V211.325C375.819 202.423 368.6 195.201 359.695 195.201ZM537.058 195.201H440.305C431.4 195.201 424.181 202.423 424.181 211.325V588.662C424.181 597.568 431.4 604.786 440.305 604.786H537.058C545.963 604.786 553.182 597.568 553.182 588.662V211.325C553.182 202.423 545.963 195.201 537.058 195.201Z"
              fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/PLAY */
export const MUTED = ({fill, ...rest}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="800" height="800" viewBox="0 0 800 800" fill="none" {...rest}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M400.006 0C620.912 0 800 179.072 800 400.004C800 620.901 620.912 799.987 400.006 799.989C179.088 799.989 0 620.903 0 400.004C0 179.07 179.089 0 400.006 0ZM418.305 207.858C412.808 204.873 406.117 205.136 400.87 208.543L247.472 308.174H190.774C181.35 308.174 173.71 315.813 173.71 325.236V474.749C173.71 484.174 181.35 491.813 190.774 491.813H247.472L400.87 591.443C403.688 593.273 406.923 594.197 410.165 594.197C412.96 594.197 415.762 593.51 418.306 592.128C423.805 589.142 427.229 583.389 427.229 577.131V222.856C427.228 216.6 423.803 210.842 418.305 207.858ZM573.05 399.993L622.041 351.002C627.708 345.333 627.708 336.147 622.041 330.478C616.373 324.812 607.186 324.812 601.518 330.478L552.528 379.47L503.537 330.478C497.869 324.812 488.682 324.812 483.014 330.478C477.347 336.147 477.347 345.333 483.014 351.002L532.007 399.993L483.016 448.984C477.349 454.653 477.349 463.838 483.016 469.508C485.849 472.341 489.564 473.758 493.277 473.758C496.991 473.758 500.704 472.341 503.539 469.508L552.529 420.515L601.52 469.508C604.353 472.341 608.068 473.758 611.781 473.758C615.495 473.758 619.208 472.341 622.043 469.508C627.71 463.838 627.71 454.653 622.043 448.984L573.05 399.993Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/PLAY */
export const UNMUTED = ({fill, ...rest}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="800" height="800" viewBox="0 0 800 800" fill="none" {...rest}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M400.006 0.00488281C620.912 0.00488281 800 179.075 800 400.009C800 620.907 620.912 799.994 400.006 799.994C179.088 799.994 0 620.907 0 400.009C0 179.077 179.089 0.00488281 400.006 0.00488281ZM459.253 207.864C453.756 204.879 447.065 205.142 441.818 208.549L288.42 308.18H231.722C222.298 308.18 214.659 315.82 214.659 325.242V474.756C214.659 484.18 222.298 491.82 231.722 491.82H288.42L441.818 591.449C444.636 593.279 447.871 594.203 451.113 594.203C453.908 594.203 456.71 593.516 459.254 592.134C464.753 589.148 468.177 583.395 468.177 577.138V222.861C468.177 216.605 464.753 210.849 459.253 207.864ZM515.673 520.699C517.115 520.699 518.577 520.438 520.003 519.887C559.085 504.711 585.343 457.826 585.343 403.213C585.343 354.912 564.059 311.231 531.118 291.929C525.42 288.588 518.09 290.505 514.748 296.205C511.407 301.905 513.321 309.236 519.022 312.574C544.774 327.663 561.414 363.242 561.414 403.211C561.414 447.326 540.823 486.13 511.341 497.576C505.182 499.966 502.126 506.9 504.519 513.061C506.355 517.8 510.88 520.699 515.673 520.699Z" fill={fill} />
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/CLOCK */
export const CLOCK = ({ fill, ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" {...rest}>
        <g clipPath="url(#clip0_355591_5251)">
            <path d="M15 8.5C15 10.2239 14.3152 11.8772 13.0962 13.0962C11.8772 14.3152 10.2239 15 8.5 15C6.77609 15 5.12279 14.3152 3.90381 13.0962C2.68482 11.8772 2 10.2239 2 8.5C2 6.77609 2.68482 5.12279 3.90381 3.90381C5.12279 2.68482 6.77609 2 8.5 2C10.2239 2 11.8772 2.68482 13.0962 3.90381C14.3152 5.12279 15 6.77609 15 8.5ZM0.5 8.5C0.5 10.6217 1.34285 12.6566 2.84315 14.1569C4.34344 15.6571 6.37827 16.5 8.5 16.5C10.6217 16.5 12.6566 15.6571 14.1569 14.1569C15.6571 12.6566 16.5 10.6217 16.5 8.5C16.5 6.37827 15.6571 4.34344 14.1569 2.84315C12.6566 1.34285 10.6217 0.5 8.5 0.5C6.37827 0.5 4.34344 1.34285 2.84315 2.84315C1.34285 4.34344 0.5 6.37827 0.5 8.5ZM7.75 4.25V8.5C7.75 8.75 7.875 8.98438 8.08437 9.125L11.0844 11.125C11.4281 11.3562 11.8938 11.2625 12.125 10.9156C12.3562 10.5687 12.2625 10.1062 11.9156 9.875L9.25 8.1V4.25C9.25 3.83437 8.91562 3.5 8.5 3.5C8.08437 3.5 7.75 3.83437 7.75 4.25Z" fill="#5E6875"/>
        </g>
        <defs>
            <clipPath id="clip0_355591_5251">
                <rect width="16" height="16" fill="white" transform="translate(0.5 0.5)"/>
            </clipPath>
        </defs>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/CLOCK */
export const BREADCRUMBS_SEPARATOR = ({ fill, ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="8" viewBox="0 0 4 8" fill="none" {...rest}>
        <path d="M3.84795 3.64913C4.05068 3.83626 4.05068 4.16375 3.84795 4.35088L0.853801 7.34504C0.666667 7.54776 0.339181 7.54776 0.152047 7.34504C-0.0506823 7.1579 -0.0506823 6.83042 0.152047 6.64328L2.78752 3.99221L0.152047 1.35673C-0.0506823 1.1696 -0.0506823 0.842111 0.152047 0.654976C0.339181 0.452247 0.666667 0.452247 0.853801 0.654976L3.84795 3.64913Z" fill="#AAAFB6"/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/INFO_TOOLTIP */
export const INFO_TOOLTIP = ({ fill, ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...rest}>
        <path d="M8 1.5C9.72391 1.5 11.3772 2.18482 12.5962 3.40381C13.8152 4.62279 14.5 6.27609 14.5 8C14.5 9.72391 13.8152 11.3772 12.5962 12.5962C11.3772 13.8152 9.72391 14.5 8 14.5C6.27609 14.5 4.62279 13.8152 3.40381 12.5962C2.18482 11.3772 1.5 9.72391 1.5 8C1.5 6.27609 2.18482 4.62279 3.40381 3.40381C4.62279 2.18482 6.27609 1.5 8 1.5ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM6.75 10.5C6.33437 10.5 6 10.8344 6 11.25C6 11.6656 6.33437 12 6.75 12H9.25C9.66562 12 10 11.6656 10 11.25C10 10.8344 9.66562 10.5 9.25 10.5H9V7.75C9 7.33437 8.66562 7 8.25 7H6.75C6.33437 7 6 7.33437 6 7.75C6 8.16562 6.33437 8.5 6.75 8.5H7.5V10.5H6.75ZM8 6C8.26522 6 8.51957 5.89464 8.70711 5.70711C8.89464 5.51957 9 5.26522 9 5C9 4.73478 8.89464 4.48043 8.70711 4.29289C8.51957 4.10536 8.26522 4 8 4C7.73478 4 7.48043 4.10536 7.29289 4.29289C7.10536 4.48043 7 4.73478 7 5C7 5.26522 7.10536 5.51957 7.29289 5.70711C7.48043 5.89464 7.73478 6 8 6Z" fill="#838D95"/>
    </svg>
);
