import BlogPostCard from '@osavi/blog/src/component/BlogPostCard/BlogPostCard.component';
import { NUMBER_OF_RELATED_POSTS } from '@osavi/blog/src/component/BlogRelatedPosts/BlogRelatedPosts.config';
import React from 'react';

import '../component/BlogRelatedPosts/BlogRelatedPosts.style.scss';

export default {
    'Osavi/Blog/Component/BlogRelatedPosts/Component/BlogRelatedPostsComponent': {
        'member-function': {
            renderRelatedPosts(args, callback, instance) {
                const { related_posts, isLoaded } = instance.props;

                if (!related_posts.length && !isLoaded) {
                    return Array.from({ length: NUMBER_OF_RELATED_POSTS }, (_, i) => (
                        <BlogPostCard
                          key={ i }
                          isPlaceholder
                        />
                    ));
                }

                return related_posts.map((post) => (
                    <BlogPostCard
                      key={ post.post_id }
                      post={ post }
                      mix={ { block: 'BlogRelatedPosts', elem: 'PostCard' } }
                      isRelated
                      isCompactOnMobile
                    />
                ));
            },

            renderTitle() {
                return (
                    <h3 block="BlogRelatedPosts" elem="Title">
                        { __('Related Posts') }
                    </h3>
                );
            },

            render(args, callback, instance) {
                const { related_posts } = instance.props;

                if (!related_posts.length) {
                    return null;
                }

                return (
                    <div block="BlogRelatedPosts" mods={ { isOverridden: true } }>
                        <div block="BlogRelatedPosts" elem="Wrapper">
                            { instance.renderTitle() }
                            <div block="BlogRelatedPosts" elem="PostsWrapper">
                                { instance.renderRelatedPosts() }
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
};
