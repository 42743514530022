import { ChildrenType } from '@scandipwa/scandipwa/src/type/Common';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Accordion from './Accordion.component';

/** @namespace Scandipwa/Component/Accordion/Container */
export class AccordionContainer extends PureComponent {
    static propTypes = {
        'data-accordion': PropTypes.string.isRequired,
        children: ChildrenType.isRequired
    };

    componentDidMount() {
        const accordionBoxes = document.querySelectorAll('.Accordion-Box');

        accordionBoxes.forEach((accordionBox) => {
            accordionBox.addEventListener('click', (e) => this.handleClickBox(e));
        });

        this.setFirstActive();
    }

    componentWillUnmount() {
        const accordionBoxes = document.querySelectorAll('.Accordion-Header');

        accordionBoxes.forEach((accordionBox) => {
            accordionBox.removeEventListener('click', (e) => this.handleClickBox(e));
        });
    }

    setFirstActive() {
        const accordionBoxes = document.querySelectorAll('.Accordion-Box');
        // eslint-disable-next-line fp/no-let
        let isSomeActive = false;
        accordionBoxes.forEach((accordionBox) => {
            if (accordionBox.classList.contains('Accordion-Box_isOpen')) {
                isSomeActive = true;
            }
        });
        if (!isSomeActive) {
            accordionBoxes[0].classList.add('Accordion-Box_isOpen');
        }
    }

    setActiveAnswer(clickedItem) {
        const boxes = document.querySelectorAll('.Accordion-Box');
        boxes.forEach((box) => {
            if (clickedItem !== box) {
                box.classList.remove('Accordion-Box_isOpen');
            }
        });
        clickedItem.classList.toggle('Accordion-Box_isOpen');
    }

    handleClickBox(e) {
        const box = e.target.closest('.Accordion-Box');
        this.setActiveAnswer(box);
    }

    containerProps = () => {
        const { children } = this.props;
        return {
            children
        };
    };

    render() {
        return (
            <Accordion
              { ...this.containerProps() }
            />
        );
    }
}

export default AccordionContainer;
