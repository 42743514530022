/** @namespace Scandipwa/Util/Element/Index/eventPersist */
export const eventPersist = (callback) => (event) => {
    event.persist();
    callback(event);
};

/** @namespace Scandipwa/Util/Element/Index/onEnterPressDefault */
export const onEnterPressDefault = (event) => {
    if (event.keyCode === 13) {
        event.preventDefault();
    }
};
