import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    RouterContainer as SourceRouterContainer
} from 'SourceComponent/Router/Router.container';

export * from 'SourceComponent/Router/Router.container';

/** @namespace Scandipwa/Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {
    componentDidUpdate() {}
}
export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
