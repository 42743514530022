import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';

export {
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    alternatives: state.MetaReducer.alternatives
});

/** @namespace Scandipwa/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    static propTypes = {
        alternatives: PropTypes.array
    };

    static defaultProps = {
        alternatives: []
    };

    _getDefaultTitle() {
        const { default_title } = this.props;

        return String(default_title || window.preloadTitle || '');
    }

    _getTitle() {
        const { title } = this.props;

        return String(title) || this._getDefaultTitle();
    }

    containerProps = () => {
        const {
            canonical_url,
            title_prefix,
            title_suffix
        } = this.props;

        return {
            metadata: this._getMetadata(),
            default_title: this._getDefaultTitle(),
            title: this._getTitle(),
            canonical_url,
            title_prefix,
            title_suffix
        };
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
