import PropTypes from 'prop-types';

import ProductAttributes from 'Component/ProductAttributes';
import {
    ProductCard as SourceProductCard
} from 'SourceComponent/ProductCard/ProductCard.component';

import './ProductCard.style';

/** @namespace Scandipwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCard {
    static propTypes = {
        ...super.propTypes,
        withViewButton: PropTypes.bool,
        showAttributes: PropTypes.bool
    };

    renderAttributes() {
        const { product } = this.props;
        return (
            <ProductAttributes product={ product } attributesGroupCode="attributes" isProductCard />
        );
    }

    renderViewButton() {
        return (
            <button
              block="Button"
              mods={ { priority: 'primary', size: 'small' } }
              mix={ { block: 'ViewButton' } }
            >
                { __('See') }
            </button>
        );
    }

    renderFlavour() {
        const { product: { attributes = {} } } = this.props;

        const flavours = Object.values(attributes.flavour_konfig?.attribute_options || {});

        return (
            <div block="ProductCard" elem="FlavourContainer">
                { flavours.map((flavour) => (
                    <div
                      key={ flavour.value }
                      block="ProductCard"
                      elem="FlavourChip"
                      mods={ { variant: flavour.value } }
                    >
                        { flavour.label }
                    </div>
                )) }
            </div>
        );
    }

    renderCardContent() {
        const {
            renderContent, withViewButton, showAttributes, forBlog
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        if (forBlog) {
            return this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderMainDetails() }
                    </div>
                </>
            ));
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderFlavour() }
                        { this.renderMainDetails() }
                        { showAttributes && this.renderAttributes() }
                        { withViewButton && this.renderViewButton() }
                    </div>
                </>
            ))
        );
    }
}

export default ProductCardComponent;
