import { getQueryParam } from '@scandipwa/scandipwa/src/util/Url';
import PropTypes from 'prop-types';
import React from 'react';

import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';

import '../component/BlogCategories/BlogCategories.style.scss';

const propTypes = (originalMember) => ({
    ...originalMember,
    closeOverlay: PropTypes.func,
    overlayHandler: PropTypes.func,
    isCategoryOverlayOpen: PropTypes.func
});

export default {
    'Osavi/Blog/Component/BlogCategories/Component/BlogCategoriesComponent': {
        'static-member': {
            state: [
                {
                    activeCategory: null
                }
            ],
            propTypes
        },
        'member-function': {
            renderCategoryField([identifier, title, _, key, category_id, parent_category_id], callback, instance) {
                const { closeOverlay, location } = instance.props;
                const categoryFromUrl = getQueryParam('category', location);
                return (
                    <div
                      className={ parent_category_id > 1 ? 'BlogSubCategory' : 'BlogCategory' }
                      block="BlogCategories"
                      elem={ parent_category_id > 1 ? 'SubCategory' : 'Category' }
                      mods={ { isActive: categoryFromUrl === identifier } }
                      key={ key }
                    >
                        <Link
                          to={ `${identifier === 'all-categories' || categoryFromUrl === identifier
                              ? '/blog'
                              : `blog?category=${identifier}`}` }
                          title={ __('View %s', title) }
                            // eslint-disable-next-line react/jsx-no-bind
                          onClick={ () => (closeOverlay ? closeOverlay(title) : null) }
                        >
                            <h3 className="BlogCategoryTitle" block="BlogCategories" elem="SubCategoryTitle">
                                <TextPlaceholder
                                  mix={ { block: 'BlogCategories', elem: 'CategoryTitlePlaceholder' } }
                                  content={ title }
                                  length="medium"
                                />
                            </h3>
                        </Link>
                    </div>
                );
            },

            renderCategory([category, key], callback, instance) {
                const {
                    title,
                    identifier,
                    category_id,
                    posts_count
                } = category;

                return (
                    instance.renderCategoryField(
                        identifier,
                        title,
                        posts_count,
                        key,
                        category_id
                    )
                );
            },

            render(args, callback, instance) {
                const { device, overlayHandler, isCategoryOverlayOpen } = instance.props;

                return (
                    <div block="BlogCategories" mods={ { isOverridden: true } }>
                        <div
                          block="BlogCategories"
                          elem="Wrapper"
                          onClick={ overlayHandler }
                          aria-hidden="true"
                        >
                            <h3 block="BlogCategories" elem="Title">
                                { __('Categories') }
                            </h3>
                        </div>
                        { device.isMobile
                            ? (
                                <div
                                  block="BlogCategories"
                                  elem="Overlay"
                                  mods={ { isOpen: isCategoryOverlayOpen } }
                                >
                                    { instance.renderContent() }
                                </div>
                            )
                            : instance.renderContent() }
                    </div>
                );
            }
        }
    }
};
