import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import LazyLoad from 'react-lazyload';

import { ChildrenType } from 'Type/Common';

/** @namespace Scandipwa/Component/RenderWhenVisible/Component */
export class RenderWhenVisible extends PureComponent {
    static propTypes = {
        children: ChildrenType.isRequired,
        fallback: PropTypes.func
    };

    static defaultProps = {
        fallback: () => {}
    };

    renderFallback() {
        const { fallback } = this.props;
        const fallbackRender = fallback();

        if (fallbackRender) {
            return fallbackRender;
        }

        return null;
    }

    renderChildren() {
        const { children } = this.props;

        return children;
    }

    render() {
        return (
            <LazyLoad placeholder={ this.renderFallback() }>
                { this.renderChildren() }
            </LazyLoad>

        );
    }
}

export default RenderWhenVisible;
