import GoogleMapReact from 'google-map-react';
import React, { createRef, PureComponent } from 'react';

import { fitBounds, initialCluster, toArrayLocations } from 'Util/Map/Map';

import { DEFAULT_COORS, DEFAULT_ZOOM, ZOOM } from './Map.config';

/** @namespace Scandipwa/Component/Map/Component */
export class Map extends PureComponent {
    map = createRef();

    maps = createRef();

    options = {
        fullscreenControl: false,
        zoomControlOptions: {
            position: 3,
            style: 2
        }
    };

    cluster = initialCluster();

    state = {
        bounds: []
    };

    componentDidUpdate(prevProps) {
        const { points, pointSelected } = this.props;
        const { points: prevPoints, pointSelected: prevPointSelected } = prevProps;

        if (points !== prevPoints) {
            this.handlePointsUpdate();
        }

        if (pointSelected !== undefined && pointSelected !== prevPointSelected) {
            this.handleChildUpdate();
        }
    }

    handleGoogleApiLoaded({ map, maps }) {
        const { points, onMapsLoaded } = this.props;

        if (typeof onMapsLoaded === 'function') {
            onMapsLoaded(maps);
        }

        this.maps.current = maps;
        this.map.current = map;

        this.cluster.load(toArrayLocations(points));

        fitBounds(this.cluster.points, this.maps.current, this.map.current);
    }

    handleChange({ zoom, bounds }) {
        this.setState({
            bounds: [bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat],
            zoom
        });
    }

    handlePointsUpdate() {
        const { points } = this.props;

        this.cluster.load(toArrayLocations(points));

        fitBounds(this.cluster.points, this.maps.current, this.map.current);
    }

    handleChildUpdate() {
        const { fixedCenter, pointSelected } = this.props;

        if (this.cluster.points && this.cluster.points.length > 0) {
            const point = this.cluster.points
                .find(({ properties: { point_key } = {} }) => String(pointSelected) === String(point_key));
            const { geometry: { coordinates: [lng, lat] = [] } = {} } = point || {};

            if (this.map.current) {
                this.map.current.setZoom(ZOOM);
                this.map.current.panTo({ lat: lat + (fixedCenter || 0), lng });
            }
        }
    }

    handleChildClick(key) {
        const { onChildClick } = this.props;
        const point = typeof key === 'number'
            ? this.getPoints()[key]
            : this.getPoints().find(({ properties: { point_key } = {} }) => String(point_key) === String(key));

        const {
            properties: {
                cluster,
                cluster_id,
                point_key
            } = {}, geometry: { coordinates: [lng, lat] = [] } = {}
        } = point || {};

        if (this.map.current) {
            if (cluster) {
                this.map.current.panTo({ lat, lng });
                this.map.current.setZoom(this.cluster.getClusterExpansionZoom(cluster_id));
            } else if (typeof onChildClick === 'function') {
                onChildClick(point_key);
            }
        }
    }

    handleChildReset() {
        const { onChildClick } = this.props;

        if (this.map.current) {
            this.map.current.setZoom(DEFAULT_ZOOM);
            this.map.current.panTo(DEFAULT_COORS);

            if (typeof onChildClick === 'function') {
                onChildClick(undefined);
            }
        }
    }

    getPoints() {
        const { bounds, zoom } = this.state;
        if (this.cluster.points && this.cluster.points.length > 0) {
            return this.cluster.getClusters(bounds, zoom);
        }

        return [];
    }

    renderMarker(values) {
        const { properties: { point_key } = {}, geometry: { coordinates: [lng, lat] = [] } = {} } = values;
        const { children } = this.props;

        if (typeof children !== 'function') {
            return null;
        }

        return (
            <div key={ point_key } lat={ lat } lng={ lng }>
                { children(values, { handleChildReset: this.handleChildReset.bind(this) }) }
            </div>
        );
    }

    render() {
        const { googleMapsApiKey } = this.props;

        return (
            <GoogleMapReact
              bootstrapURLKeys={ { key: googleMapsApiKey } }
              defaultCenter={ DEFAULT_COORS }
              defaultZoom={ DEFAULT_ZOOM }
              options={ this.options }
              onChildClick={ this.handleChildClick.bind(this) }
              onChange={ this.handleChange.bind(this) }
              onGoogleApiLoaded={ this.handleGoogleApiLoaded.bind(this) }
              yesIWantToUseGoogleMapApiInternals
            >
                { this.getPoints().map(this.renderMarker.bind(this)) }
            </GoogleMapReact>
        );
    }
}

export default Map;
