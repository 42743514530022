import PropTypes from 'prop-types';
import { Fragment, PureComponent } from 'react';

import Field from 'Component/Field';
import Html from 'Component/Html';

import './Consents.style';

/** @namespace Scandipwa/Component/Consents/Component */
export class Consents extends PureComponent {
    static propTypes = {
        consents: PropTypes.arrayOf(PropTypes.shape({
            consent_id: PropTypes.string,
            description: PropTypes.string,
            is_checkbox: PropTypes.bool,
            is_required: PropTypes.bool
        })).isRequired,
        fieldId: PropTypes.string,
        color: PropTypes.string
    };

    render() {
        const {
            consents,
            isSubmitted,
            updateSelectedConsentIds,
            selectedConsentIds,
            page,
            fieldId,
            color
        } = this.props;

        return (
            consents.map((consent) => (consent.is_checkbox ? (
                    <Fragment key={ consent.consent_id }>
                        <Field
                          id={ fieldId ? `${consent.consent_id}_${fieldId}` : `${consent.consent_id}_${page}` }
                          name={ consent.consent_id }
                          type="checkbox"
                          label={ (
                                <div
                                  block="Consents"
                                  elem="AgreementCMS"
                                  mods={ { isRequired: consent.is_required, color } }
                                >
                                    <Html content={ consent.description } />
                                </div>
                            ) }
                          onChange={ (_, checked) => {
                              updateSelectedConsentIds(consent.consent_id, checked);
                          } }
                        />
                        { isSubmitted
                            && !selectedConsentIds.includes(consent.consent_id)
                            && consent.is_required
                            && <p block="Field" elem="Message">{ __('Field required!') }</p> }
                    </Fragment>
            ) : (
                    <div
                      key={ consent.consent_id }
                      block="Consents"
                      elem="AgreementCMS"
                      mods={ { noCheckbox: true } }
                    >
                        <Html content={ consent.description } />
                    </div>
            )))
        );
    }
}

export default Consents;
