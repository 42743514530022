import { createRef } from 'react';
import { connect } from 'react-redux';

import {
    ContactFormContainer as SourceContactFormContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/ContactForm/ContactForm.container';

import ContactForm from './ContactForm.component';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/ContactForm/Container */
export class ContactFormContainer extends SourceContactFormContainer {
    containerFunctions = {
        onFormError: this.onFormError.bind(this),
        onFormSuccess: this.onFormSuccess.bind(this)
    };

    consentsNewsletterRef = createRef();

    containerProps() {
        return {
            consentsNewsletterRef: this.consentsNewsletterRef
        };
    }

    state = {
        ...this.state,
        isSubmitted: false
    };

    onFormSuccess(fields) {
        const consentsNewsletter = this.consentsNewsletterRef.current.state;
        const { areAllRequiredConsentsAccepted } = consentsNewsletter;

        if (!areAllRequiredConsentsAccepted) {
            this.onFormError();
            return;
        }

        this.onFormSubmit(fields);
    }

    onFormError() {
        this.setState((prevState) => ({ ...prevState, isSubmitted: true }));
    }

    render() {
        return (
            <ContactForm
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormContainer);
