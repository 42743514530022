import React, {
    forwardRef, useEffect, useRef, useState
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';

/** @namespace Scandipwa/Component/VirtualList/Component/VirtualList */
export const VirtualList = ({ gutter = 0, list, children }) => {
    const listRef = useRef();
    const itemSizeMap = useRef({});

    const getItemSize = (index) => itemSizeMap.current[index] || 50;

    const setItemSize = (index, size) => {
        itemSizeMap.current = { ...itemSizeMap.current, [index]: size + gutter };

        if (listRef.current) {
            listRef.current.resetAfterIndex(index);
        }
    };

    const innerElementType = forwardRef(({ style, ...rest }, ref) => (
        <div
          ref={ ref }
          style={ {
              ...style,
              paddingTop: gutter
          } }
          { ...rest }
        />
    ));

    const renderItem = ({ data, index, style }) => {
        const itemRef = useRef();
        const [windowSize, setWindowSize] = useState({
            width: 0,
            height: 0
        });

        const handleWindowSize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        useEffect(() => {
            window.addEventListener('resize', handleWindowSize);

            return () => {
                window.removeEventListener('resize', handleWindowSize);
            };
        });

        useEffect(() => {
            if (!itemRef) {
                throw new Error();
            }

            setItemSize(index, itemRef.current.getBoundingClientRect().height);
        }, [index, windowSize]);

        return (
            <div style={ { ...style, top: style.top, height: style.height - gutter } }>
                { children(data[index], itemRef, index) }
            </div>
        );
    };

    return (
        <AutoSizer>
            { ({ height, width }) => (
                <List
                  height={ height }
                  width={ width }
                  itemCount={ list.length }
                  itemData={ list }
                  itemSize={ getItemSize }
                  innerElementType={ innerElementType }
                  ref={ listRef }
                >
                    { renderItem }
                </List>
            ) }
        </AutoSizer>
    );
};

export default VirtualList;
