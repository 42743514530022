/* eslint-disable fp/no-let */

import domToReact from 'html-react-parser/lib/dom-to-react';

import Accordion from 'Component/Accordion/Accordion.container';
import AdvoxSlider from 'Component/AdvoxSlider';
import ArrowNext from 'Component/ArrowNext';
import ArrowPrev from 'Component/ArrowPrev';
import ContentTabs from 'Component/ContentTabs';
import Icons from 'Component/Icons';
import Instagram from 'Component/Instagram';
import QuizButton from 'Component/QuizButton';
import ShowMoreComponent from 'Component/ShowMore';
import VerticalContentTabs from 'Component/VerticalContentTabs';
import VideoPlayer from 'Component/VideoPlayer';
import WidgetFactory from 'Component/WidgetFactory';
import {
    Html as SourceHtml
} from 'SourceComponent/Html/Html.component';

/** @namespace Scandipwa/Component/Html/Component */
export class HtmlComponent extends SourceHtml {
    rules = [
        {
            query: { name: ['widget'] },
            replace: this.replaceWidget
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable
        },
        {
            query: { attribs: ['data-tabs'] },
            replace: this.replaceTabs
        },
        {
            query: { attribs: ['data-v-tabs'] },
            replace: this.replaceVTabs
        },
        {
            query: { attribs: ['data-accordion'] },
            replace: this.replaceAccordionTabs
        },
        {
            query: { attribs: ['data-instagram'] },
            replace: this.replaceInstagram
        },
        {
            query: { attribs: ['data-video'] },
            replace: this.replaceVideo
        },
        {
            query: { attribs: ['data-quiz-button'] },
            replace: this.createQuizButton
        },
        {
            query: { attribs: ['data-lp-products-slider'] },
            replace: ({ children }) => this.replaceLpProductsSlider({ children, subVariant: '' })
        },
        {
            query: { attribs: ['data-lp-nature-products-slider'] },
            replace: ({ children }) => this.replaceLpProductsSlider({ children, subVariant: 'nature' })
        },
        {
            query: { attribs: ['data-lp-products'] },
            replace: ({ children }) => this.replaceLpProducts({ children })
        },
        {
            query: { attribs: ['data-hp-horizontal-tabs-categories'] },
            replace: this.replaceHpHorizontalTabsCategories
        },
        {
            query: { attribs: [{ class: ['post-holder'] }] },
            replace: (element) => {
                const {
                    link, title, image: { src, alt }
                } = {
                    link: this.findElement('post-item-link', element)?.attribs?.href,
                    title: this.findElement('post-item-link', element)?.children?.[0]?.data?.trim(),
                    image: this.findElement('post-ftimg-hld', element)
                        ?.children?.find((child) => child?.name === 'a')
                        ?.children?.find((child) => child?.name === 'img')?.attribs || {}
                };

                return (
                    <a href={ link } className="post-item">
                        <img
                          className="post-item-image"
                          src={ src }
                          alt={ alt }
                        />
                        <p
                          className="
                          TypographyParagraph
                          TypographyParagraph_size_normal
                          TypographyParagraph_color_black"
                        >
                            { title }
                        </p>
                    </a>
                );
            }
        }, {
            query: { attribs: [{ class: ['blog-widget-recent'] }] },
            replace: (element) => (
                <div className="post-list">
                    { domToReact(
                        this.findElement('post-list', { children: element?.children || [] })?.children || [],
                        this.parserOptions
                    ) }
                </div>
            )
        },
        {
            query: { attribs: ['data-say-about-us-slider'] },
            replace: this.replaceSayAboutAsSlider
        },
        {
            query: { attribs: [{ class: 'instagram-media' }] },
            replace: ({
                type, name: TagName, children, attribs
            }) => {
                if (type === 'tag') {
                    return (
                        <div block="PostsDetails" elem="Instagram">
                            <TagName
                              { ...this.attributesToProps(attribs) }
                            >
                                { domToReact(children, this.parserOptions) }
                            </TagName>
                        </div>
                    );
                }
            }
        },
        {
            query: { attribs: ['data-icon'] },
            replace: this.replaceIcon
        },
        {
            query: { attribs: [{ class: 'ProductDescription-ContentWrapper' }] },
            replace: ({ attribs, children }) => {
                const attributes = this.attributesToProps(attribs);

                return (
                    <ShowMoreComponent>
                        <div { ...attributes }>
                            { domToReact(children, this.parserOptions) }
                        </div>
                    </ShowMoreComponent>
                );
            }
        }
    ];

    findElement(key, initialElement) {
        let element;

        if (initialElement?.attribs?.class?.includes(key)) {
            element = initialElement;
        } else if (initialElement?.children?.length > 0) {
            for (const child of initialElement?.children) {
                const foundedElement = this.findElement(key, child);

                if (foundedElement) {
                    element = foundedElement;
                }
            }
        }

        return element;
    }

    replaceIcon({ attribs }) {
        return (
            <Icons name={ attribs['data-icon'] } />
        );
    }

    replaceWidget({ attribs }) {
        return (
            <WidgetFactory { ...this.attributesToProps(attribs) } />
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    replaceTabs({ attribs, children }) {
        const attributes = this.attributesToProps(attribs);
        return (
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            <ContentTabs { ...attributes }>
                { domToReact(children, this.parserOptions) }
            </ContentTabs>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    replaceVTabs({ attribs, children }) {
        const attributes = this.attributesToProps(attribs);
        return (
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            <VerticalContentTabs { ...attributes }>
                { domToReact(children, this.parserOptions) }
            </VerticalContentTabs>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    replaceAccordionTabs({ attribs, children }) {
        const attributes = this.attributesToProps(attribs);
        return (
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            <Accordion { ...attributes }>
                { domToReact(children, this.parserOptions) }
            </Accordion>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    replaceInstagram() {
        return (
            <Instagram />
        );
    }

    // Parent having data-video must contain child with src to video
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    replaceVideo({ children }) {
        const iframeAttribs = children[0]?.attribs?.src;

        if (!iframeAttribs) {
            return null;
        }

        return (
            <VideoPlayer url={ iframeAttribs } />
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    createQuizButton({ attribs, children }) {
        const attributes = this.attributesToProps(attribs);

        return (
            <QuizButton { ...attributes }>
                { domToReact(children, this.parserOptions) }
            </QuizButton>
        );
    }

    replaceLpProductsSlider({ children, subVariant }) {
        return (
            <AdvoxSlider
              variant="lp-products"
              subVariant={ subVariant }
              settings={ {
                  dots: false,
                  arrows: true,
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  infinite: false,
                  responsive: [
                      {
                          breakpoint: 1500,
                          settings: {
                              slidesToShow: 3,
                              slidesToScroll: 1
                          }
                      },
                      {
                          breakpoint: 1280,
                          settings: {
                              slidesToShow: 2,
                              slidesToScroll: 1
                          }
                      },
                      {
                          breakpoint: 768,
                          settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1
                          }
                      }
                  ]
              } }
            >
                { domToReact(Array.from(children)
                    .filter((el) => el.attribs?.class), this.parserOptions) }
            </AdvoxSlider>
        );
    }

    replaceLpProducts({ children }) {
        return domToReact(Array.from(children)
            .filter((el) => el.attribs?.class), this.parserOptions);
    }

    replaceHpHorizontalTabsCategories({ children }) {
        return (
            <AdvoxSlider
              variant="hp-horizontal-tabs-categories"
              settings={ {
                  dots: false,
                  arrows: true,
                  slidesToShow: 5,
                  slidesToScroll: 1,
                  infinite: false,
                  responsive: [
                      {
                          breakpoint: 1500,
                          settings: {
                              slidesToShow: 5,
                              slidesToScroll: 1
                          }
                      },
                      {
                          breakpoint: 1280,
                          settings: {
                              slidesToShow: 4,
                              slidesToScroll: 1
                          }
                      },
                      {
                          breakpoint: 1025,
                          settings: {
                              slidesToShow: 3,
                              slidesToScroll: 1
                          }
                      },
                      {
                          breakpoint: 768,
                          settings: {
                              slidesToShow: 2,
                              slidesToScroll: 1
                          }
                      },
                      {
                          breakpoint: 420,
                          settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1
                          }
                      }
                  ]
              } }
            >
                { domToReact(Array.from(children)
                    .filter((el) => el.attribs?.class), this.parserOptions) }
            </AdvoxSlider>
        );
    }

    renderNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
              className={ className }
              style={ style }
              onClick={ onClick }
            >
                <ArrowNext />
            </div>
        );
    }

    renderPrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
              className={ className }
              style={ style }
              onClick={ onClick }
            >
                <ArrowPrev />
            </div>
        );
    }

    replaceSayAboutAsSlider({ children }) {
        const NextArrow = this.renderNextArrow.bind(this);
        const PrevArrow = this.renderPrevArrow.bind(this);

        return (
            <AdvoxSlider
              variant="none"
              subVariant="none"
              settings={ {
                  dots: true,
                  arrows: true,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                  autoplay: false,
                  centerMode: true,
                  centerPadding: '0px',
                  responsive: [
                      {
                          breakpoint: 1025,
                          settings: {
                              slidesToShow: 3,
                              slidesToScroll: 1
                          }
                      },
                      {
                          breakpoint: 768,
                          settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1
                          }
                      },
                      {
                          breakpoint: 420,
                          settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1
                          }
                      }
                  ],
                  nextArrow: <NextArrow />,
                  prevArrow: <PrevArrow />
              } }
            >
                { domToReact(children?.filter((child) => child.type !== 'text'), this.parserOptions) }
            </AdvoxSlider>
        );
    }
}

export default HtmlComponent;
