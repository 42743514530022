import PropTypes from 'prop-types';

import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';

/** @namespace Scandipwa/Component/Meta/Component */
export class Meta extends SourceMeta {
    static propTypes = {
        alternatives: PropTypes.array
    };

    static defaultProps = {
        alternatives: []
    };

    renderAlternate() {
        const { alternatives } = this.props;

        if (!alternatives?.length) {
            return null;
        }

        return (
            alternatives.map((alternative) => (
                <link
                  key={ alternative.url }
                  rel="alternate"
                  hrefLang={ alternative.store_code }
                  href={ alternative.url }
                />
            ))
        );
    }

    renderTitle() {
        const {
            default_title,
            title_prefix,
            title_suffix,
            title
        } = this.props;

        const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        const titleSuffix = title_suffix ? ` | ${ title_suffix }` : '';

        return (
            <title>
                { `${ titlePrefix }${ title || default_title || '' }${ titleSuffix }` }
            </title>
        );
    }

    renderMeta() {
        const { metadata } = this.props;

        return (
            <>
                { this.renderTitle() }
                { this.renderCanonical() }
                { this.renderAlternate() }
                { metadata.map((tag) => <meta key={ tag.name || tag.property } { ...tag } />) }
            </>
        );
    }
}

export default Meta;
