import Consents from 'Component/Consents';
import { CONSENTS_CONTACT } from 'Component/Consents/Consents.config';
import {
    ContactFormComponent
} from 'Component/ContactForm/ContactForm.component';
import Form from 'Component/Form';
import Loader from 'Component/Loader';

/** @namespace Scandipwa/Component/ResellerContactForm/Component */
export class ResellerContactForm extends ContactFormComponent {
    get fieldMap() {
        return {
            name: {
                validation: ['notEmpty'],
                label: __('Name'),
                placeholder: __('Your name')
            },
            email: {
                validation: ['notEmpty', 'email'],
                label: __('Email'),
                placeholder: __('Your email')
            }
        };
    }

    renderConsents() {
        const { isSubmitted, consentsNewsletterRef } = this.props;

        return (
            <Consents
              ref={ consentsNewsletterRef }
              isSubmitted={ isSubmitted }
              page={ CONSENTS_CONTACT }
              color="black"
            />
        );
    }

    renderFields() {
        return (
            <div
              block="FieldForm"
              elem="Fields"
            >
                { Object.entries(this.fieldMap).map(this.renderField) }
                { this.renderConsents() }
            </div>
        );
    }

    renderActions() {
        const { isLoading } = this.props;

        return (
            <>
                <Loader isLoading={ isLoading } />
                <div block="ContactForm" elem="Submit">
                    <button type="submit" block="Button">
                        { __('Become a stockist') }
                    </button>
                </div>
            </>
        );
    }

    render() {
        const { onFormSuccess, onFormError } = this.props;

        return (
            <div block="ContactForm">
                <Form
                  onSubmitError={ onFormError }
                  onSubmitSuccess={ onFormSuccess }
                  mix={ { block: 'FieldForm' } }
                >
                    { this.renderFields() }
                    { this.renderActions() }
                </Form>
            </div>
        );
    }
}

export default ResellerContactForm;
