/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-len, react/jsx-no-bind */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { BEFORE_ITEMS_TYPE, SWITCH_ITEMS_TYPE } from 'Component/Router/Router.config';
import { LOT_SEARCH_URL } from 'Route/LotSearch/LotSearch.config';
import { QUIZ_PAGE_URL } from 'Route/QuizPage/QuizPage.config';
import { REPORT_PAGE_URL } from 'Route/ReportsPage/ReportsPage.config';
import { TEAM_PAGE_URL } from 'Route/TeamPage/TeamPage.config';
import UrlRewrites from 'Route/UrlRewrites';
import {
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    PasswordChangePage,
    ProductComparePage,
    Router as SourceRouter,
    SearchPage,
    SomethingWentWrong,
    StyleGuidePage,
    WishlistShared,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';
import { translateUrl } from 'Util/Helpers/translateUrl';

export {
    CartPage,
    Checkout,
    CmsPage,
    CookiePopup,
    DemoNotice,
    Header,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    ConfirmAccountPage,
    MenuPage,
    Footer,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    WishlistShared,
    OfflineNotice,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    SomethingWentWrong,
    StyleGuidePage,
    withStoreRegex
};
export const LotSearch = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "lot-search" */ 'Route/LotSearch'));
export const LotResult = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "lot-result" */ 'Route/LotResult'));
export const QuizPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "quiz-page" */ 'Route/QuizPage'));
export const TeamPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "team" */ 'Route/TeamPage'));
export const ReportsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "reports" */ 'Route/ReportsPage'));

/** @namespace Scandipwa/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10
        },
        {
            component: <DemoNotice />,
            position: 15
        },
        {
            component: <Header />,
            position: 20
        },
        {
            component: <NavigationTabs />,
            position: 25
        },
        {
            component: <NewVersionPopup />,
            position: 35
        }
    ];

    [SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={ withStoreRegex('/') } exact render={ (props) => <HomePage { ...props } /> } />,
            position: 10
        },
        {
            component: <Route path={ withStoreRegex('/search/:query/') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 25
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ (props) => <CmsPage { ...props } /> } />,
            position: 40
        },
        {
            component: <Route path={ withStoreRegex('/cart') } exact render={ (props) => <CartPage { ...props } /> } />,
            position: 50
        },
        {
            component: <Route path={ withStoreRegex('/checkout/:step?') } render={ (props) => <Checkout { ...props } /> } />,
            position: 55
        },
        {
            component: <Route path={ withStoreRegex('/:account*/createPassword/') } render={ (props) => <PasswordChangePage { ...props } /> } />,
            position: 60
        },
        {
            component: <Route path={ withStoreRegex('/:account*/create/') } render={ (props) => <CreateAccountPage { ...props } /> } />,
            position: 61
        },
        {
            component: <Route path={ withStoreRegex('/:account*/login/') } render={ (props) => <LoginAccountPage { ...props } /> } />,
            position: 62
        },
        {
            component: <Route path={ withStoreRegex('/:account*/forgotpassword/') } render={ (props) => <ForgotPasswordPage { ...props } /> } />,
            position: 63
        },
        {
            component: <Route path={ withStoreRegex('/:account*/confirm') } render={ (props) => <ConfirmAccountPage { ...props } /> } />,
            position: 65
        },
        {
            component: <Route path={ withStoreRegex('/my-account/:tab?') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 70
        },
        {
            component: <Route path={ withStoreRegex('/forgot-password') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 71
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ (props) => <MenuPage { ...props } /> } />,
            position: 80
        },
        {
            component: <Route path={ withStoreRegex('/wishlist/shared/:code') } render={ (props) => <WishlistShared { ...props } /> } />,
            position: 81
        },
        {
            component: <Route path={ withStoreRegex('/contact') } render={ (props) => <ContactPage { ...props } /> } />,
            position: 82
        },
        {
            component: <Route path={ withStoreRegex('/compare') } render={ (props) => <ProductComparePage { ...props } /> } />,
            position: 83
        },
        {
            component: <Route path={ withStoreRegex('/styleguide') } render={ (props) => <StyleGuidePage { ...props } /> } />,
            position: 83
        },
        {
            component: <Route path={ withStoreRegex(`/${translateUrl(LOT_SEARCH_URL) }`) } render={ (props) => <LotSearch { ...props } /> } />,
            position: 84
        },
        {
            component: <Route path={ withStoreRegex('/lot-cert/:lotNumber') } render={ (props) => <LotResult { ...props } /> } />,
            position: 85
        },
        {
            component: <Route exact path={ [withStoreRegex(`/${translateUrl(QUIZ_PAGE_URL)}/:id`), withStoreRegex(`/${translateUrl(QUIZ_PAGE_URL)}`)] } render={ (props) => <QuizPage { ...props } /> } />,
            position: 90
        },
        {
            component: <Route path={ withStoreRegex(`/${REPORT_PAGE_URL}`) } render={ (props) => <ReportsPage { ...props } /> } />,
            position: 100
        },
        {
            component: <Route path={ withStoreRegex(`/${translateUrl(TEAM_PAGE_URL)}`) } render={ (props) => <TeamPage { ...props } /> } />,
            position: 110
        },
        {
            component: <Route render={ (props) => <UrlRewrites { ...props } /> } />,
            position: 1000
        }
    ];
}

export default RouterComponent;
