import {
    Overlay as SourceOverlay
} from 'SourceComponent/Overlay/Overlay.component';

import './Overlay.style';

/** @namespace Scandipwa/Component/Overlay/Component */
export class OverlayComponent extends SourceOverlay {
    // TODO implement logic
}

export default OverlayComponent;
