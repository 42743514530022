import { PureComponent } from 'react';

import { ProductType } from 'Type/ProductList';

import ProductAttributes from './ProductAttributes.component';

/** @namespace Scandipwa/Component/ProductAttributes/Container */
export class ProductAttributesContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    containerProps = () => ({
        attributesWithValues: this._getAttributesWithValues(),
        certificates: this._getCertificates()
    });

    _getAttributesWithValues() {
        const { product: { attributes = {}, parameters = {} } } = this.props;

        const allAttribsWithValues = Object.entries(attributes).reduce((acc, [key, val]) => {
            const { attribute_label, attribute_value } = val;
            if (attribute_value) {
                return { ...acc, [attribute_label]: val };
            }

            const valueIndexFromParameter = parameters[key];
            if (valueIndexFromParameter) {
                return { ...acc, [attribute_label]: { ...val, attribute_value: valueIndexFromParameter } };
            }

            return acc;
        }, {});

        return allAttribsWithValues;
    }

    _getCertificates() {
        const { product: { lot_numbers = [], variants } } = this.props;

        // eslint-disable-next-line fp/no-let
        let arr_lot_numbers = [];

        if (variants?.length) {
            variants.forEach((item) => {
                if (item?.lot_numbers?.length) {
                    arr_lot_numbers = [...arr_lot_numbers, ...item.lot_numbers];
                }
            });
        }

        return [...lot_numbers, ...arr_lot_numbers];
    }

    render() {
        return (
            <ProductAttributes
              { ...this.props }
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductAttributesContainer;
