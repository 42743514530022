import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';

import './Instagram.style';

/** @namespace Scandipwa/Component/Instagram/Component */
export class InstagramComponent extends PureComponent {
    static propTypes = {
        instaPhotos: PropTypes.arrayOf(
            PropTypes.shape({
                media_type: PropTypes.string,
                media_url: PropTypes.string,
                permalink: PropTypes.string,
                caption: PropTypes.string
            })
        ),
        instaUsername: PropTypes.string,
        isLoading: PropTypes.bool.isRequired,
        isError: PropTypes.bool.isRequired
    };

    static defaultProps = {
        instaPhotos: [],
        instaUsername: ''
    };

    renderInstaProfileLink() {
        const { instaUsername } = this.props;

        return instaUsername ? (
            <a
              href={ `https://instagram.com/${instaUsername}/` }
              block="Instagram"
              elem="Link"
              target="_blank"
              rel="noreferrer"
            >
                { `@${instaUsername}` }
            </a>
        ) : null;
    }

    renderInstaSinglePhoto(photo) {
        const {
            media_url, permalink, caption, thumbnail_url
        } = photo;
        const src = thumbnail_url || media_url;

        return (
            <a href={ permalink } key={ permalink } block="Instagram" elem="Photo" target="_blank" rel="noreferrer">
                <img src={ src } alt={ caption } />
            </a>
        );
    }

    renderInstaPhotos() {
        const { instaPhotos, isError, isLoading } = this.props;
        if (isLoading && !isError) {
            return <Loader isLoading={ isLoading } />;
        }

        return (
            <div block="Instagram" elem="Photos">
                { instaPhotos.map((photo) => this.renderInstaSinglePhoto(photo)) }
            </div>
        );
    }

    render() {
        const { instaPhotos, isError } = this.props;

        if (isError || !instaPhotos.length) {
            return null;
        }

        return (
            <div block="Instagram">
                <h2 block="TypographyHeader" mods={ { heading: 'h2' } }>Instagram</h2>
                { this.renderInstaPhotos() }
                { this.renderInstaProfileLink() }
            </div>
        );
    }
}

export default InstagramComponent;
