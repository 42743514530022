import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ConsentsQuery from 'Query/Consents.query';
import { fetchQuery } from 'Util/Request/Query';

import Consents from './Consents.component';
import {
    CONSENTS_CHECKOUT, CONSENTS_CONTACT, CONSENTS_NEWSLETTER, CONSENTS_REGISTER
} from './Consents.config';

/** @namespace Scandipwa/Component/Consents/Container */
export class ConsentsContainer extends PureComponent {
    static propTypes = {
        page: PropTypes.oneOf([CONSENTS_REGISTER, CONSENTS_CONTACT, CONSENTS_NEWSLETTER, CONSENTS_CHECKOUT]).isRequired,
        isSubmitted: PropTypes.bool.isRequired
    };

    state = {
        consents: [],
        selectedConsentIds: [],
        requiredConsentIds: [],
        areAllRequiredConsentsAccepted: false
    };

    containerFunctions = {
        updateSelectedConsentIds: this.updateSelectedConsentIds.bind(this)
    };

    componentDidMount() {
        const { page } = this.props;

        this._getConsents(page);
    }

    getRequiredConsents() {
        const { consents } = this.state;
        const requiredConsentIds = consents.reduce((accumulator, consent) => {
            if (consent.is_required) {
                accumulator.push(consent.consent_id);
            }

            return accumulator;
        }, []);

        this.setState({
            requiredConsentIds,
            areAllRequiredConsentsAccepted: requiredConsentIds.length <= 0
        });
    }

    checkTermsAndConditions = () => {
        const { requiredConsentIds, selectedConsentIds } = this.state;
        const requiredConsentsSelected = requiredConsentIds.every((elem) => selectedConsentIds.indexOf(elem) > -1);

        this.setState({
            areAllRequiredConsentsAccepted: requiredConsentsSelected
        });
    };

    updateSelectedConsentIds(consentId, checked) {
        const { selectedConsentIds } = this.state;

        const consents = checked
            ? selectedConsentIds.concat(consentId)
            : selectedConsentIds.filter((val) => val !== consentId);

        this.setState(
            {
                selectedConsentIds: consents
            },
            () => this.checkTermsAndConditions()
        );
    }

    _getConsents(page) {
        fetchQuery(
            ConsentsQuery.getConsents({ page }),
        ).then(
            /** @namespace Scandipwa/Component/Consents/Container/ConsentsContainer/_getConsents/fetchQuery/then */
            ({ getConsents }) => {
                this.setState({
                    consents: getConsents
                });
                this.getRequiredConsents();
            },
            /** @namespace Scandipwa/Component/Consents/Container/ConsentsContainer/_getConsents/fetchQuery/then/catch */
            () => {
                this.setState({
                    consents: []
                });
            }
        );
    }

    render() {
        return (
            <Consents
              { ...this.state }
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default ConsentsContainer;
