import { withRouter } from 'react-router-dom';

import ContentWrapper from 'Component/ContentWrapper';
import {
    Breadcrumbs as SourceBreadcrumbs
} from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';

import './Breadcrumbs.style.scss';

/** @namespace Scandipwa/Component/Breadcrumbs/Component */
export class BreadcrumbsComponent extends SourceBreadcrumbs {
    renderBreadcrumbList(breadcrumbs) {
        return breadcrumbs.map((_, i) => this.renderBreadcrumb(
            breadcrumbs[breadcrumbs.length - 1 - i], i
        ));
    }

    render() {
        const {
            wrapperMix,
            breadcrumbs
        } = this.props;

        return (
            <ContentWrapper
              mix={ { block: 'Breadcrumbs' } }
              wrapperMix={ wrapperMix }
              label={ __('Breadcrumbs (current location)...') }
            >
                <nav aria-label="Breadcrumbs navigation">
                    <ul
                      block="Breadcrumbs"
                      elem="List"
                      itemScope
                      itemType="http://schema.org/BreadcrumbList"
                    >
                        { (
                            breadcrumbs.length
                                ? this.renderBreadcrumbList(breadcrumbs)
                                : this.renderBreadcrumb({}, 0)
                        ) }
                    </ul>
                </nav>
            </ContentWrapper>
        );
    }
}

export default withRouter(BreadcrumbsComponent);
