import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Icons from './Icons.component';
import * as IconsConfig from './Icons.config';

/** @namespace Scandipwa/Component/Icons/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Scandipwa/Component/Icons/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/Icons/Container */
export class IconsContainer extends PureComponent {
    static propTypes = {
        name: PropTypes.oneOf([
            'instagram',
            'facebook',
            'twitter',
            'youtube',
            'arrow_up',
            'arrow_down',
            'arrow_left',
            'arrow_right',
            'brain',
            'battery',
            'shield',
            'heart',
            'body',
            'lungs',
            'plus',
            'download',
            'play',
            'back_arrow',
            'marker',
            'pause',
            'unmuted',
            'muted',
            'close',
            'clock',
            'breadcrumbs_separator',
            'info_tooltip'
        ]).isRequired,
        fill: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string
    };

    static defaultProps = {
        fill: '#000',
        width: '30',
        height: '30'
    };

    containerProps = () => {
        const { name, ...rest } = this.props;

        return {
            icon: this._getIconByName(name, rest)
        };
    };

    _getIconByName(name, props) {
        const nameUpperCase = name.toUpperCase();
        if (typeof IconsConfig[nameUpperCase] === 'function') {
            return IconsConfig[nameUpperCase](props);
        }

        return '';
    }

    render() {
        return <Icons { ...this.containerProps() } />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IconsContainer);
