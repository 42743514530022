import { connect } from 'react-redux';

import Map from './Map.component';

/** @namespace Scandipwa/Component/Map/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    googleMapsApiKey: state.ConfigReducer.seller_point_google_maps_api_key
});

/** @namespace Scandipwa/Component/Map/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Map);
