import { PureComponent } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { TOP } from 'Component/ChevronIcon/ChevronIcon.config';

import './ScrollToTop.style';

/** @namespace Scandipwa/Component/ScrollToTop/Component */
export class ScrollToTop extends PureComponent {
    renderScrollTop() {
        const { scrollToTop, isScrollVisible } = this.props;

        return (
            <button block="ScrollToTop" onClick={ scrollToTop } mods={ { isVisible: isScrollVisible } }>
                <ChevronIcon direction={ TOP } />
            </button>
        );
    }

    render() {
        return this.renderScrollTop();
    }
}

export default ScrollToTop;
