import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';

import './Loader.override.style';

/** @namespace Scandipwa/Component/Loader/Component */
export class LoaderComponent extends SourceLoader {
    // TODO implement logic
}

export default LoaderComponent;
