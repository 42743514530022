import { formConfig as sourceFormConfig } from 'SourceComponent/Form/Form.config';

export * from 'SourceComponent/Form/Form.config.js';

/** @namespace Scandipwa/Component/Form/Config/requiredCheckbox */
export const requiredCheckbox = ({ checked }) => checked;

/** @namespace Scandipwa/Component/Form/Config/formConfig */
export const formConfig = () => ({
    ...sourceFormConfig(),
    requiredCheckbox: {
        validate: requiredCheckbox,
        message: __('This field is required!')
    }

});

export default formConfig();
