import { getPostUrl } from '@osavi/blog/src/util/Posts';
import React from 'react';

import Html from 'Component/Html';
import Icons from 'Component/Icons';
import Image from 'Component/Image';
import Link from 'Component/Link';
import { getActiveStoreCode } from 'Util/Helpers/getActiveStoreCode';

import '../component/BlogPostCard/BlogPostCard.style.scss';

export default {
    'Osavi/Blog/Component/BlogPostCard/Component/BlogPostCardComponent': {
        'member-function': {
            renderImage(args, callback, instance) {
                const {
                    post: {
                        first_image,
                        title
                    },
                    isRelated,
                    post
                } = instance.props;

                if (!first_image || first_image === 'false') {
                    return null;
                }

                return (
                    <Link
                      to={ getPostUrl(post) }
                      title={ __('View %s', title) }
                      mix={ { block: 'BlogPostCard', elem: 'ImageWrapper', mods: { isRelated } } }
                    >
                        <Image
                          src={ first_image }
                          alt={ title }
                          ratio="custom"
                          isPlaceholder={ !first_image }
                        />
                    </Link>
                );
            },

            renderPublishDate(args, callback, instance) {
                const { post: { publish_time } } = instance.props;
                if (!publish_time) {
                    return null;
                }

                const dateFormatted = new Date(publish_time).toLocaleDateString('pl-PL');

                return (
                    <div block="BlogPostCard" elem="PublishDate">
                        { dateFormatted }
                    </div>
                );
            },

            renderAuthor(args, callback, instance) {
                const { post: { author } } = instance.props;
                if (!author) {
                    return null;
                }

                return (
                    <div block="BlogPostCard" elem="Author">
                        { author.name }
                    </div>
                );
            },
            renderReadingTime(args, callback, instance) {
                const { post: { reading_time } } = instance.props;

                if (!reading_time) {
                    return null;
                }

                return (
                    <div block="BlogPostCard" elem="ReadingTime">
                        <Icons name="clock" height={ 16 } width={ 16 } />
                        { __('%s min', reading_time) }
                    </div>
                );
            },

            renderCategories(args, callback, instance) {
                const { post: { categories } } = instance.props;
                if (!categories?.length) {
                    return null;
                }

                return (
                    <div block="BlogPostCard" elem="Categories">
                        { categories.map(({ category_id, title, identifier }, index) => {
                            const url = `/${getActiveStoreCode()}/blog?category=${identifier}`;

                            return (
                                <>
                                    { index > 0 ? <span block="BlogPostCard" elem="CategorySeparator">|</span> : null }
                                    <a
                                      block="BlogPostCard"
                                      elem="Category"
                                      href={ url }
                                      key={ `category${category_id}` }
                                    >
                                        { title }
                                    </a>
                                </>
                            );
                        }) }
                    </div>
                );
            },

            renderDescription(args, callback, instance) {
                const { post: { short_content } } = instance.props;

                if (!short_content) {
                    return null;
                }

                return (
                    <div block="BlogPostCard" elem="Description">
                        <Html content={ short_content } />
                    </div>
                );
            },

            renderReadMore(args, callback, instance) {
                const { post } = instance.props;
                if (!post) {
                    return null;
                }

                return (
                    <Link
                      block="BlogPostCard"
                      elem="Button"
                      mix={ {
                          block: 'Button',
                          mods: { size: 'small' }
                      } }
                      to={ getPostUrl(post) }
                    >
                        { __('Read more') }
                    </Link>
                );
            },

            renderContents(args, callback, instance) {
                const {
                    post: {
                        first_image
                    }, isPlaceholder, isRelated
                } = instance.props;

                if (isPlaceholder) {
                    return null;
                }

                const isNoImage = !first_image || first_image === 'false';

                return (
                    <div block="BlogPostCard" elem="ContentWrapper" mods={ { expand: true, isRelated } }>
                        <div block="BlogPostCard" elem="Details" mods={ { isRelated, isNoImage } }>
                            { !isRelated ? (
                                <div block="BlogPostCard" elem="Header">
                                    { [
                                        instance.renderPublishDate(),
                                        instance.renderAuthor(),
                                        instance.renderReadingTime()
                                    ]
                                        .filter(Boolean)
                                        .map((child, key) => (
                                            <>
                                                { key > 0
                                                    ? <span block="BlogPostCard" elem="HeaderSeparator">{ ' | ' }</span>
                                                    : null }
                                                { child }
                                            </>
                                        )) }
                                </div>
                            ) : null }
                            { instance.renderTitle() }
                            { instance.renderCategories() }
                            { !isRelated ? instance.renderDescription() : null }
                        </div>
                        { instance.renderImage() }
                    </div>
                );
            },

            render(args, callback, instance) {
                const { isPlaceholder, isCompactOnMobile, isHero } = instance.props;
                return (
                    <div
                      block="BlogPostCard"
                      mods={ {
                          isLoading: isPlaceholder, isCompactOnMobile, isHero, isOverridden: true
                      } }
                    >
                        { instance.renderContents() }
                    </div>
                );
            }
        }
    }
};
