import React from 'react';

import AdvoxSlider from 'Component/AdvoxSlider';
import ArrowNext from 'Component/ArrowNext';
import ArrowPrev from 'Component/ArrowPrev';
import ChevronIcon from 'Component/ChevronIcon';
import { LEFT, RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import ProductCard from 'Component/ProductCard';
import {
    ProductLinks as SourceProductLinks
} from 'SourceComponent/ProductLinks/ProductLinks.component';

import './ProductLinks.override.style';

/** @namespace Scandipwa/Component/ProductLinks/Component */
export class ProductLinksComponent extends SourceProductLinks {
    renderHeading() {
        const { title, forBlog } = this.props;

        if (!title) {
            return null;
        }

        if (forBlog) {
            return (
                <h2 block="ProductLinks" elem="Title">
                    { title }
                </h2>
            );
        }

        const titleArray = title.split(' ');
        titleArray[1] = `<span>${titleArray[1]}</span>`;

        return (
            <h2 block="ProductLinks" elem="Title">
                <Html content={ titleArray.join(' ') } />
            </h2>
        );
    }

    renderProductCard(product, i) {
        const {
            productCardProps,
            productCardFunctions,
            showAttributes,
            withViewButton,
            forBlog
        } = this.props;

        const { id = i } = product;

        return (
            <ProductCard
              block="ProductLinks"
              elem="Card"
              product={ product }
              key={ id }
              { ...productCardProps }
              { ...productCardFunctions }
              withViewButton={ withViewButton }
              showAttributes={ showAttributes }
              forBlog={ forBlog }
            />
        );
    }

    renderNextArrow(props) {
        const { forBlog } = this.props;
        const { className, style, onClick } = props;

        return (
            <div
              className={ className }
              style={ style }
              onClick={ onClick }
            >
                { forBlog ? <ArrowNext /> : <ChevronIcon direction={ RIGHT } /> }

            </div>
        );
    }

    renderPrevArrow(props) {
        const { forBlog } = this.props;
        const { className, style, onClick } = props;

        return (
            <div
              className={ className }
              style={ style }
              onClick={ onClick }
            >
                { forBlog ? <ArrowPrev /> : <ChevronIcon direction={ LEFT } /> }
            </div>
        );
    }

    render() {
        const { areDetailsLoaded, forBlog } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        const NextArrow = this.renderNextArrow.bind(this);
        const PrevArrow = this.renderPrevArrow.bind(this);

        if (forBlog) {
            return (
                <div block="ProductLinks" mods={ { forBlog } }>
                    { this.renderHeading() }
                    <AdvoxSlider
                      variant="none"
                      subVariant="none"
                      settings={ {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          dots: false,
                          nextArrow: <NextArrow />,
                          prevArrow: <PrevArrow />
                      } }
                    >
                        { this.renderItems() }
                    </AdvoxSlider>
                </div>
            );
        }

        return (
            <ContentWrapper
              mix={ { block: 'ProductLinks' } }
              wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper' } }
              label={ __('Linked products') }
            >
                { this.renderHeading() }
                <ul block="ProductLinks" elem="List">
                    <AdvoxSlider
                      mix={ { block: 'SliderWidget', mix: { block: 'ProductLinks', elem: 'Slider' } } }
                      variant="none"
                      settings={ {
                          dots: true,
                          arrows: false,
                          speed: 500,
                          slidesToShow: 4,
                          slidesToScroll: 1,
                          infinite: true,
                          draggable: true,
                          nextArrow: <NextArrow />,
                          prevArrow: <PrevArrow />,
                          responsive: [
                              {
                                  breakpoint: 1024,
                                  settings: {
                                      slidesToShow: 2,
                                      slidesToScroll: 1
                                  }
                              },
                              {
                                  breakpoint: 728,
                                  settings: {
                                      arrows: true,
                                      slidesToShow: 1,
                                      slidesToScroll: 1
                                  }
                              }
                          ]
                      } }
                    >
                        { this.renderItems() }
                    </AdvoxSlider>

                </ul>
            </ContentWrapper>
        );
    }
}

export default ProductLinksComponent;
