import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './Accordion.style';

/** @namespace Scandipwa/Component/Accordion/Component */
export class AccordionComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired
    };

    renderChildren() {
        const { children } = this.props;
        return children;
    }

    render() {
        return (
            <div block="Accordion">
                { this.renderChildren() }
            </div>
        );
    }
}

export default AccordionComponent;
