Mosaic.setPlugins([require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/osavi/@advox/blog/src/plugin/BlogCategories.plugin.js'),
require('./../packages/osavi/@advox/blog/src/plugin/BlogPost.query.plugin.js'),
require('./../packages/osavi/@advox/blog/src/plugin/BlogPostCard.plugin.js'),
require('./../packages/osavi/@advox/blog/src/plugin/BlogPosts.query.plugin.js'),
require('./../packages/osavi/@advox/blog/src/plugin/BlogRecentPosts.plugin.js'),
require('./../packages/osavi/@advox/blog/src/plugin/BlogRelatedPosts.plugin.js'),
require('./../packages/osavi/@advox/blog/src/plugin/BlogRelatedProducts.plugin.js'),
require('./../packages/osavi/@advox/blog/src/plugin/BlogSearchBar.plugin.js'),
require('./../packages/osavi/@advox/blog/src/plugin/BlogSearchOverlay.plugin.js'),
require('./../packages/osavi/@advox/blog/src/plugin/PostDetails.plugin.js'),
require('./../packages/osavi/@advox/blog/src/plugin/PostDetailsContainer.plugin.js'),
require('./../packages/osavi/@advox/blog/src/plugin/PostListing.plugin.js'),
require('./../packages/osavi/@scandipwa/blog/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/osavi/@scandipwa/blog/src/plugin/HeaderComponent.plugin.js'),
require('./../packages/osavi/@scandipwa/blog/src/plugin/Image.plugin.js'),
require('./../packages/osavi/@scandipwa/blog/src/plugin/Router.plugin.js'),
require('./../packages/osavi/@scandipwa/blog/src/plugin/StoreIndex.plugin.js')]);
import * as Sentry from '@sentry/react';
import { render } from 'react-dom';

import App from 'Component/App';
import history from 'Util/History';

import 'Util/Polyfill';
import 'Style/main';

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        environment: window.location.host,
        dsn: 'https://774d0b86a278804302f496f0d09b6d26@sentry.advox.pl/103',
        integrations: [
            Sentry.reactRouterV5BrowserTracingIntegration({ history })
        ],
        tracesSampleRate: 1.0
    });
}

render(<App />, document.getElementById('root'));
